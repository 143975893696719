import { useState, useCallback } from 'react';
import { DamageMapItemType } from '../../../type/bodyAndPaint';

interface DamageCheckHookProps {
  initialCheck: DamageMapItemType;
}
export const useDamageCheck = ({ initialCheck }: DamageCheckHookProps) => {
  const [damageMap, setDamageMap] = useState(
    initialCheck ?? ({} as DamageMapItemType),
  );
  const handleCheckBoxChange = useCallback((key: keyof DamageMapItemType) => {
    setDamageMap((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);
  return [damageMap, handleCheckBoxChange] as const;
};
