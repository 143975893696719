import { Router } from 'framework7/types';
import { BPRepairPage } from '@/components/pages/BodyAndPaint/BPRepairPage';
import { RepairCheckInReserve } from '@/components/pages/BodyAndPaint/repairCheckIn/repairCheckInReserve';
import { RepairProgressDetail } from '@/components/pages/BodyAndPaint/repairProgress/repairProgressDetail';
import { RepairServiceRequest } from '@/components/pages/BodyAndPaint/repairService/repairServiceRequest';
import { paths } from '@/config/paths';

export const bodyAndPaintRoutes: Router.RouteParameters[] = [
  {
    path: paths.bpsRepairCheckInReserve,
    name: '傷・凹み修理入庫予約',
    component: RepairCheckInReserve,
  },
  {
    path: paths.bpsRepairProgressDetail,
    name: '傷・凹み修理修理状況',
    component: RepairProgressDetail,
  },
  {
    path: paths.bpsRepairServiceRequest,
    name: '傷・凹み修理修理依頼',
    component: RepairServiceRequest,
  },
  {
    path: paths.bpsRepair,
    name: '傷・凹み修理修理依頼',
    component: BPRepairPage,
  },
];
