import { RepairProgressInsuranceInfoType } from '../../type/bodyAndPaint';
import { AccordionField } from '../accordion';
import { FilledButton } from '../button';

interface RepairProgressInsuranceProps {
  info?: RepairProgressInsuranceInfoType;
  onClick: () => void;
}
export const RepairProgressInsuranceField = ({
  info,
  onClick,
}: RepairProgressInsuranceProps) => {
  const {
    company_name,
    certificate_number,
    receipt_number,
    has_deductible,
    repair_plan,
  } = info ?? {};
  const InsuranceField = () => {
    const label = '保険情報';
    const infoList: Record<string, string> = {
      保険会社: company_name ?? '',
      証券番号: certificate_number ?? '',
      事故受付番号: receipt_number ?? '',
      免責金: has_deductible?.toLocaleString('ja-JP') ?? '-円',
      見積もり後の修理予定: `${repair_plan ?? '-'}`,
    };
    return (
      <AccordionField title={label}>
        {Object.entries(infoList).map(([label, item], idx) => (
          <div className={'repair-progress-info'} key={idx}>
            <div className={'repair-progress-info-label'}>
              <span>{label}</span>
            </div>
            <div className="repair-progress-info-item">
              <span>{item}</span>
            </div>
          </div>
        ))}
      </AccordionField>
    );
  };
  const NoInsuranceCard = () => {
    const label = '保険情報';
    const bodyText =
      '保険利用の有無について検討中を選択した方はこちらから登録することができます。';
    const captionText =
      '入庫後OO日以上経過した場合はお見積りが届くまで保険情報は登録できません。';
    return (
      <div className={'information-card no-insurance'}>
        <div className={'no-insurance-header'}>
          <span>{label}</span>
        </div>
        <div className={'no-insurance-body'}>
          <span>{bodyText}</span>
          <li>
            <span>{captionText}</span>
          </li>
        </div>
        <FilledButton label={'保険の情報を登録する'} onClick={onClick} />
      </div>
    );
  };
  return info ? <InsuranceField /> : <NoInsuranceCard />;
};
