import { FilledButton } from '../button';

interface NoInsuranceCardProps {
  onClick: () => void;
}
export const NoInsuranceCard = ({ onClick }: NoInsuranceCardProps) => {
  const label = '保険情報';
  const bodyText =
    '保険利用の有無について検討中を選択した方はこちらから登録することができます。';
  const captionText =
    '入庫後OO日以上経過した場合はお見積りが届くまで保険情報は登録できません。';
  return (
    <div className={'information-card no-insurance'}>
      <div className={'no-insurance-header'}>
        <span>{label}</span>
      </div>
      <div className={'no-insurance-body'}>
        <span>{bodyText}</span>
        <li>
          <span>{captionText}</span>
        </li>
      </div>
      <FilledButton label={'保険の情報を登録する'} onClick={onClick} />
    </div>
  );
};
