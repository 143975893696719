import { useDateText } from '../../hook/util/useDateTimeText';

interface ExpiryDateNoteCardProps {
  date: Date;
}
export const ChangeDeadlineNoteCard = ({ date }: ExpiryDateNoteCardProps) => {
  const expiryPeriod = 3;
  const expiryDate = new Date(date);
  expiryDate.setDate(date.getDate() - expiryPeriod);
  const limitedDateText = useDateText({ dateTime: expiryDate });
  const noteLabel = 'ご注意事項';
  const noteText = [
    '当日は予約時間内に店舗受付にお越しください。(予約開始の10~15分前までにお越しください。)',
    `予約の変更・受付期間は${limitedDateText}まで変更可能です。`,
  ];
  return (
    <div className={'information-card canceling-reserve'}>
      <div className={'information-header'}>
        <div className={'header-icon'}>
          <i className={'icon-caution'} />
        </div>
        <div className={'header-label'}>
          <span>{noteLabel}</span>
        </div>
      </div>
      <div className={'information-body'}>
        <ul>
          {noteText.map((text, idx) => (
            <li key={idx}>
              <span>{text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
