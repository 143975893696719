import { TextButton } from '../button';
import style from './repairCheckInStyle.module.css';
interface CheckInConfirmInfoCardProps {
  info: Record<string, string | string[]>[];
  header?: {
    label: string;
    onEdit: () => void;
  };
}
export const CheckInConfirmInfoCard = ({
  info,
  header,
}: CheckInConfirmInfoCardProps) => {
  return (
    <div className={style.checkInConfirmField}>
      {header && (
        <div className={style.checkInConfirmHeader}>
          <div className={style.confirmHeaderLabel}>
            <span>{header.label}</span>
          </div>
          <div className={style.confirmHeaderButton}>
            <TextButton label={'編集する'} onClick={header.onEdit} />
          </div>
        </div>
      )}
      <div className={style.checkInConfirmCard}>
        {info.map((item, idx) => {
          const [[label, value]] = Object.entries(item);
          return (
            <div className={style.checkInConfirmItem} key={idx}>
              <div className={style.confirmItemLabel}>
                <span>{label}</span>
              </div>
              <div className={style.confirmItemInfo}>
                {Array.isArray(value) ? (
                  value.map((item, idx) => <span key={idx}>{item}</span>)
                ) : (
                  <span>{value}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
