import { f7, useStore } from 'framework7-react';
import { FC } from 'react';
import styles from './RegisterNewMemberStep4Style.module.css';
import RegisterInviteCodeImg from '@/assets/images/register-qr/register-scan.png';
import { Button } from '@/components/uiParts/Button';
import { isApp } from '@/config/device';
import { RegisterUserDataType } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { storeDispatch } from '@/utils/store';

interface RegisterNewMemberStep4Props {
  hanldeCancel: () => void;
}

export const RegisterNewMemberStep4: FC<RegisterNewMemberStep4Props> = ({
  hanldeCancel,
}) => {
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;

  window.onInspectionCertificateScan = (data) => {
    try {
      const parsed: {
        transport_bu_name: string;
        class_no: string;
        hiragana: string;
        registry_no: string;
        registry_no_erased?: number;
        businesswork_flg?: number;
        inspection_certificate_file: string | File | Blob; // Base64 image if necessary
        vin: string;
        motor_model?: string;
        katasiki_num?: number;
        ruibetsu_num?: number;
        inspection_limit: string; // YYYY/MM/DD
        model_code: string;
        first_registration: string; // YYYY-MM-DD
      } = JSON.parse(data);
      storeDispatch('setRegisterUser', 'registerUser', {
        ...registerUserData,
        scan: {
          ...parsed,
          next_inspection_date: parsed.inspection_limit,
          registration_date: parsed.first_registration,
        },
      });
      f7.view.main.router.navigate(paths.registerNewMemberConfirm);
    } catch {}
  };

  const openInspectionCertificateScan = () => {
    if (isApp) {
      window.location.href = '/inspection_certificate_reader';
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['main-image']}>
        <img src={RegisterInviteCodeImg} />
      </div>
      <div className={styles['content']}>
        <div className={styles['heading']}>
          <h3>続けて車検証の登録を行いますか？</h3>
        </div>
        <div className={styles['button-group']}>
          <Button
            className={styles['button']}
            onClick={openInspectionCertificateScan}
          >
            車検証を登録
          </Button>
          <Button
            className={styles['button']}
            outline
            bgColor="white"
            onClick={hanldeCancel}
          >
            スキップ
          </Button>
        </div>
      </div>
    </div>
  );
};
