import { Page } from 'framework7-react';
import { FC, useState } from 'react';
import './TodoDetailStyle.scss';
import Calendar from 'react-calendar';
import { PopupTemplate } from '@/components/uiParts/Template/PopupTemplate/PopupTemplate';
import { DATE_MONTH_INCREMENTER, formatDateWithDay } from '@/utils/date';

interface SelectCalendarProps {
  name: string;
  formMethods: any;
  minDate: Date;
}

type DateClass = { date: Date };

export const SelectCalendar: FC<SelectCalendarProps> = (props) => {
  const { name, formMethods, minDate } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getValues, setValue } = formMethods;
  const [currentView, setCurrentView] = useState<
    'month' | 'year' | 'decade' | 'century' | null
  >(null);

  const clickDay = (value: Date) => {
    setIsModalOpen(false);
    setValue(name, value);
  };

  const valueFormat = getValues()[name]
    ? formatDateWithDay(new Date(getValues()[name]) || Date.now())
    : '';

  const prevLabel = currentView === 'year' ? '＜ 前年' : '＜ 前月';
  const nextLabel = currentView === 'year' ? '次年 ＞' : '次月 ＞';

  return (
    <>
      <div className="delivery-item-label">日付</div>
      <div className="input-calendar" onClick={() => setIsModalOpen(true)}>
        <input type="text" name={name} value={valueFormat} readOnly />
      </div>

      <PopupTemplate
        title="カレンダー"
        id="deliveryCalendarPopUp"
        opened={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <Page name="deliveryCalendarPopUp">
          <Calendar
            prev2Label={null}
            next2Label={null}
            value={getValues()[name] ? new Date(getValues()[name]) : null}
            onClickDay={clickDay}
            minDetail={'month'}
            nextLabel={nextLabel}
            prevLabel={prevLabel}
            navigationLabel={({ date }: DateClass) => {
              const year = date.getFullYear();
              const month = date.getMonth() + DATE_MONTH_INCREMENTER;
              return `${year}年${month}月`;
            }}
            formatShortWeekday={(locale, date) => {
              const day = date.getDay();
              const weekday = ['日', '月', '火', '水', '木', '金', '土'];
              return weekday[day];
            }}
            onViewChange={({ view }) => setCurrentView(view)}
            minDate={minDate}
            formatDay={(_, date) => String(date.getDate())}
          />
        </Page>
      </PopupTemplate>
    </>
  );
};
