import { baseApi } from '@/config/axios';

import {
  FetchBannersParams,
  FetchBannersResponse,
} from '@/types/api/bannerApi';

export const fetchBanners = (
  params: FetchBannersParams,
): Promise<FetchBannersResponse> =>
  baseApi.get(`v1/customer/banner/info`, { params });
