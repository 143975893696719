import { ReactNode, useState } from 'react';
import {
  CheckInReserveProvider,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import {
  CheckInReserveDataType,
  CheckInReserveStateType,
} from '../type/bodyAndPaint';
import { CheckInReserveCarCondition } from './checkInCarCondition';
import { CheckInReserveCheckInDate } from './checkInCheckInDate';
import { CheckInReserveConfirmReserve } from './checkInReserveConfirm';
import { CheckInReserveUseInsurance } from './checkInUseInsurance';
import { CheckInReserveUseLoanerCar } from './checkInUseLoanerCar';

export const RepairCheckInReserve = ({ state }: { state: string }) => {
  const stateMap: Record<CheckInReserveStateType, boolean> = {
    CAR_CONDITION: true,
    USE_INSURANCE: true,
    CHECK_IN_DATE: true,
    USE_LOANER_CAR: true,
    RESERVE_CONFIRM: true,
  };
  const initState = (
    state in Object.keys(stateMap) ? state : 'CAR_CONDITION'
  ) as CheckInReserveStateType;
  const [checkInReserveState] = useState<CheckInReserveStateType>(initState);
  const [checkInReserveData] = useState<CheckInReserveDataType>(
    {} as CheckInReserveDataType,
  );
  const RepairCheckInReserveChildren = () => {
    const { state } = useCheckInReserveStateContext();
    const StateMap: Record<CheckInReserveStateType, ReactNode> = {
      CAR_CONDITION: <CheckInReserveCarCondition />,
      USE_INSURANCE: <CheckInReserveUseInsurance />,
      CHECK_IN_DATE: <CheckInReserveCheckInDate />,
      USE_LOANER_CAR: <CheckInReserveUseLoanerCar />,
      RESERVE_CONFIRM: <CheckInReserveConfirmReserve />,
    };
    return <>{state && StateMap[state]}</>;
  };
  return (
    <CheckInReserveProvider
      initialState={checkInReserveState}
      initialData={checkInReserveData}
    >
      <RepairCheckInReserveChildren />
    </CheckInReserveProvider>
  );
};
