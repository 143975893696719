import { differenceInDays, parse } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC } from 'react';
import { CarChangeList } from '../CarChangeList/CarChangeList';
import style from './carInfoCardStyle.module.css';
import carImg from '@/assets/images/carImg.svg';
import { Button } from '@/components/uiParts/Button';
import { isApp } from '@/config/device';
import { paths } from '@/config/paths';
import { Affiliate } from '@/types/api/authApi';
import { Car } from '@/types/api/customerApi';
import { generateTransportName, isDelivered } from '@/utils/car';
import {
  dateToSlashConnection,
  getCurrentTimeInTokyo,
  removeTimeFromDate,
} from '@/utils/date';
import { carNameTitle } from '@/utils/home';
import { getCarPhoto } from '@/utils/utils';

interface CarInfoCardProps {
  selectedCar?: Car;
  isOpenCarList: boolean;
  isBasicInfoLoaded: boolean;
  affiliatesInfo: Affiliate[];
  isShowSwitchCar: boolean;
  handleClickRegistCar: () => void;
  handleClickListImage: (
    car: Affiliate['cars'][0] | null,
    affiliateID: number,
  ) => Promise<void>;
  changeIsOpenCarList: (bool: boolean) => void;
}
export const CarInfoCard: FC<CarInfoCardProps> = ({
  selectedCar,
  isOpenCarList,
  isBasicInfoLoaded,
  affiliatesInfo,
  isShowSwitchCar,
  handleClickRegistCar,
  handleClickListImage,
  changeIsOpenCarList,
}) => {
  // 車両輸送名
  const transportName = generateTransportName(selectedCar);

  const carInfoCardTitle = [selectedCar?.maker_nm, selectedCar?.car_nm]
    .filter(Boolean)
    .join(' ');

  const deliveryConfirmedDate = (dateString: string) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    return dateToSlashConnection(date);
  };

  const handleClickCarImage = () => {
    f7.view.main.router.navigate(
      paths.car(String(selectedCar?.m_car_id) || ''),
      {
        props: { isChangeCarImage: true },
      },
    );
  };

  const handleClickCarOrder = (id?: number) => {
    if (id == null) return;
    f7.view.main.router.navigate(paths.carOrder(id.toString()));
  };

  const daysUntilDelivery = selectedCar?.delivery_confirmed_date
    ? differenceInDays(
        parse(
          selectedCar.delivery_confirmed_date,
          'yyyy-MM-dd',
          getCurrentTimeInTokyo(),
        ),
        removeTimeFromDate(getCurrentTimeInTokyo()),
      )
    : -1;

  const carTitle = carNameTitle(
    isBasicInfoLoaded,
    isDelivered(selectedCar?.delivery_complete_flg),
    carInfoCardTitle ? carInfoCardTitle : '現在契約中の車両はありません',
  );

  return (
    <>
      <CarChangeList
        isOpenCarList={isOpenCarList}
        selectedCar={selectedCar}
        affiliatesInfo={affiliatesInfo}
        changeIsOpenCarList={changeIsOpenCarList}
        handleClickListImage={handleClickListImage}
      />
      <div className={style['car-card-wrapper']}>
        <div className={style['car-card']}>
          <div className={style['car-selected-wrapper']}>
            <div
              className={style['car-image-icon']}
              onClick={handleClickCarImage}
            >
              <img src={getCarPhoto(selectedCar as Car, false) || carImg} />
              {!isDelivered(selectedCar?.delivery_complete_flg) && (
                <>
                  <div className={style['car-coating']}> </div>
                  <span className={style['car-label']}>納車準備中</span>
                </>
              )}
            </div>
            <div className={style['car-information-wrapper']}>
              <span className={style['car-information-title']}>{carTitle}</span>
              <span className={style['car-information-number']}>
                {carInfoCardTitle
                  ? transportName
                  : isBasicInfoLoaded &&
                    isApp &&
                    '車検証を読み取ることで車両情報をアプリに登録できます。'}
              </span>
            </div>
          </div>
          {isBasicInfoLoaded && !carInfoCardTitle && isApp && (
            <div className={style['car-card-button-group']}>
              <Button
                className={style['car-regist-btn']}
                onClick={handleClickRegistCar}
              >
                車検証を読み取る
              </Button>
            </div>
          )}
          <div className={style['car-card-button-group']}>
            {!!selectedCar?.t_order_id &&
              !isDelivered(selectedCar?.delivery_complete_flg) && (
                <div className={style['car-card-button-wrapper']}>
                  <a
                    className={style['car-card-button']}
                    onClick={() => handleClickCarOrder(selectedCar?.t_order_id)}
                  >
                    <i className={`icon-file-color-inside ${style.icon}`} />
                    注文書を見る
                  </a>
                </div>
              )}
            {!!isShowSwitchCar && (
              <div className={style['car-card-button-wrapper']}>
                <a
                  className={style['car-card-button']}
                  onClick={() => changeIsOpenCarList(true)}
                >
                  <i className={`icon-reload-refresh ${style.icon}`} />
                  車両を切り替える
                </a>
              </div>
            )}
          </div>
          {!isDelivered(selectedCar?.delivery_complete_flg) && (
            <div className={style['car-delivery']}>
              {daysUntilDelivery >= 0 && (
                <div className={style['car-delivery-count-down']}>
                  納車まであと<span>{daysUntilDelivery}日</span>
                </div>
              )}
              <div className={style['car-delivery-date']}>
                納車日：
                {deliveryConfirmedDate(
                  selectedCar?.delivery_confirmed_date || '',
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
