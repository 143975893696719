import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  HAS_DEDUCTIBLE,
  INSURANCE_USE,
  REPAIR_PLAN,
} from '../../const/bodyAndPaint';
import { useRadioSelect } from '../../hook/util/common/useRadioSelect';
import { useTextInput } from '../../hook/util/common/useTextInput';
import { InsuranceInfoType } from '../../type/bodyAndPaint';
import { EntryField } from '../common/entryField';
import { RadioGroup } from '../radioGroup';
import { TextField } from '../textFIeld';
import style from './addInsurance.module.css';

interface EntryUseInsuranceField {
  info?: InsuranceInfoType;
  setInfo: Dispatch<SetStateAction<InsuranceInfoType>>;
}
export const EntryUseInsuranceField = ({
  info,
  setInfo,
}: EntryUseInsuranceField) => {
  const {
    use_insurance,
    company_id,
    company_name,
    certificate_number,
    receipt_number,
    has_deductible,
    deductible_amount,
    repair_plan,
  } = info ?? ({} as InsuranceInfoType);

  const insuranceList: string[] = useMemo(
    () => Object.values(INSURANCE_USE).map((item) => item.label),
    [],
  );
  const hasDeductibleList: string[] = useMemo(
    () => Object.values(HAS_DEDUCTIBLE).map((item) => item.label),
    [],
  );
  const repairPlanList: string[] = useMemo(
    () => Object.values(REPAIR_PLAN).map((item) => item.label),
    [],
  );

  const [insuranceSelect, insuranceValue, changeInsurance] = useRadioSelect({
    initialSelect: use_insurance,
    selectList: insuranceList,
  });
  const [hasDeductibleSelect, hasDeductibleValue, changeHasDeductible] =
    useRadioSelect({
      initialSelect: has_deductible,
      selectList: hasDeductibleList,
    });
  const [repairPlanSelect, repairPlanValue, changeRepairPlan] = useRadioSelect({
    initialSelect: repair_plan,
    selectList: repairPlanList,
  });
  const [companyNameValue, changeCompanyName] = useTextInput({
    initialValue: company_name,
    required: true,
  });
  const [certificateNumberValue, changeCertificateNumber] = useTextInput({
    initialValue: certificate_number,
    required: true,
  });
  const [receiptNumberValue, changeReceiptNumber] = useTextInput({
    initialValue: receipt_number,
    required: false,
  });
  const [deductibleAmountValue, changeDeductibleAmount] = useTextInput({
    initialValue: deductible_amount ? deductible_amount.toString() : '',
    required: false,
  });

  const isInsurance = !(insuranceSelect === INSURANCE_USE.YES_USE.value);
  const hasDeductible = !!(hasDeductibleSelect === HAS_DEDUCTIBLE.YES.value);

  useEffect(() => {
    setInfo((prev) => ({
      ...prev,
      use_insurance: insuranceSelect,
      company_id: 0,
      company_name: companyNameValue,
      certificate_number: certificateNumberValue,
      receipt_number: receiptNumberValue,
      has_deductible: hasDeductibleSelect,
      deductible_amount: Number(deductibleAmountValue),
      repair_plan: repairPlanSelect,
    }));
  }, [
    setInfo,
    insuranceSelect,
    companyNameValue,
    certificateNumberValue,
    receiptNumberValue,
    hasDeductibleSelect,
    deductibleAmountValue,
    repairPlanSelect,
  ]);

  const InsuranceLabel = () => {
    return (
      <div className={style.entryUseInsuranceLabel}>
        <span>{'保険の利用・詳細について'}</span>
      </div>
    );
  };
  const InsuranceNote = () => {
    return (
      <div className={style.insuranceNote}>
        <span>{'お手元に保険証書をご準備ください'}</span>
      </div>
    );
  };
  const NoInsuranceNote = () => {
    const noteText =
      '※保険を利用するか否かのご判断は、お見積もり後に再度ご確認いたしますので、現時点で確定していなくても結構です。';
    return (
      <div className={style.checkInReserveNote}>
        <span>{noteText}</span>
      </div>
    );
  };

  return (
    <div className={style.entryUseInsuranceField}>
      <InsuranceLabel />
      {isInsurance && <InsuranceNote />}
      <EntryField label={'保険を利用しますか？'} required={true}>
        {!isInsurance && <NoInsuranceNote />}
        <RadioGroup
          groupName={'use-insurance'}
          list={insuranceList}
          value={insuranceValue}
          onChange={changeInsurance}
        />
      </EntryField>
      {isInsurance && (
        <EntryField label={'保険会社'} required={true}>
          <TextField
            value={companyNameValue}
            onChange={changeCompanyName}
            required={true}
            placeholder={'〇〇損保'}
          />
        </EntryField>
      )}
      {isInsurance && (
        <EntryField label={'証券番号'} required={true}>
          <TextField
            value={certificateNumberValue}
            onChange={changeCertificateNumber}
            required={true}
            placeholder={'アルファベットと数字'}
          />
        </EntryField>
      )}
      {isInsurance && (
        <EntryField label={'事故受付番号'} required={false}>
          <span>{'保険会社様の受付番号がありましたら教えてください'}</span>
          <TextField
            value={receiptNumberValue}
            onChange={changeReceiptNumber}
            required={false}
            placeholder={'000000000000000000'}
          />
        </EntryField>
      )}
      {isInsurance && (
        <EntryField label={'免責金はありますか'} required={true}>
          <RadioGroup
            groupName={'has-deductible'}
            list={hasDeductibleList}
            value={hasDeductibleValue}
            onChange={changeHasDeductible}
          />
        </EntryField>
      )}
      {isInsurance && hasDeductible && (
        <EntryField label={'免責金'}>
          <TextField
            value={deductibleAmountValue}
            onChange={changeDeductibleAmount}
            required={undefined}
            placeholder={'0000円'}
          />
        </EntryField>
      )}
      {isInsurance && (
        <EntryField label={'見積後の修理予定'} required={true}>
          <RadioGroup
            groupName={'repair-planning'}
            list={repairPlanList}
            value={repairPlanValue}
            onChange={changeRepairPlan}
          />
        </EntryField>
      )}
    </div>
  );
};
