import { useCallback } from 'react';
import { postChangeRepairCheckInDateApi } from '@/api/bodyAndPaint';
import { ChangeRepairCheckInDateParams } from '@/types/api/bodyAndPaint';

interface PostChangeRepairCheckInDateHookProps {
  info: ChangeRepairCheckInDateParams;
}
export const usePostChangeRepairCheckInDate = ({
  info,
}: PostChangeRepairCheckInDateHookProps) => {
  const postRequest = useCallback(async () => {
    try {
      if (info) {
        const fetch = await postChangeRepairCheckInDateApi(info);
        return fetch;
      } else {
        throw 'invalid params';
      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);

  return { postRequest };
};
