import { useCallback } from 'react';
import { postRepairCheckInReserveApi } from '@/api/bodyAndPaint';
import { RepairCheckInReserveParams } from '@/types/api/bodyAndPaint';

interface PostRepairCheckInReserveHookProps {
  info: RepairCheckInReserveParams;
}
export const usePostRepairCheckInReserve = ({
  info,
}: PostRepairCheckInReserveHookProps) => {
  const postRequest = useCallback(async () => {
    try {
      if (info) {
        const fetch = await postRepairCheckInReserveApi(info);
        return fetch;
      } else {
        throw 'invalid params';
      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);

  return { postRequest };
};
