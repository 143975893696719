import { FilledButton } from '../button';
import style from './repairCheckInStyle.module.css';
interface CheckInReserveDialogCardProps {
  onClick: () => void;
}
export const CheckInReserveDialogCard = ({
  onClick,
}: CheckInReserveDialogCardProps) => {
  const dialogLabel = 'ご予約ありがとうございます';
  const dialogText = [
    'ご入庫予約を承りました。',
    'ご予約日のご来場をお待ちしております。',
  ];
  return (
    <div className={style.dialogCard}>
      <div className={style.messageField}>
        <div className={style.messageHeader}>
          <span>{dialogLabel}</span>
        </div>
        <div className={style.messageBody}>
          {dialogText.map((item, idx) => (
            <span key={idx}>{item}</span>
          ))}
        </div>
      </div>
      <FilledButton onClick={onClick} label={'予約内容を確認する'} />
    </div>
  );
};
