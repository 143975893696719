import { FC } from 'react';
import style from './accountStyle.module.css';
import CopyImg from '@/assets/images/copy.svg';

type InviteCodeInputProps = {
  value: string;
  errorMessage?: string;
};

export const InviteCodeInput: FC<InviteCodeInputProps> = ({
  value,
  errorMessage = '',
}) => {
  const copyCode = () => navigator.clipboard.writeText(value);

  return (
    <div
      className={style['popup-input']}
      style={{
        position: 'relative',
      }}
    >
      <input
        className={`${style['input-text']} ${
          errorMessage && style['error-input']
        }`}
        value={value}
        disabled
      />
      <img src={CopyImg} onClick={copyCode} />
      {errorMessage && <p className={style['error']}>{errorMessage}</p>}
    </div>
  );
};
