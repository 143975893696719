import { useCallback } from 'react';
import { postCancelRepairCheckInReserveApi } from '@/api/bodyAndPaint';

interface PostCancelRepairCheckInReserveHookProps {
  m_customer_id: number;
  t_pm_car_inspection_id: number;
}
export const usePostCancelRepairCheckInReserve = ({
  m_customer_id,
  t_pm_car_inspection_id,
}: PostCancelRepairCheckInReserveHookProps) => {
  const postRequest = useCallback(async () => {
    try {
      if (m_customer_id > 0 && t_pm_car_inspection_id > 0) {
        const fetch = await postCancelRepairCheckInReserveApi({
          m_customer_id,
          t_pm_car_inspection_id,
        });
        return fetch;
      } else {
        throw 'invalid params';
      }
    } catch (error) {
      console.error(error);
    }
  }, [m_customer_id, t_pm_car_inspection_id]);

  return { postRequest };
};
