import { f7 } from 'framework7-react';
import { useState } from 'react';
import { checkCustomerInviteCode } from '@/api/registerQRApi';
import { CustomerInviteCodeParams } from '@/types/api/registerQRApi';

export const useCheckInviteCode = () => {
  const [error, setError] = useState<string>('');
  const [isValidCode, setIsValidCode] = useState<boolean>(false);

  const checkInviteCode = async (params: CustomerInviteCodeParams) => {
    try {
      f7.preloader.show();

      const res = await checkCustomerInviteCode(params);

      const data = res?.data;

      if (data?.success) {
        if (!data?.invalid_invide_code) {
          setIsValidCode(true);
        } else {
          setError(
            '無効なコードです。紹介コードを確認し、再入力してください。',
          );
        }
      } else {
        setError(
          data?.error?.message ||
            '無効なコードです。紹介コードを確認し、再入力してください。',
        );
      }
    } catch (err: any) {
      setError(
        err?.message ||
          '無効なコードです。紹介コードを確認し、再入力してください。',
      );
    } finally {
      f7.preloader.hide();
    }
  };

  return {
    checkInviteCode,
    setError,
    error,
    isValidCode,
  };
};
