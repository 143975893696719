import { f7, useStore } from 'framework7-react';
import { FC } from 'react';
import { RegisterNewMemberStep1 } from './RegisterNewMemberStep1/RegisterNewMemberStep1';
import { RegisterNewMemberStep2 } from './RegisterNewMemberStep2/RegisterNewMemberStep2';
import { RegisterNewMemberStep3 } from './RegisterNewMemberStep3/RegisterNewMemberStep3';
import { RegisterNewMemberStep4 } from './RegisterNewMemberStep4/RegisterNewMemberStep4';
import style from './RegisterNewMemberStyle.module.css';
import { RegisterQRPageTemplate } from '@/components/uiParts/Template/RegisterQRPageTemplate/RegisterQRPageTemplate';
import { RegisterUserDataType } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';
import { storeDispatch } from '@/utils/store';
import './RegisterNewMemberStyle.scss';

interface RegisterNewMemberProps {
  sourcePage?: string;
  startStep?: number;
}

export const RegisterNewMember: FC = ({
  sourcePage,
  startStep,
}: RegisterNewMemberProps) => {
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;
  const { currentStep } = registerUserData;

  const isBackToSourcePage = sourcePage && currentStep === startStep;

  const handleBack = () => {
    if (isBackToSourcePage) {
      f7.view.main.router.navigate(sourcePage, {
        transition: 'push-reverse',
      });
      return;
    }
    if (currentStep > 1) {
      storeDispatch('setRegisterUser', 'registerUser', {
        ...registerUserData,
        currentStep: currentStep - 1,
      });
      return;
    }
    f7.view.main.router.navigate(
      `${paths.registerQR}?affiliate_id=${
        registerUserData.affiliate_id || affiliateId.DEFAULT
      }`,
      {
        transition: 'push-reverse',
      },
    );
  };

  return (
    <RegisterQRPageTemplate
      title={currentStep === 2 ? '入力内容の確認' : '新規会員登録'}
      pageName="RegisterNewMember"
      showNavBack={true}
      handleClickBack={handleBack}
    >
      <div
        className={`${style['page']} ${
          currentStep === 1 ? style['white-page'] : ''
        }`}
      >
        {[1, 2].includes(currentStep) && (
          <div className={style['content']}>
            {currentStep === 1 && <RegisterNewMemberStep1 />}

            {currentStep === 2 && <RegisterNewMemberStep2 />}
          </div>
        )}

        {currentStep === 3 && (
          <RegisterNewMemberStep4
            hanldeCancel={() => {
              if (isBackToSourcePage) {
                handleBack();
                return;
              }
              f7.view.main.router.navigate(paths.loginTutorial);
            }}
          />
        )}
      </div>
    </RegisterQRPageTemplate>
  );
};
