import { Checkbox } from 'framework7-react';
import './f7ComponentStyle.css';
interface CheckBoxItemProps {
  name: string;
  onChange: () => void;
  check: boolean;
  label: string;
}
export const CheckBoxItem = ({
  name,
  onChange,
  check,
  label,
}: CheckBoxItemProps) => {
  return (
    <div className={'check-box-item'} key={name} onClick={onChange}>
      <Checkbox name={name} checked={check} onChange={() => onChange()} />
      <div className={'check-box-label'}>
        <span>{label}</span>
      </div>
    </div>
  );
};
