import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { LOANER_CAR_USE } from '../../const/bodyAndPaint';
import { useRadioSelect } from '../../hook/util/common/useRadioSelect';
import { useTextInput } from '../../hook/util/common/useTextInput';
import { LoanerCarInfoType } from '../../type/bodyAndPaint';
import { CheckBoxItem } from '../checkBox';
import { EntryField } from '../common/entryField';
import { RadioGroup } from '../radioGroup';
import { TextField } from '../textFIeld';

interface EntryLoanerCarFieldProps {
  info: LoanerCarInfoType;
  setInfo: Dispatch<SetStateAction<LoanerCarInfoType>>;
}
export const EntryLoanerCarField = ({
  info,
  setInfo,
}: EntryLoanerCarFieldProps) => {
  const { use_loaner_car, loaner_car_request, approve } =
    info ?? ({} as LoanerCarInfoType);
  const loanerCarUseList: string[] = useMemo(
    () => Object.values(LOANER_CAR_USE).map((item) => item.label),
    [],
  );
  const [approveCheck, setApproveCheck] = useState(approve);
  const [loanerCarUseSelect, loanerCarUseValue, changeLoanerCarUseSelect] =
    useRadioSelect({
      initialSelect: use_loaner_car,
      selectList: loanerCarUseList,
    });
  const [loanerCarRequestValue, changeLoanerCarRequest] = useTextInput({
    initialValue: loaner_car_request,
    required: true,
  });

  const ApproveCheckField = () => {
    const text =
      '代車の状況によってご希望のお車がご用意できない場合がございます。あらかじめご承知おきください。';
    return (
      <div className={'loaner-car-approve'}>
        <div className={'loaner-car-approve-message'}>
          <span>{text}</span>
        </div>
        <div className={'loaner-car-approve-check'}>
          <CheckBoxItem
            name={'loaner-car-approve-check'}
            onChange={() => setApproveCheck(!approveCheck)}
            check={approveCheck}
            label={'内容を確認し、承諾する'}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setInfo((prev) => ({
      ...prev,
      use_loaner_car: loanerCarUseSelect,
      loaner_car_request: loanerCarRequestValue,
      approve: approveCheck,
    }));
  }, [setInfo, loanerCarUseSelect, loanerCarRequestValue, approveCheck]);

  return (
    <div className={'entry-car-condition-field'}>
      <EntryField label={'代車を利用しますか？'} required={true}>
        <RadioGroup
          groupName={'car_driven'}
          list={loanerCarUseList}
          value={loanerCarUseValue}
          onChange={changeLoanerCarUseSelect}
        />
      </EntryField>
      <EntryField label={'代車に関するご希望はありますか？'} required={true}>
        <TextField
          value={loanerCarRequestValue}
          onChange={changeLoanerCarRequest}
          required={true}
          placeholder={'年/月/日'}
        />
      </EntryField>
      <EntryField label={'確認事項'} required={true}>
        <ApproveCheckField />
      </EntryField>
    </div>
  );
};
