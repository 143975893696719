import { FilledButton } from '../button';
import style from './repairCheckInStyle.module.css';

/* Components*/
interface ChangeCheckInDialogCardProps {
  onClick: () => void;
}
export const ChangeCheckInDialogCard = ({
  onClick,
}: ChangeCheckInDialogCardProps) => {
  const dialogLabel = '入庫日を変更しました';
  const dialogText = ['入庫日の予約変更を承りました'];

  const handleClickConfirm = () => onClick();
  return (
    <div className={style.dialogCard}>
      <div className={style.messageField}>
        <div className={style.messageHeader}>
          <span>{dialogLabel}</span>
        </div>
        <div className={style.messageBody}>
          {dialogText.map((item, idx) => (
            <span key={idx}>{item}</span>
          ))}
        </div>
      </div>
      <FilledButton onClick={handleClickConfirm} label={'予約内容を確認する'} />
    </div>
  );
};
