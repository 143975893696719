import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Partial.module.scss';

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps {
  name: string;
  options?: Option[];
  label: string;
  error: string;
  required?: boolean;
}

export const SelectDropdown: FC<SelectProps> = ({
  name,
  options,
  label,
  error = '',
  required = true,
}) => {
  const { register } = useFormContext();

  return (
    <div
      className={`${styles['select-dropdown-container']} ${
        !!error ? styles['select-dropdown-error'] : ''
      }`}
    >
      <label>
        {label}
        <span
          className={`${styles['select-dropdown-label-tips']} ${
            required && styles['required']
          }`}
        >
          {required ? '必須' : '任意'}
        </span>
      </label>
      <div className={styles['select-dropdown-wrap']}>
        <select
          required={required}
          className={styles['select-dropdown']}
          defaultValue=""
          {...register(name)}
        >
          <option disabled hidden />
          {options?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <span className={styles['select-dropdown-caret']} />
      </div>
      {!!error && (
        <p className={styles['select-dropdown-error-message']}>{error}</p>
      )}
    </div>
  );
};
