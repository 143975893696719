import { useCallback, useMemo } from 'react';
import {
  useCheckInReserveDataContext,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import { useLoanerCarInfo } from '../hook/util/repairCheckIn/useLoanerCar';
import {
  CheckInReserveDataType,
  LoanerCarInfoType,
} from '../type/bodyAndPaint';
import { EntryLoanerCarField } from '../util/repairCheckIn/entryUseLoanerCar';
import { NextButtonField } from '../util/repairCheckIn/nextButtonField';

export const CheckInReserveUseLoanerCar = () => {
  const { setState } = useCheckInReserveStateContext();
  const { data } = useCheckInReserveDataContext();
  const { loaner_car_info } = data ?? ({} as CheckInReserveDataType);
  const loanerCarInfo = useMemo(() => loaner_car_info, [loaner_car_info]);

  const handleClickBack = useCallback(() => {
    setState('CHECK_IN_DATE');
  }, [setState]);
  const handleClickNext = useCallback(() => {
    setState('RESERVE_CONFIRM');
  }, [setState]);

  const [loanerCar, setLoanerCar, disable] = useLoanerCarInfo({
    info: loanerCarInfo ?? ({} as LoanerCarInfoType),
  });
  return (
    <>
      <EntryLoanerCarField info={loanerCar} setInfo={setLoanerCar} />
      <NextButtonField disable={disable} onClick={handleClickNext} />
    </>
  );
};
