import { f7 } from 'framework7-react';
import { useCallback, useState } from 'react';
import {
  useRepairProgressDataContext,
  useRepairProgressStateContext,
} from '../context/progressDetailContext';
import { usePostCancelRepairCheckInReserve } from '../hook/api/usePostCancelRepairCheckInReserve';
import { usePostChangeRepairCheckInDate } from '../hook/api/usePostChangeRepairCheckInDate';
import { usePostUpdateInsuranceInfo } from '../hook/api/usePostUpdateInsuranceInfo';
import { useInsuranceInfo } from '../hook/util/repairCheckIn/useInsuranceInfo';
import { useGetCalendarData } from '../hook/util/useGetCalendarData';
import {
  InsuranceInfoType,
  RepairProgressDataType,
} from '../type/bodyAndPaint';
import { AddInsuranceButtonField } from '../util/addInsurance/addInsuranceButtonField';
import { EntryUseInsuranceField } from '../util/addInsurance/entryUseInsurance';
import { ChangeCheckInButtonField } from '../util/calendar/changeCheckInButtonFIeld';
import { DialogComponent } from '../util/dialogFrame';
import { ModalSheet } from '../util/modalSheet';
import { ChangeCheckInDialogCard } from '../util/repairCheckIn/changeCheckInDialogCard';
import { EntryCalendarField } from '../util/repairCheckIn/entryCalendar';
import { CancelReserveDialogCard } from '../util/repairProgress/cancelReserveDialogCard';
import { ChangeDeadlineNoteCard } from '../util/repairProgress/changeDeadlineNoteCard';
import { RepairProgressButton } from '../util/repairProgress/repairProgressButtonField';
import { RepairProgressFactoryField } from '../util/repairProgress/repairProgressFactoryField';
import { RepairProgressInsuranceField } from '../util/repairProgress/repairProgressInsuranceField';
import { RepairProgressReserveField } from '../util/repairProgress/repairProgressReserveField';
import { RepairProgressStatusField } from '../util/repairProgress/repairProgressStatusField';
import { paths } from '@/config/paths';
import './repairProgressDetail.css';

export const ProgressRepairInformation = () => {
  const { data: bpData } = useRepairProgressDataContext();
  const { setState } = useRepairProgressStateContext();
  const { data } = useRepairProgressDataContext();
  const { state_info, reserve_info, insurance_info, factory_info } =
    data ?? ({} as RepairProgressDataType);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openChangeCheckInDialog, setOpenChangeCheckInDialog] = useState(false);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);

  const [stateInsurance, setInsurance, insuranceDisable] = useInsuranceInfo({
    info: {} as InsuranceInfoType,
  });
  const {
    selectDate: selectCheckInDate,
    setSelectDate: setSelectCheckInDate,
    dateItemList,
    selectMonthly: selectMonth,
    handlePrevMonth,
    handleNextMonth,
    disable: calendarDisable,
  } = useGetCalendarData({
    initialDate: reserve_info?.check_in_date,
    plantID: 0, // TODO: API様修正→予約詳細から取得
  });

  const { postRequest: onAddInsurance } = usePostUpdateInsuranceInfo({
    ids: {
      m_customer_id: bpData?.ids?.customerID ?? 0,
      t_pm_car_inspection_id: bpData?.ids?.inspectionID ?? 0,
      m_affiliate_id: bpData?.ids?.affiliateID ?? 0,
      t_body_paint_id: 0, // TODO: API様修正→予約詳細から取得
    },
    insurance: stateInsurance,
  });
  const { postRequest: onChangeCheckIn } = usePostChangeRepairCheckInDate({
    info: {
      m_customer_id: bpData?.ids?.customerID ?? 0,
      t_pm_car_inspections_id: bpData?.ids?.inspectionID ?? 0,
      check_in_date: selectCheckInDate,
    },
  });
  const { postRequest: onCancelReserve } = usePostCancelRepairCheckInReserve({
    m_customer_id: bpData?.ids?.customerID ?? 0,
    t_pm_car_inspection_id: bpData?.ids?.inspectionID ?? 0,
  });

  const dateInfo = new Date(state_info?.check_in_date ?? 'undefined');

  const handleClickBack = useCallback(() => {
    f7.views.main.router.navigate(paths.maintenance);
  }, []);

  const handleClickCheckRepairImage = () => {
    setState('REPAIR_IMAGE');
  };
  const handleClickPickUpReserve = () => {
    setState('PICK_UP_RESERVE');
  };
  const handleClickAddInsurance = async () => {
    onAddInsurance().then(() => {
      setOpenInsuranceModal(false);
    });
  };
  const handleClickChangeCheckInDate = () => {
    onChangeCheckIn().then(() => {
      setOpenCalendarModal(false);
      setOpenChangeCheckInDialog(true);
    });
  };
  const handleClickCheckInCancelConfirm = () => {
    onCancelReserve().then(() => {
      setOpenCancelDialog(false);
      f7.views.main.router.navigate(paths.maintenance);
    });
  };

  return (
    <>
      <div className={'progress-repair-information-field'}>
        <RepairProgressStatusField
          info={state_info}
          checkRepairImage={handleClickCheckRepairImage}
          pickUpReserve={handleClickPickUpReserve}
        />
        <RepairProgressReserveField info={reserve_info} />
        <RepairProgressInsuranceField
          info={insurance_info}
          onClick={() => setOpenInsuranceModal(true)}
        />
        <RepairProgressFactoryField info={factory_info} />
        <ChangeDeadlineNoteCard date={dateInfo} />
        <RepairProgressButton
          changeCheckInDate={() => setOpenCalendarModal(true)}
          cancelCheckInDate={() => setOpenCancelDialog(true)}
        />
      </div>
      <DialogComponent open={openCancelDialog}>
        <CancelReserveDialogCard
          onClose={() => setOpenCancelDialog(false)}
          onConfirm={handleClickCheckInCancelConfirm}
        />
      </DialogComponent>
      <DialogComponent open={openChangeCheckInDialog}>
        <ChangeCheckInDialogCard
          onClick={() => setOpenChangeCheckInDialog(false)}
        />
      </DialogComponent>
      <ModalSheet
        title={'保険の登録'}
        open={openInsuranceModal}
        onClose={() => setOpenInsuranceModal(false)}
      >
        <EntryUseInsuranceField info={stateInsurance} setInfo={setInsurance} />
        <AddInsuranceButtonField
          disable={insuranceDisable}
          onClickAddInsurance={handleClickAddInsurance}
        />
      </ModalSheet>
      <ModalSheet
        title={'日程の変更'}
        open={openCalendarModal}
        onClose={() => setOpenCalendarModal(false)}
      >
        <EntryCalendarField
          select={selectCheckInDate}
          setSelect={setSelectCheckInDate}
          itemList={dateItemList}
          selectMonth={selectMonth}
          onNext={handleNextMonth}
          onPrev={handlePrevMonth}
        />
        <ChangeCheckInButtonField
          disable={calendarDisable}
          onClick={handleClickChangeCheckInDate}
        />
      </ModalSheet>
    </>
  );
};
