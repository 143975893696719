import { Dispatch, SetStateAction } from 'react';
import { useCalendarDateList } from '../../hook/util/useCalendarDateList';
import style from './calendarComponent.module.css';
import { CalendarDateItem } from './calendarDateItem';
import { WEEK_LABEL } from '@/consts/bodyAndPaint';

interface CalendarDateEntryBodyProps {
  selectDate: Date;
  setSelectDate: Dispatch<SetStateAction<Date>>;
  monthDate: Date;
  dateInfoList: { [date_block: string]: number }[];
}
export const CalendarDateEntryBody = ({
  selectDate,
  setSelectDate,
  monthDate,
  dateInfoList,
}: CalendarDateEntryBodyProps) => {
  const calendarList = useCalendarDateList({ monthInfo: monthDate });

  const getWeekLabel = (labelKey: string) => {
    const keyName = labelKey as keyof typeof WEEK_LABEL;
    return keyName === 'SUNDAY'
      ? `${style.dayOfWeek} ${style.sunday}`
      : keyName === 'SATURDAY'
      ? `${style.dayOfWeek} ${style.saturday}`
      : `${style.dayOfWeek}`;
  };
  const handleChangeSelectDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    setSelectDate(newDate);
  };
  const getSize = (date: Date) => {
    const offset = 1;
    const keyDate = `${date.getFullYear()}-${
      date.getMonth() + offset
    }-${date.getDate()}`;
    const item = dateInfoList.find((item) => keyDate in item);
    return item ? item[keyDate] : 0;
  };
  return (
    <div className={style.calendarDateBody}>
      <div className={style.calendarLabel}>
        {Object.keys(WEEK_LABEL).map((item, idx) => (
          <div className={getWeekLabel(item)} key={idx}>
            {WEEK_LABEL[item as keyof typeof WEEK_LABEL]}
          </div>
        ))}
      </div>
      <div className={style.calendarField}>
        {calendarList.map((weekItem, weekIdx) => (
          <div className={style.weekItem} key={weekIdx}>
            {weekItem.map((dateItem, dateIdx) => (
              <div
                onClick={() => handleChangeSelectDate(dateItem)}
                key={dateIdx}
              >
                <CalendarDateItem
                  calendarDate={dateItem}
                  selectDate={selectDate}
                  availableSize={getSize(dateItem)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
