import { OutlinedButton, FilledButton, TextButton } from '../button';

interface RepairRequestButtonFieldProps {
  isEstimating: boolean;
  isInsurance: boolean;
  onClickRepairSelect: () => void;
  onClickAddInsurance: () => void;
  onClickNoRepair: () => void;
}
export const RepairRequestButtonField = ({
  isEstimating,
  isInsurance,
  onClickRepairSelect,
  onClickAddInsurance,
  onClickNoRepair,
}: RepairRequestButtonFieldProps) => {
  const RepairRequestWithInsurance = () => {
    return (
      <div className={'button-field estimate-result'}>
        <OutlinedButton onClick={onClickNoRepair} label={'修理しない'} />
        <FilledButton
          onClick={onClickRepairSelect}
          label={'修理依頼に進む'}
          disabled={isEstimating}
        />
      </div>
    );
  };
  const RepairRequestNoInsurance = () => {
    return (
      <div className={'button-field estimate-no-insurance'}>
        <div className={'request-button-field'}>
          <OutlinedButton
            onClick={onClickAddInsurance}
            label={'保険情報を登録'}
          />
          <FilledButton
            onClick={onClickRepairSelect}
            label={'このまま修理依頼'}
            disabled={isEstimating}
          />
        </div>
        <TextButton onClick={onClickNoRepair} label={'修理しない'} />
      </div>
    );
  };
  return (
    <>
      {isInsurance && <RepairRequestWithInsurance />}
      {!isInsurance && <RepairRequestNoInsurance />}
    </>
  );
};
