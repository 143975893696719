import { useCalendarStyle } from '../../hook/util/useCalendarStyle';

interface CalendarDateItemProps {
  calendarDate: Date;
  selectDate: Date;
  availableSize: number;
}
export const CalendarDateItem = ({
  calendarDate,
  selectDate,
  availableSize,
}: CalendarDateItemProps) => {
  const { selectStyle, reserveStyle } = useCalendarStyle({
    calendarDate,
    selectDate,
    availableSize,
  });
  return (
    <div className={selectStyle}>
      <div>
        <span>{calendarDate.getDate().toString()}</span>
      </div>
      <div className={reserveStyle}>
        <i className={'status-icon'} />
      </div>
    </div>
  );
};
