import { ReactNode } from 'react';

export const ScrollField = ({
  orientation,
  children,
}: {
  orientation: 'horizontal' | 'vertical';
  children: ReactNode;
}) => {
  return (
    <div className={'scroll-field'}>
      <div className={`scroll-content ${orientation}`}>{children}</div>
    </div>
  );
};
