import { useMemo } from 'react';
import { BODY_PAINT_STATE } from '../../const/bodyAndPaint';
import { useDateTimeText } from '../../hook/util/useDateTimeText';
import { FilledButton, OutlinedButton } from '../button';
import { FactoryInfoCard } from '../common/factoryInfoCard';
import { REPAIR_PROGRESS_STATE } from '@/consts/bodyAndPaint';
import { RepairProgressStatusInfoType } from '@/types/bodyAndPaint';
import '../../repairProgress/repairProgressDetailStyle.css';

interface RepairProgressStatusProps {
  info: RepairProgressStatusInfoType;
  checkRepairImage: () => void;
  pickUpReserve: () => void;
}
export const RepairProgressStatusField = ({
  info,
  checkRepairImage,
  pickUpReserve,
}: RepairProgressStatusProps) => {
  const {
    status,
    check_in_date,
    pick_up_date,
    factory_name,
    factory_gps_info,
    factory_telephone,
  } = info ?? ({} as RepairProgressStatusInfoType);

  const mobiconState: Record<
    keyof typeof BODY_PAINT_STATE,
    typeof REPAIR_PROGRESS_STATE[number]
  > = {
    ACCIDENT_ACCEPT: 'CHECK_IN_ACCEPTED',
    CHECK_IN_ACCEPT: 'REPAIR_ESTIMATING',
    ESTIMATE_APPLY: 'REPAIR_EXECUTING',
    INSURANCE_APPLY: 'REPAIR_ESTIMATING',
    DETERMINED_APPLIES: 'REPAIR_ESTIMATING',
    APPLIES_RESULT: 'REPAIR_EXECUTING',
    REPAIR_CLAIM: 'REPAIR_EXECUTING',
    NO_REPAIR: 'CHECK_IN_CANCELED',
    DISASSEMBLY: 'REPAIR_EXECUTING',
    REPAIR_HAS_DONE: 'REPAIR_HAS_DONE',
    INSPECTION_HAS_DONE: 'REPAIR_HAS_DONE',
    DELIVERED_HAS_DONE: 'DELIVERED_HAS_DONE',
    REPAIR_CANCELLED: 'CHECK_IN_CANCELED',
  };
  const currenState = useMemo(
    () => Object.values(mobiconState)[status],
    [status],
  );
  const checkInDate = useDateTimeText({ dateTime: new Date(check_in_date) });
  const pickUpDate = useDateTimeText({
    dateTime: new Date(pick_up_date ?? 'undefined'),
  });
  const stateImageClass: Record<typeof REPAIR_PROGRESS_STATE[number], string> =
    {
      CHECK_IN_ACCEPTED: 'check-in-accepted',
      CHECK_IN_COMPLETED: 'check-in-completed',
      CHECK_IN_CANCELED: 'check-in-canceled',
      REPAIR_ESTIMATING: 'repair-estimating',
      REPAIR_EXECUTING: 'repair-executing',
      REPAIR_HAS_DONE: 'repair-has-done',
      DELIVERED_HAS_DONE: 'delivered-has-done',
    };
  const stateHeaderText: Record<typeof REPAIR_PROGRESS_STATE[number], string> =
    {
      CHECK_IN_ACCEPTED: '入庫予約を受け付けました',
      CHECK_IN_COMPLETED: '入庫予約が完了しました',
      CHECK_IN_CANCELED: 'ご予約を取り消しました',
      REPAIR_ESTIMATING: '見積もり中',
      REPAIR_EXECUTING: '修理中',
      REPAIR_HAS_DONE: '修理完了',
      DELIVERED_HAS_DONE: '納車済み',
    };
  const stateBodyText: Record<typeof REPAIR_PROGRESS_STATE[number], string[]> =
    {
      CHECK_IN_ACCEPTED: [
        'ただいま予約状況を確認しておりますので',
        '入庫日確定までしばらくお待ちください。',
      ],
      CHECK_IN_COMPLETED: ['入庫日が確定しました。'],
      CHECK_IN_CANCELED: ['またのご利用をお待ちしております。'],
      REPAIR_ESTIMATING: [
        '工場への搬送が完了いたしました。',
        '見積書が届くまで今しばらくお待ちください。',
      ],
      REPAIR_EXECUTING: [
        'お客様のご依頼内容を確認し、',
        '工場にて着工開始致しました。',
        '修理が終わるまで今しばらくお待ちください。',
      ],
      REPAIR_HAS_DONE: pick_up_date
        ? [
            'お引き取り日が確定しました。',
            '当日になりましたら、工場にお越しください。',
          ]
        : [
            '工場より修理完了のご報告がございました。',
            'お車のお引き取り可能日につきましては、',
            'こちらからご予約をお願いします。',
          ],
      DELIVERED_HAS_DONE: [
        'この度はご利用いただき',
        '誠にありがとうございました。',
      ],
    };

  const handleClickCheckRepairImage = () => checkRepairImage();
  const handleClickPickUpReserve = () => pickUpReserve();

  const StatusIconField = () => {
    return (
      <div className={`repair-status-icon ${stateImageClass[currenState]}`}>
        <i className={'icon'} />
      </div>
    );
  };
  const StatusMessageField = () => {
    return (
      <div className={`repair-status-message`}>
        <div className={`message-header`}>
          <span>{stateHeaderText[currenState]}</span>
        </div>
        <div className={`message-body`}>
          {stateBodyText[currenState] &&
            stateBodyText[currenState].map((text, idx) => (
              <span key={idx}>{text}</span>
            ))}
        </div>
      </div>
    );
  };
  const ScheduleDateField = () => {
    const ScheduleDateItem = ({
      label,
      date,
    }: {
      label: string;
      date: string;
    }) => {
      return (
        <div className={'schedule-date-field'}>
          <div className={'schedule-tag-label'}>
            <span>{label}</span>
          </div>
          <div className={'schedule-info-date'}>
            <span>{date}</span>
          </div>
        </div>
      );
    };
    switch (currenState) {
      case 'CHECK_IN_ACCEPTED':
      case 'CHECK_IN_COMPLETED':
        return <ScheduleDateItem label={'入庫日'} date={checkInDate} />;
      case 'REPAIR_HAS_DONE':
      case 'DELIVERED_HAS_DONE':
        return pickUpDate ? (
          <ScheduleDateItem label={'引き取り日'} date={pickUpDate} />
        ) : (
          <OutlinedButton
            label={'修理箇所の画像を確認'}
            onClick={handleClickCheckRepairImage}
          />
        );
      default:
        return <></>;
    }
  };
  const PickUpDateReserveButtonField = () => {
    return (
      <div className={'button-field reserve-pick-up'}>
        <FilledButton
          onClick={handleClickPickUpReserve}
          label={'返却日を予約する'}
        />
      </div>
    );
  };
  return (
    <div className={'repair-progress-status-info'}>
      <StatusIconField />
      <StatusMessageField />
      <ScheduleDateField />
      <FactoryInfoCard
        info={{
          factoryName: factory_name,
          telephone: factory_telephone,
          address: factory_gps_info,
        }}
      />
      {currenState === 'REPAIR_HAS_DONE' && <PickUpDateReserveButtonField />}
    </div>
  );
};
