import { useState, useCallback, BaseSyntheticEvent } from 'react';

interface TextFieldProps {
  initialValue: string;
  required: boolean;
}
export const useTextInput = ({ initialValue, required }: TextFieldProps) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const handleChangeValue = useCallback(
    (e: BaseSyntheticEvent) => {
      if (required && value === '' && initialValue !== '') {
        setError(true);
      }
      setValue(e.target.value);
    },
    [initialValue, required, value],
  );
  return [value, handleChangeValue, error] as const;
};
