import { OutlinedButton } from '../button';
import { EstimateDetailItemInfoType } from '@/types/bodyAndPaint';

interface EstimateDetailItemCardProps {
  info: EstimateDetailItemInfoType[];
  onClick: () => void;
}
export const EstimateDetailItemCard = ({
  info,
  onClick,
}: EstimateDetailItemCardProps) => {
  const handleClickButton = () => onClick();

  const EstimateItem = ({
    infoItem,
  }: {
    infoItem: EstimateDetailItemInfoType;
  }) => {
    const { name, repair_amount, applied_amount } = infoItem;
    const repairAmount = repair_amount.toLocaleString('ja-JP') + '円';
    const appliedAmount =
      applied_amount && applied_amount.toLocaleString('ja-JP') + '円';
    const style = applied_amount === undefined ? 'no-apply' : 'applied';
    const isInsurance =
      applied_amount === undefined ? '保険適用不可' : '保険適用可';
    return (
      <div className={'estimate-item'}>
        <div className={'estimate-item-header'}>
          <div className={'estimate-item-label'}>
            <span>{name}</span>
          </div>
          <div className={`estimate-item-status ${style}`}>
            <span>{isInsurance}</span>
          </div>
        </div>
        <div className={'estimate-item-body'}>
          <div className={`repair-amount ${style}`}>
            <span>{repairAmount}</span>
          </div>
          {appliedAmount && (
            <div className={'applied-amount'}>
              <span>{appliedAmount}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={'estimate-detail-item-field'}>
      <div className={'estimate-detail-item-header'}>
        <div className={'header-label'}>
          <span>{'見積もり詳細'}</span>
        </div>
        <div className={'image-check-button'}>
          <OutlinedButton
            onClick={handleClickButton}
            label={'修理箇所の画像を確認'}
          />
        </div>
      </div>
      <div className={'estimate-detail-item-body'}>
        <div className={'estimate-detail-item-card'}>
          {info?.map((item, idx) => (
            <EstimateItem infoItem={item} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};
