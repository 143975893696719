import { f7 } from 'framework7-react';
import { useMemo, useState } from 'react';
import { ITEM_REPAIR } from '../const/bodyAndPaint';
import { useRepairProgressDataContext } from '../context/progressDetailContext';
import {
  useRepairRequestDataContext,
  useRepairRequestStateContext,
} from '../context/repairRequestContext';
import { usePostRepairRequest } from '../hook/api/usePostRepairRequest';
import { RepairItemConfirmInfoType } from '../type/bodyAndPaint';
import { DialogComponent } from '../util/dialogFrame';
import { RepairRequestConfirmField } from '../util/repairRequest/repairRequestConfirmField';
import { RepairRequestDialogCard } from '../util/repairRequest/repairRequestDialogCard';
import { RequestConfirmButtonField } from '../util/repairRequest/requestConfirmButtonField';
import { paths } from '@/config/paths';

export const RepairRequestConfirm = () => {
  const { data: bpData } = useRepairProgressDataContext();
  const { setState } = useRepairRequestStateContext();
  const { data } = useRepairRequestDataContext();

  const [openDialog, setOpenDialog] = useState(false);
  const isEstimating = useMemo(
    () => data?.estimate_result_info.is_estimating ?? true,
    [data],
  );
  const confirmInfo = useMemo(
    () => data?.repair_item_confirm_info ?? ({} as RepairItemConfirmInfoType),
    [data],
  );
  const repairItemList = useMemo(
    () =>
      confirmInfo.repair_request_select_info_list.map((item) => {
        const key = Object.keys(ITEM_REPAIR)[
          item.repair_select ?? 0
        ] as keyof typeof ITEM_REPAIR;
        return {
          t_pm_car_inspection_need_maintenance_id: item.repair_item_id,
          repair_contents: ITEM_REPAIR[key].value,
        };
      }),
    [confirmInfo.repair_request_select_info_list],
  );

  const { postRequest } = usePostRepairRequest({
    info: {
      m_customer_id: bpData?.ids.customerID ?? 0,
      t_estimate_id: data?.ids.estimateID ?? 0,
      m_affiliate_id: bpData?.ids.affiliateID ?? 0,
      t_body_paint_id: data?.ids.bodyPaintID ?? 0,
      repair_item: repairItemList,
    },
  });

  const handleClickBack = () => {
    setState('ESTIMATE_RESULT');
  };
  const handleClickRequestCorrect = () => {
    setState('REPAIR_SELECT');
  };

  const handleClickRequestConfirm = async () => {
    postRequest().then(() => {
      setOpenDialog(true);
    });
  };
  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    f7.views.main.router.navigate(paths.maintenance);
  };

  return (
    <>
      <RepairRequestConfirmField info={confirmInfo} />
      <RequestConfirmButtonField
        onCancel={handleClickRequestCorrect}
        onRequest={handleClickRequestConfirm}
        isEstimating={isEstimating}
      />
      <DialogComponent open={openDialog}>
        <RepairRequestDialogCard onClose={handleClickCloseDialog} />
      </DialogComponent>
    </>
  );
};
