export const customerType = {
  PERSONAL: 1,
  CORPORATION: 2,
  TRADER: 3,
};

export const genders = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};

export const affiliateInquiryFormType = {
  DEFAULT: 1,
  CUSTOMIZE: 2,
} as const;

export const customerTypes = {
  CORPORATION: 2,
  PERSONAL: 1,
};
