import { FC } from 'react';
import style from './bannerPopupPageStyle.module.css';
import { PopupPageTemplate } from '@/components/uiParts/Template/PopupPageTemplate/PopupPageTemplate';

interface BannerPopupPageProps {
  path: string;
}
export const BannerPopupPage: FC<BannerPopupPageProps> = ({ path }) => {
  const iframeStyle = {
    width: '100%',
    height: '80rem',
  };
  return (
    <PopupPageTemplate
      title=""
      pageName="bannerPopup"
    >
      <div className={style['banner-popup-top']}>
        <div className={style['banner-popup-card']}>
            <iframe style={iframeStyle} src={path}/>
        </div>
      </div>
    </PopupPageTemplate>
  );

};
