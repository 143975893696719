import { ReactNode } from 'react';
import { AccordionField } from '../accordion';
import { RepairProgressFactoryInfoType } from '@/types/bodyAndPaint';

interface RepairProgressFactoryProps {
  info: RepairProgressFactoryInfoType;
}
export const RepairProgressFactoryField = ({
  info,
}: RepairProgressFactoryProps) => {
  const {
    factory_name,
    factory_address,
    factory_gps_info,
    factory_telephone,
    opening_hours,
    payment_method,
  } = info ?? ({} as RepairProgressFactoryInfoType);
  const handleClickMapAddressLink = () => {};
  const handleClickTelephoneLink = () => {};
  const FactoryName = () => {
    return (
      <div className="repair-progress-info-item">
        <span>{factory_name}</span>
      </div>
    );
  };
  const FactoryAddress = () => {
    return (
      <div className="repair-progress-info-item">
        <span>{factory_address}</span>
        {factory_gps_info && (
          <div className={'link-text'} onClick={handleClickMapAddressLink}>
            <span>{'地図アプリでみる'}</span>
          </div>
        )}
      </div>
    );
  };
  const FactoryTelephone = () => {
    return (
      <div className="repair-progress-info-item">
        {factory_telephone && (
          <div className={'link-text'} onClick={handleClickTelephoneLink}>
            <span>{factory_telephone}</span>
          </div>
        )}
      </div>
    );
  };
  const OpeningHours = () => {
    return (
      <div className="repair-progress-info-item">
        <span>{opening_hours}</span>
      </div>
    );
  };
  const PaymentMethods = () => {
    return (
      <div className="repair-progress-info-item">
        <span>{payment_method}</span>
      </div>
    );
  };
  const infoList: Record<string, ReactNode> = {
    店舗名: <FactoryName />,
    店舗住所: <FactoryAddress />,
    電話番号: <FactoryTelephone />,
    営業時間: <OpeningHours />,
    支払い方法: <PaymentMethods />,
  };
  return (
    <AccordionField title={'入庫工場'}>
      {Object.entries(infoList).map(([label, item], idx) => (
        <div className={'repair-progress-info'} key={idx}>
          <div className={'repair-progress-info-label'}>
            <span>{label}</span>
          </div>
          {item}
        </div>
      ))}
    </AccordionField>
  );
};
