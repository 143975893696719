import { DamageMapInfoType } from '@/types/bodyAndPaint';

/* Mobicon BPS 列挙定数定義*/
export const CAR_DRIVEN = {
  DRIVEN: { value: 0, label: 'できる' },
  CAN_NOT: { value: 1, label: 'できない' },
  NOT_SURE: { value: 2, label: '分からない' },
} as const;
export const DAMAGE_DEGREE = {
  INCIDENT: { value: 0, label: '大（事故など）' },
  CONTACT: { value: 1, label: '中（接触など）' },
  SCRATCH: { value: 2, label: '小（擦りなど）' },
} as const;
export const OTHER_PARTY = {
  NO_EXIST: { value: 0, label: 'いない' },
  EXIST: { value: 1, label: 'いる' },
} as const;
export const INSURANCE_USE = {
  YES_USE: { value: 0, label: '利用する' },
  NOT_USE: { value: 1, label: '利用しない' },
  NOT_SURE: { value: 2, label: '利用するか検討中' },
} as const;
export const HAS_DEDUCTIBLE = {
  YES: { value: 0, label: 'あり' },
  NO: { value: 1, label: 'なし' },
} as const;
export const REPAIR_PLAN = {
  YES: { value: 0, label: '修理予定' },
  NO: { value: 1, label: '見積もりのみ希望で修理をしない' },
} as const;
export const LOANER_CAR_USE = {
  YES_USE: { value: 0, label: '利用する' },
  NOT_USE: { value: 1, label: '利用しない' },
} as const;
export const ITEM_REPAIR = {
  USE_INSURANCE: { value: 2, label: '保険を使って修理する' },
  NO_INSURANCE: { value: 1, label: '保険を使わず、修理する' },
  NO_REPAIR: { value: 0, label: '修理しない' },
} as const;

export const EDGE_SIDE_MAP = {
  left: 0,
  center: 1,
  right: 2,
} as const;
export const DOOR_SIDE_MAP = {
  left: 0,
  right: 1,
} as const;
export const DAMAGE_MAP: Record<string, { map: object; label: string }> = {
  front_side: { map: EDGE_SIDE_MAP, label: '前側' },
  front_door: { map: DOOR_SIDE_MAP, label: '前ドア' },
  rear_door: { map: DOOR_SIDE_MAP, label: '後ドア' },
  rear_side: { map: EDGE_SIDE_MAP, label: '後側' },
} as const;

export const WEEK_LABEL = {
  SUNDAY: '日',
  MONDAY: '月',
  TUESDAY: '火',
  WEDNESDAY: '水',
  THURSDAY: '木',
  FRIDAY: '金',
  SATURDAY: '土',
} as const;

export const CONFIRM_CHECK_IN_RESERVE = {
  car_name: '対象車両',
  category: '入庫区分',
  factory: '入庫工場',
};
export const CONFIRM_CAR_CONDITION = {
  car_drive: '自走できるか',
  damage_part: '損傷箇所',
  notice_date: 'いつから',
  damage_degree: '損傷の程度',
  has_other_party: '相手がいる事故か',
  description: '詳細',
  use_insurance: '保険の利用',
  company_name: '保険会社',
  certificate_number: '証券番号',
  receipt_number: '事故受付番号',
  deductible: '免責金',
  repair_plan: '見積もり後の修理予定',
};
export const DAMAGE_PART: Record<keyof DamageMapInfoType, string> = {
  front_side_left: '前方部分 左側の破損',
  front_side_center: '前方部分 中央の破損',
  front_side_right: '前方部分 右側の破損',
  front_door_left: '前ドア部分 左ドアの破損',
  front_door_right: '前ドア部分 右ドアの破損',
  rear_door_left: '後ドア部分 左ドアの破損',
  rear_door_right: '後ドア部分 右ドアの破損',
  rear_side_left: '後方部分 左側の破損',
  rear_side_center: '後方部分 中央の破損',
  rear_side_right: '後方部分 右側の破損',
};
export const CONFIRM_LOANER_CAR = {
  use_loaner_car: '代車',
};
export const CONFIRM_CHECK_IN_DATE = {
  check_in_date: '入庫日',
};

export const REPAIR_PROGRESS_STATE = [
  'CHECK_IN_ACCEPTED',
  'CHECK_IN_COMPLETED',
  'CHECK_IN_CANCELED',
  'REPAIR_ESTIMATING',
  'REPAIR_EXECUTING',
  'REPAIR_HAS_DONE',
  'DELIVERED_HAS_DONE',
] as const;
