import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchBanners } from '@/api/bannerApi';
import { url } from '@/consts/url';
import {
  FetchBannersParams,
  FetchBannersResponse,
} from '@/types/api/bannerApi';

export const useFetchBanners = ({
  m_mt_service_category_id,
  m_affiliate_id,
  limit,
}: FetchBannersParams) => {
  const [data, setData] = useState<FetchBannersResponse['data']>();
  const [banners, setBanners] =
    useState<FetchBannersResponse['data']['m_banners']>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();

    const fn = async () => {
      try {
        const res = await fetchBanners({
          m_mt_service_category_id,
          m_affiliate_id,
          limit,
        });
        const data = res.data;

        const banners = data.m_banners;

        setData(data);
        setBanners(banners);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };

    fn();
  }, [limit, m_affiliate_id, m_mt_service_category_id]);

  return {
    data,
    error,
    banners,
  };
};

// バナー画像のパスを生成
export const getBannerImageSrc = (path?: string) => {
  return path ? url.MOBICON_API + window.btoa(path) : url.MOBICON_API;
};
