import { sanctumApi } from './authApi';
import { baseApi, baseFormDataApi } from '@/config/axios';
import {
  CustomerInviteCodeParams,
  CustomerInviteCodeResponse,
  GenerateCustomerInviteCodeParams,
  GenerateCustomerInviteCodeResponse,
  InspectionCertificationsResponse,
  RegistCustomerParams,
  RegistCustomerResponse,
} from '@/types/api/registerQRApi';

export const checkCustomerInviteCode = async (
  params: CustomerInviteCodeParams,
): Promise<CustomerInviteCodeResponse> => {
  await sanctumApi();
  return baseApi.put('/v1/customer/invite-code', params);
};

export const generateCustomerInviteCode = (
  params: GenerateCustomerInviteCodeParams,
): Promise<GenerateCustomerInviteCodeResponse> =>
  baseApi.post('/v1/customer/invite-code', params);

export const postRegisterCustomer = async (
  params: RegistCustomerParams,
): Promise<RegistCustomerResponse> => {
  await sanctumApi();
  return baseApi.post('/v1/customer/regist_customer', params);
};

export const postInspectionCertifications = async (
  params: FormData,
): Promise<InspectionCertificationsResponse> => {
  await sanctumApi();
  return baseFormDataApi.post(
    '/v1/customer/car/inspection-certifications',
    params,
  );
};
