import { useCallback } from 'react';
import { DamageMapItemType } from '../../type/bodyAndPaint';
import style from '../bpUtilityStyle.module.css';
import { CheckBoxItem } from '../checkBox';
import { DAMAGE_MAP } from '@/consts/bodyAndPaint';

interface DamageCheckMapProps {
  map: DamageMapItemType;
  setMap: (key: keyof DamageMapItemType) => void;
}
export const DamageCheckMap = ({ map, setMap }: DamageCheckMapProps) => {
  const CheckBoxLabel: Record<keyof DamageMapItemType, string> = {
    front_side_left: '左側',
    front_side_center: '中央',
    front_side_right: '右側',
    front_door_left: '左側',
    front_door_right: '右側',
    rear_door_left: '左側',
    rear_door_right: '右側',
    rear_side_left: '左側',
    rear_side_center: '中央',
    rear_side_right: '右側',
  };
  const handleCheckBox = useCallback(
    (keyName: keyof DamageMapItemType) => {
      setMap(keyName);
    },
    [setMap],
  );
  return (
    <div className={style.damageMapField}>
      {Object.entries(DAMAGE_MAP).map(
        ([damage_key, damage_item], damage_idx) => {
          return (
            <div className={style.damageMap} key={damage_idx}>
              <div className={style.damageMapLabel}>
                <span>{damage_item.label}</span>
              </div>
              <div className={style.damageMapRecordField}>
                {Object.keys(damage_item.map).map((detail_key) => {
                  const keyName =
                    `${damage_key}_${detail_key}` as keyof DamageMapItemType;
                  return (
                    <CheckBoxItem
                      key={detail_key}
                      name={'damage-check'}
                      onChange={() => handleCheckBox(keyName)}
                      check={map[keyName] ?? false}
                      label={CheckBoxLabel[keyName] ?? ''}
                    />
                  );
                })}
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
