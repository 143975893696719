import { FilledButton } from '../button';
import style from './addInsurance.module.css';

interface ReEstimateButtonFieldProps {
  disable: boolean;
  onClickAddInsurance: () => void;
  onClickEntryCancel: () => void;
}
export const ReEstimateButtonField = ({
  disable,
  onClickAddInsurance,
  onClickEntryCancel,
}: ReEstimateButtonFieldProps) => {
  const handleClickAddInsurance = () => onClickAddInsurance();
  const handleClickEntryCancel = () => onClickEntryCancel();
  return (
    <div className={style.reEstimateButtonField}>
      <FilledButton
        onClick={handleClickAddInsurance}
        label={'保険情報を登録して再見積もり'}
        disabled={disable}
      />
      <FilledButton onClick={handleClickEntryCancel} label={'キャンセル'} />
    </div>
  );
};
