import { Dispatch, SetStateAction } from 'react';
import { ScrollField } from '../scrollField';

interface CalendarTimeEntryFieldProps {
  timeList: Date[];
  selectDate: Date;
  setSelectDate: Dispatch<SetStateAction<Date>>;
}
export const CalendarTimeEntryField = ({
  timeList,
  selectDate,
  setSelectDate,
}: CalendarTimeEntryFieldProps) => {
  const timeSelectClass = (date: Date) => {
    return !!(selectDate.getHours() === date.getHours()) &&
      !!(selectDate.getMinutes() === date.getMinutes())
      ? 'time-item select'
      : 'time-item';
  };

  const handleClickTimeItem = (dateTime: Date) => {
    setSelectDate(dateTime);
  };

  return (
    <div className={'calendar-time-field'}>
      <ScrollField orientation={'horizontal'}>
        <div className={'calendar-time-content'}>
          {timeList.map((item, idx) => (
            <div
              className={timeSelectClass(item)}
              onClick={() => handleClickTimeItem(item)}
              key={idx}
            >
              <span>{`${item.getHours()}:${item.getMinutes()}`}</span>
            </div>
          ))}
        </div>
      </ScrollField>
    </div>
  );
};
