import { Sheet } from 'framework7-react';
import { ReactNode } from 'react';
import './f7ComponentStyle.css';

interface ModalSheetProps {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}
export const ModalSheet = ({
  title,
  open,
  onClose,
  children,
}: ModalSheetProps) => {
  const ModalHeader = () => {
    return (
      <div className={'modal-header'}>
        <div className={'close-button'} onClick={onClose}>
          <i className={'icon-close'} />
        </div>
        <div className={'header-label'}>
          <span>{title}</span>
        </div>
      </div>
    );
  };
  return (
    <Sheet backdrop swipeToClose opened={open} onSheetClosed={onClose}>
      <ModalHeader />
      <div className={'modal-body'}>{children}</div>
    </Sheet>
  );
};
