import { FilledButton } from '../button';
import style from './addInsurance.module.css';
interface AddInsuranceButtonFieldProps {
  disable: boolean;
  onClickAddInsurance: () => void;
}
export const AddInsuranceButtonField = ({
  disable,
  onClickAddInsurance,
}: AddInsuranceButtonFieldProps) => {
  const handleClickAddInsurance = () => onClickAddInsurance();
  return (
    <div className={style.addInsuranceButtonField}>
      <FilledButton
        onClick={handleClickAddInsurance}
        label={'保険情報を登録して再見積もり'}
        disabled={disable}
      />
    </div>
  );
};
