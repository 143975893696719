import { useCallback, useMemo } from 'react';
import { InsuranceInfoType } from '../../type/bodyAndPaint';
import { postUpdateInsuranceInfoApi } from '@/api/bodyAndPaint';
import { UpdateInsuranceInfoParams } from '@/types/api/bodyAndPaint';

interface PostUpdateInsuranceInfoHookProps {
  ids: {
    m_customer_id: number;
    t_pm_car_inspection_id: number;
    m_affiliate_id: number;
    t_body_paint_id: number;
  };
  insurance: InsuranceInfoType;
}
export const usePostUpdateInsuranceInfo = ({
  ids,
  insurance,
}: PostUpdateInsuranceInfoHookProps) => {
  const parameters: UpdateInsuranceInfoParams = useMemo(
    () => ({
      m_customer_id: ids.m_customer_id,
      t_pm_car_inspection_id: ids.t_pm_car_inspection_id,
      m_affiliate_id: ids.m_affiliate_id,
      t_body_paint_id: ids.t_body_paint_id,
      insurance_info: {
        insurance_use_flg: insurance.use_insurance,
        m_insurance_company_id: insurance.company_id,
        policy_number: Number(insurance.certificate_number),
        has_deductible: !!insurance.has_deductible,
        deductible_fee: insurance.deductible_amount ?? 0,
        repair_planning: insurance.repair_plan,
        accident_reception_number: Number(insurance.receipt_number),
      },
    }),
    [ids, insurance],
  );
  const postRequest = useCallback(async () => {
    try {
      if (parameters) {
        const fetch = await postUpdateInsuranceInfoApi(parameters);
        return fetch;
      } else {
        throw 'invalid params';
      }
    } catch (error) {
      console.error(error);
    }
  }, [parameters]);

  return { postRequest };
};
