import { Router } from 'framework7/types';
import { ServicePage } from '@/components/pages/Service/ServicePage';
import { paths } from '@/config/paths';

export const serviceRoutes: Router.RouteParameters[] = [
  {
    path: paths.service,
    component: ServicePage,
    name: 'サービス',
  },
  {
    path: paths.serviceCategory(':id'),
    component: ServicePage,
    name: 'サービスカテゴリー',
  },
];
