import { Button } from 'framework7-react';
import './f7ComponentStyle.css';
interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  warning?: boolean;
  cancelate?: boolean;
  buttonStyle?: 'normal' | 'warning' | 'cancelate';
}

export const FilledButton = ({
  label,
  onClick,
  disabled = false,
  buttonStyle = 'normal',
}: ButtonProps) => {
  return (
    <Button
      className={buttonStyle}
      fill
      round
      onClick={onClick}
      disabled={disabled}
    >
      <span>{label}</span>
    </Button>
  );
};

export const OutlinedButton = ({
  label,
  onClick,
  disabled = false,
  buttonStyle = 'normal',
}: ButtonProps) => {
  return (
    <Button
      className={buttonStyle}
      outline
      round
      onClick={onClick}
      disabled={disabled}
    >
      <span>{label}</span>
    </Button>
  );
};

export const TextButton = ({
  label,
  onClick,
  disabled = false,
  buttonStyle = 'normal',
}: ButtonProps) => {
  return (
    <Button className={buttonStyle} onClick={onClick} disabled={disabled}>
      <span>{label}</span>
    </Button>
  );
};
