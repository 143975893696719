import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DamageMapItemType,
  RepairProgressDataType,
} from '../../../type/bodyAndPaint';
import { useBodyAndPaintData } from '../../api/useGetBodyAndPaintData';
import { useGetRepairWorkInProgressData } from '../../api/useGetRepairWorkInProgressData';
import { useDamagePartText } from '../useDamagePartText';
import { useOpeningHoursInfo } from '../useOpeningHoursInfo';
import { BodyAndPaintDetailResponse } from '@/types/api/bodyAndPaint';

interface RepairProgressDataHookProps {
  affiliateID: number;
  customerID: number;
  reservationID: number;
  stockCarID: number;
  unitID: number;
}
export const useRepairProgressData = ({
  affiliateID,
  customerID,
  reservationID,
  stockCarID,
  unitID,
}: RepairProgressDataHookProps) => {
  const { bpDetailData } = useBodyAndPaintData({
    affiliateID,
    reservationID,
  });
  const { reservation, insurance_info, plant_info } = useMemo(
    () => bpDetailData ?? ({} as BodyAndPaintDetailResponse),
    [bpDetailData],
  );
  const inspectionID = useMemo(
    () => reservation?.t_pm_car_inspection_id ?? 0,
    [reservation],
  );
  const damagePartInfo: Record<keyof DamageMapItemType, boolean> =
    useMemo(() => {
      return {
        front_side_left: reservation?.front_side_left ?? false,
        front_side_center: reservation?.front_side_center ?? false,
        front_side_right: reservation?.front_side_right ?? false,
        front_door_left: reservation?.front_door_left ?? false,
        front_door_right: reservation?.front_door_right ?? false,
        rear_door_left: reservation?.rear_door_left ?? false,
        rear_door_right: reservation?.rear_door_right ?? false,
        rear_side_left: reservation?.rear_side_left ?? false,
        rear_side_center: reservation?.rear_side_center ?? false,
        rear_side_right: reservation?.rear_side_right ?? false,
      };
    }, [reservation]);
  const openingHoursInfo = useMemo(() => {
    return {
      opening: plant_info?.opening_time ?? '',
      closing: plant_info?.closing_time ?? '',
      closed: {
        sun: plant_info?.sun ?? 0,
        mon: plant_info?.mon ?? 0,
        tue: plant_info?.tue ?? 0,
        wed: plant_info?.wed ?? 0,
        thu: plant_info?.thu ?? 0,
        fri: plant_info?.fri ?? 0,
        sat: plant_info?.sat ?? 0,
      },
    };
  }, [plant_info]);

  const { damagePartList } = useGetRepairWorkInProgressData({
    t_pm_car_inspection_id: inspectionID,
  });
  const damagePartText = useDamagePartText({ damagePart: damagePartInfo });
  const openingTimes = useOpeningHoursInfo(openingHoursInfo);

  const [repairProgressData, setRepairProgressData] =
    useState<RepairProgressDataType>({} as RepairProgressDataType);
  const date = useMemo(() => new Date(), []);

  // 更新情報
  const update: RepairProgressDataType = useMemo(() => {
    const saleID = reservation?.t_sale_id ?? 0;
    const factoryAddress = [
      plant_info?.address1,
      plant_info?.address2,
      plant_info?.address3,
    ].join();
    const paymentMethods = (plant_info?.affiliate_payment_methods ?? [])
      .map((item) => item.payment_method_name)
      .join('、');
    // ID情報
    const idInformation = {
      affiliateID,
      customerID,
      inspectionID,
      reservationID,
      saleID,
      stockCarID,
      unitID,
    };
    // 修理情報
    const statusInformation = {
      status: reservation?.status,
      check_in_date: reservation?.arrival_schedule_datetime,
      pick_up_date: reservation?.delivery_schedule_datetime ?? date,
      factory_name: plant_info?.plant_name,
      factory_gps_info: factoryAddress,
      factory_telephone: plant_info?.phone_no,
    };
    // 予約情報
    const reserveInformation = {
      car_info: {
        registry_no: reservation?.car_info?.registry_no ?? '',
        maker_name: reservation?.car_info?.maker_name ?? '',
        car_name: reservation?.car_info?.car_name ?? '',
        vin_number: reservation?.car_info?.vin ?? '',
      },
      category: '傷・凹み修理',
      factory_name: plant_info?.plant_name ?? '',
      check_in_date: reservation?.arrival_schedule_datetime ?? '',
      damage_part: damagePartText,
      notice_date: reservation?.damage_location_date ?? '',
      description: reservation?.damage_detail ?? '',
    };
    // 保険情報
    const insuranceInformation = insurance_info
      ? {
          company_name: insurance_info.company_name,
          certificate_number: insurance_info.policy_number,
          receipt_number: insurance_info.accident_reception_number,
          has_deductible: insurance_info.deductible_fee,
          repair_plan: insurance_info.repair_planning,
        }
      : undefined;
    // 工場情報
    const factoryInformation = {
      factory_name: plant_info?.plant_name,
      factory_address: factoryAddress,
      factory_gps_address: factoryAddress,
      factory_telephone: plant_info?.phone_no,
      opening_hours: openingTimes,
      payment_method: paymentMethods,
    };
    return {
      ids: idInformation,
      state_info: statusInformation,
      reserve_info: reserveInformation,
      insurance_info: insuranceInformation,
      factory_info: factoryInformation,
      repair_progress_info: [],
    };
  }, [
    affiliateID,
    customerID,
    inspectionID,
    reservationID,
    stockCarID,
    unitID,
    reservation,
    insurance_info,
    plant_info,
    openingTimes,
    damagePartText,
  ]);
  const initialize = useCallback(() => {
    setRepairProgressData((prev) =>
      JSON.stringify(prev) !== JSON.stringify(update) ? update : prev,
    );
  }, [update]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return { repairProgressData };
};
