import { f7 } from 'framework7-react';
import { useState } from 'react';
import { postRegisterCustomer } from '@/api/registerQRApi';
import { RegisterUserDataType } from '@/config/initialState';
import { customerTypes } from '@/consts/customer';
import { RegistCustomerParams } from '@/types/api/registerQRApi';

type Data = {
  m_customer_id: number;
  unique_code: string;
};

export const useRegisterUser = () => {
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data>();

  const registerCustomer = async (formData: RegisterUserDataType) => {
    setError(undefined);
    let response: Data | undefined = undefined;
    try {
      f7.preloader.show();
      setIsLoading(true);

      const { affiliate_id, isCorporation, step1 } = formData;

      const params: RegistCustomerParams = {
        m_affiliate_id: affiliate_id,
        customer_type: isCorporation
          ? customerTypes.CORPORATION
          : customerTypes.PERSONAL,
        first_name: step1.firstName || undefined,
        family_name: step1.familyName || undefined,
        first_name_kana: step1.firstNameKana || undefined,
        family_name_kana: step1.familyNameKana || undefined,
        birthday: step1.birthday || undefined,
        gender: Number(step1.gender) || undefined,
        address1: step1.prefectures,
        email: step1.email || undefined,
        phone_no1: step1.tel1 + '-' + step1.tel2 + '-' + step1.tel3,
        mobile_flg1: 1, // Use phone_1 so this should be default 1
        mobile_type1: Number(step1.mobile_type1) || 0,
      };

      if (step1.tel4 && step1.tel5 && step1.tel6) {
        params.phone_no2 = step1.tel4 + '-' + step1.tel5 + '-' + step1.tel6;
        params.mobile_type2 = Number(step1.mobile_type2) || 0;
      }

      if (isCorporation) {
        params.company_name_kana = step1.companyNameKana || undefined;
        params.company_name = step1.companyName || '';
        params.company_flg = 1;
      }

      const res = await postRegisterCustomer(params);

      if (res?.data?.success) {
        response = {
          m_customer_id: res.data.m_customer_id,
          unique_code: res.data.unique_code,
        };
        setData({
          ...response,
        });
      } else {
        setError(res?.data?.error?.message || '会員登録に失敗しました');
      }
    } catch (err: any) {
      setError(err?.message || '会員登録に失敗しました');
    } finally {
      setIsLoading(false);

      f7.preloader.hide();
    }
    return response;
  };
  return {
    data,
    error,
    isLoading,
    registerCustomer,
    setError,
  };
};
