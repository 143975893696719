import { ReactNode, useState } from 'react';
import {
  RepairProgressProvider,
  useRepairProgressStateContext,
} from '../context/progressDetailContext';
import {
  RepairProgressDataType,
  RepairProgressStateType,
} from '../type/bodyAndPaint';
import { ProgressPickUpConfirm } from './progressPickUpConfirm';
import { ProgressPickUpReserve } from './progressPickUpReserve';
import { ProgressProcessImageDetail } from './progressProcessImageDetail';
import { ProgressRepairImageCheck } from './progressRepairImageCheck';
import { ProgressRepairInformation } from './progressRepairInformation';

export const RepairProgressDetail = ({
  state,
  data,
}: {
  state: string;
  data: RepairProgressDataType;
}) => {
  const stateMap: Record<RepairProgressStateType, boolean> = {
    REPAIR_PROGRESS: true,
    REPAIR_IMAGE: true,
    PROGRESS_IMAGE: true,
    PICK_UP_RESERVE: true,
    RESERVE_CONFIRM: true,
  };
  const initState = (
    state in Object.keys(stateMap) ? state : 'REPAIR_PROGRESS'
  ) as RepairProgressStateType;
  const [repairProgressState] = useState<RepairProgressStateType>(initState);
  const [repairProgressData] = useState<RepairProgressDataType>(
    data ?? ({} as RepairProgressDataType),
  );
  const RepairRepairBodyAndPaintChildren = () => {
    const { state } = useRepairProgressStateContext();
    const StateMap: Record<RepairProgressStateType, ReactNode> = {
      REPAIR_PROGRESS: <ProgressRepairInformation />,
      REPAIR_IMAGE: <ProgressRepairImageCheck />,
      PROGRESS_IMAGE: <ProgressProcessImageDetail />,
      PICK_UP_RESERVE: <ProgressPickUpReserve />,
      RESERVE_CONFIRM: <ProgressPickUpConfirm />,
    };
    return <>{state && StateMap[state]}</>;
  };

  return (
    <RepairProgressProvider
      initialState={repairProgressState}
      initialData={repairProgressData}
    >
      <RepairRepairBodyAndPaintChildren />
    </RepairProgressProvider>
  );
};
