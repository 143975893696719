import { yupResolver } from '@hookform/resolvers/yup';
import { f7, useStore } from 'framework7-react';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectDropdown } from '../../CarRepair/Partials';
import { TextInput } from '../../CarWashConditioner/Inputs/TextInput';
import { DateTimeSelect } from '../Partials/DateTimeSelect';
import { PairTextInput } from '../Partials/PairTextInput';
import { PhoneInput } from '../Partials/PhoneInput';
import style from './RegisterNewMemberStep1Style.module.css';
import prefectures from './prefectures.json';
import { customerSchema } from './validationSchema';
import { Button } from '@/components/uiParts/Button';
import { RegisterUserDataType } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { genders } from '@/consts/customer';
import { affiliateId } from '@/consts/shop';
import { storeDispatch } from '@/utils/store';

type FormInput = {
  firstName: string;
  firstNameKana: string;
  familyName: string;
  familyNameKana: string;
  email: string;
  birthday: string;
  gender: number | null;
  tel1: string;
  tel2: string;
  tel3: string;
  tel4: string;
  tel5: string;
  tel6: string;
  prefectures: string;
};

export const RegisterNewMemberStep1: FC = () => {
  const methods = useForm<FormInput>({
    resolver: yupResolver(customerSchema),
    mode: 'onTouched',
  });
  const {
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = methods;
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;

  const onSubmit = (data: FormInput) => {
    storeDispatch('setRegisterUser', 'registerUser', {
      ...registerUserData,
      step1: data,
      currentStep: 2,
    });
  };

  const genderOptions = [
    { label: '男性', value: genders.MALE },
    { label: '女性', value: genders.FEMALE },
    { label: 'その他', value: genders.OTHER },
  ];

  useEffect(() => {
    if (registerUserData.step1?.familyName) {
      const {
        firstName,
        firstNameKana,
        familyName,
        familyNameKana,
        email,
        gender,
        tel1,
        tel2,
        tel3,
        tel4,
        tel5,
        tel6,
        prefectures,
      } = registerUserData.step1;
      setValue('firstName', firstName);
      setValue('firstNameKana', firstNameKana);
      setValue('familyName', familyName);
      setValue('familyNameKana', familyNameKana);
      setValue('email', email || '');
      setValue('gender', gender);
      setValue('tel1', tel1);
      setValue('tel2', tel2);
      setValue('tel3', tel3);
      setValue('tel4', tel4);
      setValue('tel5', tel5);
      setValue('tel6', tel6);
      setValue('prefectures', prefectures);
    }
  }, [registerUserData.step1, setValue]);

  return (
    <FormProvider {...methods}>
      <p className={style['content__title']}>
        お客様の基本情報を入力してください
      </p>
      <p className={style['note-step1']}>
        お客様情報をご確認の上、お電話・メールにて連絡させていただきます
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PairTextInput
          name1="familyName"
          name2="firstName"
          label="お名前"
          error={errors.firstName?.message || errors.familyName?.message}
          required
          props1={{
            placeholder: '田中',
          }}
          props2={{
            placeholder: '太郎',
          }}
        />
        <PairTextInput
          name1="familyNameKana"
          name2="firstNameKana"
          label="フリガナ"
          error={
            errors.firstNameKana?.message || errors.familyNameKana?.message
          }
          required
          props1={{
            placeholder: 'タナカ',
          }}
          props2={{
            placeholder: 'タロウ',
          }}
        />
        <DateTimeSelect
          label="生年月日"
          name="birthday"
          error={errors.birthday?.message || ''}
          required={false}
          defaultValue={registerUserData.step1.birthday || null}
        />
        <SelectDropdown
          name="gender"
          error={errors.gender?.message || ''}
          label="性別"
          options={genderOptions}
          required={false}
        />
        <PhoneInput
          type="phone_1"
          title="お電話番号"
          required
          error={
            errors.tel1?.message ||
            errors.tel2?.message ||
            errors.tel3?.message ||
            ''
          }
        />
        <PhoneInput
          type="phone_2"
          title="その他お電話番号"
          error={
            errors.tel4?.message ||
            errors.tel5?.message ||
            errors.tel6?.message ||
            ''
          }
        />
        <TextInput
          label="メールアドレス"
          name="email"
          error={errors.email?.message}
          required={false}
          placeholder="mail@example.com"
        />

        <p className={style['note']}>連絡・返信先として使用します</p>
        <SelectDropdown
          name="prefectures"
          error={''}
          label="都道府県"
          options={prefectures}
          required
        />

        <div className={style['action-btn']}>
          <Button
            animate
            round
            className={style['back-btn']}
            onClick={() =>
              f7.view.main.router.navigate(
                `${paths.registerQR}?affiliate_id=${
                  registerUserData.affiliate_id || affiliateId.DEFAULT
                }`,
                {
                  transition: 'push-reverse',
                },
              )
            }
          >
            戻る
          </Button>
          <Button
            animate
            round
            className={style['next-btn']}
            type="submit"
            disabled={!isValid}
          >
            次へ
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
