import { f7, useStore } from 'framework7-react';
import { FC, useState } from 'react';
import { WarrantyPlanRequiredPopup } from '../TroubleShooting/WarrantyPlanRequiredPopup/WarrantyPlanRequiredPopup';
import { getValidWarranties } from '../WarrantyManagement/WarrantyManagementHelper';
import { MaintenanceGroupHelpPopup } from './MaintenanceGroupHelpPopup';
import style from './MaintenanceGroupStyle.module.css';
import HelpIcon from '@/assets/images/maintenance-group/help.svg';
import { ButtonIconVertical } from '@/components/projects/ButtonIconVertical/ButtonIconVertical';
import { ReservationItem } from '@/components/projects/ReservationItem/ReservationItem';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { carWashServiceFlag } from '@/consts/carWash';
import { warrantyType } from '@/consts/warranty';
import { useFetchReservationHistories } from '@/hooks/api/reservation/useFetchReservationHistories';
import { Car } from '@/types/api/customerApi';
import { isDelivered } from '@/utils/car';
import { openAlertWarrantyExpried } from '@/utils/car';

export const MaintenanceGroup: FC = () => {
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const [isOpenWarrantyRequired, setIsOpenWarrantyRequired] = useState(false);
  const [isShowHelpPopup, setIsShowHelpPopup] = useState(false);
  const { m_customer_id } = store.state.authInfo;
  const { bp_flg } = store.state.customerInfo;
  const { newestReservation } = useFetchReservationHistories({
    m_customer_id,
    t_stock_car_id: selectedCar.t_stock_car_id,
  });
  const validWarranties = getValidWarranties(store.state.warranties);

  const isExistDefectPlan = validWarranties.some((warranty) =>
    warranty.append_warranties?.some(
      (appendWarranty) => appendWarranty.warranty_type === warrantyType.DEFECT,
    ),
  );

  const { carItemInfo } = store.state;

  const isShowCarWashService =
    store.state.customerInfo.carwash_service_flg ===
    carWashServiceFlag.INSTALLED;

  const handleClickProblem = () => {
    if (isDelivered(carItemInfo.delivery_complete_flg)) {
      if (!carItemInfo?.warranties?.[0]?.warranty_no || !isExistDefectPlan) {
        return setIsOpenWarrantyRequired(true);
      }
      if (!validWarranties?.length) {
        return openAlertWarrantyExpried();
      }
      return f7.view.main.router.navigate(paths.problem);
    }
    if (carItemInfo?.warranties?.[0]?.warranty_no) {
      return openAlertWarrantyExpried();
    }
    setIsOpenWarrantyRequired(true);
  };

  const handleClickBPService = () => {
    f7.views.main.router.navigate(paths.bpsRepair);
  };

  return (
    <PageTemplateV2
      pageName="MaintenanceGroup"
      title="メンテナンス"
      showNavBack={true}
      handleClickBack={() =>
        f7.view.main.router.navigate(paths.home, { transition: 'push-reverse' })
      }
    >
      <MaintenanceGroupHelpPopup
        opened={isShowHelpPopup}
        setOpened={setIsShowHelpPopup}
      />
      <WarrantyPlanRequiredPopup
        isOpen={isOpenWarrantyRequired}
        setIsOpenWarrantyRequired={setIsOpenWarrantyRequired}
      />
      <div className={style['content']}>
        <div className={style['maintenance-wrapper']}>
          <div className={style['maintenance-title']}>
            <div className={style['maintenance-title-badge']}>
              <span>予約一覧</span>
            </div>
            {newestReservation && (
              <div className={style['view-all']}>
                <span
                  onClick={() =>
                    f7.view.main.router.navigate(paths.reservationHistory)
                  }
                >
                  過去の予約一覧
                </span>
                <i className="icon-right" />
              </div>
            )}
          </div>
          {newestReservation && !!bp_flg ? (
            <div className={style['newest-item']}>
              <ReservationItem
                reservation={newestReservation}
                fromPage={paths.maintenance}
              />
            </div>
          ) : (
            <div className={style['maintenance-box']}>
              予約中のメンテナンスはありません。
            </div>
          )}
        </div>
        <div className={style['menu-wrapper']}>
          <div className={style['maintenance']}>
            <p className={style['maintenance-title']}>
              新しくメンテナンスを依頼する
            </p>
            <p className={style['maintenance-help']}>
              <img src={HelpIcon} onClick={() => setIsShowHelpPopup(true)} />
            </p>
          </div>
          <div className={style['maintenance__item']}>
            {isShowCarWashService && (
              <div
                className={`${style['maintenance-box']} ${style['maintenance__item--detail']}`}
                onClick={() => f7.view.main.router.navigate(paths.carWash)}
              >
                <div className={style['button-wrapper']}>
                  <ButtonIconVertical
                    label={'洗車/エアコン\nクリーニング'}
                    iconType="carwash-maintenance"
                    path={paths.carWash}
                    width={48}
                    height={48}
                  />
                </div>
              </div>
            )}
            {!!bp_flg && (
              <div
                className={`${style['maintenance-box']} ${style['maintenance__item--detail']}`}
                onClick={() => handleClickProblem()}
              >
                <div className={style['button-wrapper']}>
                  <ButtonIconVertical
                    label="保証修理"
                    iconType="guarantee-maintenance"
                    path={''}
                    width={48}
                    height={48}
                  />
                </div>
              </div>
            )}
            <div
              className={`${style['maintenance-box']} ${style['maintenance__item--detail']}`}
              onClick={handleClickBPService}
            >
              <div className={style['button-wrapper']}>
                <ButtonIconVertical
                  label="傷・凹み修理"
                  iconType="body-and-paint-maintenance"
                  path={''}
                  width={48}
                  height={48}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplateV2>
  );
};
