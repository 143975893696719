import style from './repairCheckInStyle.module.css';

// 入庫予約 入力案内カード
export const CheckInAnnounceCard = () => {
  const noteLabel = 'ご予約後の流れにつきまして';
  const noteText = [
    'ご入力内容の確認のために、サポートセンター担当者よりお電話にてご連絡をさせていただく場合がございます。',
    '保険のご利用をお考えの場合は、お手元に保険証書をご用意ください。',
  ];

  return (
    <div className={style.checkInReserveNoteCard}>
      <div className={style.noteHeader}>
        <div className={style.headerIcon}>
          <i className={'icon-image'} />
        </div>
        <div className={style.headerLabel}>
          <span>{noteLabel}</span>
        </div>
      </div>
      <div className={style.noteBody}>
        <ul>
          {noteText.map((text, idx) => (
            <li key={idx}>
              <span>{text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
