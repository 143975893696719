import { useMemo } from 'react';

export const useReserveDateText = ({ dateTime }: { dateTime?: Date }) => {
  const formatString = useMemo(() => {
    const offset = 1;
    const length = 2;
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    const year = dateTime && dateTime.getFullYear();
    const month =
      dateTime &&
      (dateTime.getMonth() + offset).toString().padStart(length, '0');
    const date =
      dateTime && dateTime.getDate().toString().padStart(length, '0');
    const weekDay = dateTime && days[dateTime.getDay()];
    const hourTime = dateTime && dateTime.getHours();
    const twelve = 12;
    const period = hourTime && hourTime < twelve ? '午前' : '午後';
    const hour12 =
      dateTime &&
      (dateTime.getHours() % twelve || twelve).toString().padStart(length, '0');
    const hours = `${period}${hour12}`;
    const minutes =
      dateTime && dateTime.getMinutes().toString().padStart(length, '0');
    return `${year}年${month}月${date}日（${weekDay}） ${hours}：${minutes}`;
  }, [dateTime]);
  return formatString;
};

export const useDateText = ({
  dateTime,
  splitter,
}: {
  dateTime?: Date;
  splitter?: string;
}) => {
  const formatString = useMemo(() => {
    const offset = 1;
    const length = 2;
    const year = dateTime && dateTime.getFullYear();
    const month =
      dateTime &&
      (dateTime.getMonth() + offset).toString().padStart(length, '0');
    const date =
      dateTime && dateTime.getDate().toString().padStart(length, '0');
    return splitter
      ? `${year}${splitter}${month}${splitter}${date}`
      : `${year}年${month}月${date}日`;
  }, [dateTime, splitter]);
  return formatString;
};

export const useDateTimeText = ({ dateTime }: { dateTime?: Date }) => {
  const formatString = useMemo(() => {
    const offset = 1;
    const length = 2;
    const year = dateTime && dateTime.getFullYear();
    const month =
      dateTime &&
      (dateTime.getMonth() + offset).toString().padStart(length, '0');
    const date =
      dateTime && dateTime.getDate().toString().padStart(length, '0');
    const hours =
      dateTime && dateTime.getHours().toString().padStart(length, '0');
    const minutes =
      dateTime && dateTime.getMinutes().toString().padStart(length, '0');
    return `${year}/${month}/${date} ${hours}:${minutes}`;
  }, [dateTime]);
  return formatString;
};
