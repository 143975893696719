import { FC } from 'react';
import style from './prepaidMenuCardStyle.module.css';
import { PrepaidButton } from '@/components/projects/PrepaidButton/PrepaidButton';
import { paths } from '@/config/paths';
import { Category } from '@/types/api/serviceApi';

interface PrepaidMenuCardProps {
  categories?: Category[];
}
export const PrepaidMenuCard: FC<PrepaidMenuCardProps> = ({ categories }) => {
  const iconTypes: { [key: number]: string } = {
    1: 'car-wash',
    2: 'car-share',
    3: 'gas-station',
    4: 'vehicle-inspection',
    5: 'parking',
    6: 'shop',
    7: 'rent-car',
  };

  const carWashId = 1;

  const carWashIndex =
    categories?.findIndex((category) => category.id === carWashId) ?? -1;
  categories &&
    carWashIndex >= 0 &&
    (categories[carWashIndex].disable_flg = false);

  return (
    <>
      <div className={style['prepaid-menu-card-wrapper']}>
        <div className={style['prepaid-menu-buttons-wrapper']}>
          {categories?.map(({ name, id, disable_flg }, index) => (
            <div className={style['prepaid-button-wrapper']} key={index}>
              <PrepaidButton
                label={name}
                iconType={iconTypes[id]}
                path={
                  id !== carWashId
                    ? paths.serviceCategory(id.toString())
                    : paths.carWash
                }
                isDisabled={disable_flg}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
