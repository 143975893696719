import { useCallback, useEffect, useState } from 'react';
import { getBodyAndPaintDetailApi } from '@/api/bodyAndPaint';
import { BodyAndPaintDetailResponse } from '@/types/api/bodyAndPaint';

// API Hooks
interface GetBodyAndPaintDataHookProps {
  affiliateID: number;
  reservationID: number;
}
export const useBodyAndPaintData = ({
  affiliateID,
  reservationID,
}: GetBodyAndPaintDataHookProps) => {
  const [bpDetailData, setBpDetailData] = useState<BodyAndPaintDetailResponse>(
    {} as BodyAndPaintDetailResponse,
  );
  const fetchDate = useCallback(async () => {
    try {
      if (reservationID > 0 && affiliateID > 0) {
        const response = await getBodyAndPaintDetailApi({
          reservation_id: reservationID,
          m_affiliate_id: affiliateID,
        });
        const { data: [data] = [] } = response.data || {};
        setBpDetailData(data as BodyAndPaintDetailResponse);
      } else {
        setBpDetailData({} as BodyAndPaintDetailResponse);
        throw 'invalid parameter';
      }
    } catch (error) {
      console.error(error);
    }
  }, [affiliateID, reservationID]);
  useEffect(() => {
    fetchDate();
  }, [fetchDate]);
  return { bpDetailData } as const;
};
