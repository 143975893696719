import { f7, useStore } from 'framework7-react';
import { FC, useState } from 'react';
import { Dialog } from './Service/Dialog';
import { Service } from './Service/Service';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useFetchBanners } from '@/hooks/api/banner/useFetchBanners';
import { useFetchServices } from '@/hooks/api/service/useFetchServices';
import { Prepaid } from '@/types/api/customerApi';
import { generatePrepaidName } from '@/utils/shop';

type ServicePageProps = {
  id: number;
};

export const ServicePage: FC<ServicePageProps> = ({ id }) => {
  const [showDialog, setShowDialog] = useState(false);
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;

  const handleClickItem = (path_type: number, path: string) => {
    if (path_type === 1) {
      f7.views.main.router.navigate(path);
    } else if (path_type === 2) {
      window.open(path, '_blank');
    }
  };
  const affiliate_id = store.state.customerInfo.m_affiliate_id;
  // const affiliate_id = 331;

  const { services } = useFetchServices({
    m_mt_service_category_id: id,
    m_affiliate_id: affiliate_id,
  });

  const { banners } = useFetchBanners({
    m_mt_service_category_id: id,
    m_affiliate_id: affiliate_id,
  });

  const handleNavigatePrepaid = () => {
    setShowDialog(false);
    localStorage.setItem('paymentRedirectPage', 'service');
    f7.view.main.router.navigate(paths.prepaidSummary);
  };

  const closeDialog = () => {
    setShowDialog(false);
    localStorage.removeItem('redirectServiceAfterPayment');
  };

  console.log('来てます');
  return (
    <>
      <Service
        id={id}
        services={services}
        banners={banners}
        handleClickItem={handleClickItem}
      />
      {showDialog && (
        <Dialog
          prepaidName={generatePrepaidName(prepaid?.name)}
          handleCancel={() => closeDialog()}
          handleOk={handleNavigatePrepaid}
        />
      )}
    </>
  );
};
