import { useMemo } from 'react';
import { ITEM_REPAIR } from '../../const/bodyAndPaint';
import { RepairItemType } from '../../type/bodyAndPaint';
import { RadioGroup } from '../radioGroup';

interface RepairItemSelectCardProps {
  info: RepairItemType;
  onChange: (newSelect: number) => void;
}
export const RepairItemSelectCard = ({
  info,
  onChange,
}: RepairItemSelectCardProps) => {
  const { repair_item_name, repair_select, repair_amount, applied_amount } =
    info;
  const isInsurance = applied_amount !== undefined;
  const isInsuranceStatus = isInsurance ? '保険適用可' : '保険適用不可';
  const isInsuranceStyle = isInsurance ? 'applied' : 'no-apply';
  const selectKeyList = useMemo(
    () =>
      isInsurance
        ? (['USE_INSURANCE', 'NO_INSURANCE', 'NO_REPAIR'] as const)
        : (['NO_INSURANCE', 'NO_REPAIR'] as const),
    [isInsurance],
  );
  const selectList = useMemo(
    () => selectKeyList.map((key) => ITEM_REPAIR[key].label),
    [selectKeyList],
  );
  const amountList = useMemo(
    () =>
      selectKeyList.map((key) => {
        const AmountMap: Record<keyof typeof ITEM_REPAIR, string> = {
          USE_INSURANCE: applied_amount
            ? `${applied_amount.toLocaleString('ja-JP')}円`
            : '-円',
          NO_INSURANCE: `${repair_amount.toLocaleString('ja-JP')}円`,
          NO_REPAIR: '----',
        };
        return AmountMap[key];
      }),
    [applied_amount, repair_amount, selectKeyList],
  );
  const handleChangeSelect = (newSelect: number) => onChange(newSelect);
  return (
    <div className={'repair-item-select-card'}>
      <div className={'repair-item-select-header'}>
        <div className={'item-label'}>
          <span>{repair_item_name}</span>
        </div>
        <div className={`insurance-status ${isInsuranceStyle}`}>
          <span>{isInsuranceStatus}</span>
        </div>
      </div>
      <div className={'repair-item-select-body'}>
        <div className={'select-group'}>
          <RadioGroup
            list={selectList}
            groupName={repair_item_name}
            value={selectList[repair_select ?? -1]}
            onChange={handleChangeSelect}
          />
        </div>
        <div className={'amount-group'}>
          {amountList.map((amount, idx) => (
            <div className={'amount-label'} key={idx}>
              <span>{amount}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
