import { useCallback, useMemo } from 'react';
import {
  useCheckInReserveDataContext,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import { useInsuranceInfo } from '../hook/util/repairCheckIn/useInsuranceInfo';
import { CheckInReserveDataType } from '../type/bodyAndPaint';
import { EntryUseInsuranceField } from '../util/addInsurance/entryUseInsurance';
import { NextButtonField } from '../util/repairCheckIn/nextButtonField';
import style from './repairCheckInReserveStyle.module.css';

export const CheckInReserveUseInsurance = () => {
  const { setState } = useCheckInReserveStateContext();
  const { data } = useCheckInReserveDataContext();
  const { insurance_info } = data ?? ({} as CheckInReserveDataType);
  const insuranceInfo = useMemo(() => insurance_info, [insurance_info]);

  const [stateInsurance, setInsurance, disable] = useInsuranceInfo({
    info: insuranceInfo,
  });

  const handleClickBack = useCallback(() => {
    setState('CAR_CONDITION');
  }, [setState]);
  const handleClickNext = useCallback(() => {
    setState('CHECK_IN_DATE');
  }, [setState]);

  return (
    <>
      <div className={style.checkInReserveEntryField}>
        <EntryUseInsuranceField info={stateInsurance} setInfo={setInsurance} />
      </div>
      <NextButtonField disable={disable} onClick={handleClickNext} />
    </>
  );
};
