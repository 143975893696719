import { useStore } from 'framework7-react';
import { FC, useEffect, useMemo } from 'react';
import styles from './RegisterNewMemberStep2Style.module.css';
import { Button } from '@/components/uiParts/Button';
import { RegisterUserDataType } from '@/config/initialState';
import { store } from '@/config/store';
import { genders } from '@/consts/customer';
import { useRegisterUser } from '@/hooks/api/registerQR/useRegisterUser';
import { useToast } from '@/hooks/useToast';
import { hyphenDate } from '@/utils/date';
import { storeDispatch } from '@/utils/store';

const genderLabels = {
  [genders.MALE]: '男性',
  [genders.FEMALE]: '女性',
  [genders.OTHER]: 'その他',
};

export const RegisterNewMemberStep2: FC = () => {
  const { openToast } = useToast();
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;

  const { data, error, registerCustomer, setError } = useRegisterUser();

  const { step1 } = registerUserData;

  const displayData = useMemo(() => {
    const data: {
      label: string;
      value: string;
    }[] = [];
    if (step1.companyName) {
      data.push({
        label: '法人名',
        value: step1.companyName,
      });
    }
    if (step1.companyNameKana) {
      data.push({
        label: '法人名フリガナ',
        value: step1.companyNameKana,
      });
    }
    if (step1.firstName && step1.familyName) {
      data.push({
        label: '名前',
        value: `${step1.familyName} ${step1.firstName}`,
      });
    }
    if (step1.firstNameKana && step1.familyNameKana) {
      data.push({
        label: 'フリガナ',
        value: `${step1.familyNameKana} ${step1.firstNameKana}`,
      });
    }
    if (step1.birthday && hyphenDate(step1.birthday)) {
      data.push({
        label: '生年月日',
        value: hyphenDate(step1.birthday) || '',
      });
    }
    if (step1.gender) {
      data.push({
        label: '性別',
        value: genderLabels[step1.gender],
      });
    }
    if (step1.tel1 && step1.tel2 && step1.tel3) {
      data.push({
        label: 'お電話番号',
        value: `${step1.tel1}-${step1.tel2}-${step1.tel3}`,
      });
    }
    if (step1.tel4 && step1.tel5 && step1.tel6) {
      data.push({
        label: 'その他お電話番号',
        value: `${step1.tel4}-${step1.tel5}-${step1.tel6}`,
      });
    }
    if (step1.email) {
      data.push({
        label: 'メールアドレス',
        value: step1.email,
      });
    }
    if (step1.prefectures) {
      data.push({
        label: '都道府県',
        value: step1.prefectures,
      });
    }
    return data;
  }, [step1]);

  const handleClickEdit = () => {
    storeDispatch('setRegisterUser', 'registerUser', {
      ...registerUserData,
      currentStep: 1,
    });
  };

  const handleClickNext = () => {
    registerCustomer(registerUserData);
  };

  useEffect(() => {
    if (data?.m_customer_id) {
      openToast('会員登録が完了しました', 'toast-success');
      storeDispatch('setRegisterUser', 'registerUser', {
        ...registerUserData,
        m_customer_id: data.m_customer_id,
        currentStep: 3,
      });
    }
  }, [openToast, data, registerUserData]);

  useEffect(() => {
    if (error) {
      setError(undefined);
      openToast('会員登録に失敗しました', 'toast-failed');
    }
  }, [error, openToast, setError]);

  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>
        <span className={styles['title']}>基本情報</span>
        <span className={styles['edit-btn']} onClick={handleClickEdit}>
          編集
        </span>
      </div>
      <div className={styles['list']}>
        {displayData.map((item, index) => {
          return (
            <div className={styles['list-item']} key={index}>
              <div className={styles['label']}>{item.label}</div>
              <div className={styles['value']}>{item.value}</div>
            </div>
          );
        })}
      </div>
      <div className={styles['bottom-btn']}>
        <Button className={styles['next-btn']} onClick={handleClickNext}>
          会員登録を完了
        </Button>
      </div>
    </div>
  );
};
