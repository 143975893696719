import { DamageMapItemType } from '../type/bodyAndPaint';
export const BODY_PAINT_STATE = {
  ACCIDENT_ACCEPT: 0,
  CHECK_IN_ACCEPT: 1,
  ESTIMATE_APPLY: 2,
  INSURANCE_APPLY: 3,
  DETERMINED_APPLIES: 4,
  APPLIES_RESULT: 5,
  REPAIR_CLAIM: 6,
  NO_REPAIR: 7,
  DISASSEMBLY: 8,
  REPAIR_HAS_DONE: 9,
  INSPECTION_HAS_DONE: 10,
  DELIVERED_HAS_DONE: 11,
  REPAIR_CANCELLED: 12,
} as const;

/* 入庫予約 選択リスト*/
export const CAR_DRIVEN = {
  DRIVEN: { value: 0, label: 'できる' },
  CAN_NOT: { value: 1, label: 'できない' },
  NOT_SURE: { value: 2, label: '分からない' },
} as const;
export const DAMAGE_DEGREE = {
  INCIDENT: { value: 0, label: '大（事故など）' },
  CONTACT: { value: 1, label: '中（接触など）' },
  SCRATCH: { value: 2, label: '小（擦りなど）' },
} as const;
export const OTHER_PARTY = {
  NO_EXIST: { value: 0, label: 'いない' },
  EXIST: { value: 1, label: 'いる' },
} as const;
export const INSURANCE_USE = {
  YES_USE: { value: 1, label: '利用する' },
  NOT_USE: { value: 0, label: '利用しない' },
  NOT_SURE: { value: 2, label: '利用するか検討中' },
} as const;
export const HAS_DEDUCTIBLE = {
  YES: { value: 0, label: 'あり' },
  NO: { value: 1, label: 'なし' },
} as const;
export const REPAIR_PLAN = {
  YES: { value: 0, label: '修理予定' },
  NO: { value: 1, label: '見積もりのみ希望で修理をしない' },
} as const;
export const LOANER_CAR_USE = {
  YES_USE: { value: 0, label: '利用する' },
  NOT_USE: { value: 1, label: '利用しない' },
} as const;
/* 修理依頼 選択リスト*/
export const ITEM_REPAIR = {
  USE_INSURANCE: { value: 2, label: '保険を使って修理する' },
  NO_INSURANCE: { value: 1, label: '保険を使わず、修理する' },
  NO_REPAIR: { value: 0, label: '修理しない' },
} as const;

export const DAMAGE_PART: Record<keyof DamageMapItemType, string> = {
  front_side_left: '前方部分 左側の破損',
  front_side_center: '前方部分 中央の破損',
  front_side_right: '前方部分 右側の破損',
  front_door_left: '前ドア部分 左ドアの破損',
  front_door_right: '前ドア部分 右ドアの破損',
  rear_door_left: '後ドア部分 左ドアの破損',
  rear_door_right: '後ドア部分 右ドアの破損',
  rear_side_left: '後方部分 左側の破損',
  rear_side_center: '後方部分 中央の破損',
  rear_side_right: '後方部分 右側の破損',
};
export const CONFIRM_CHECK_IN_RESERVE = {
  car_name: '対象車両',
  category: '入庫区分',
  factory: '入庫工場',
};
export const CONFIRM_CAR_CONDITION = {
  car_drive: '自走できるか',
  damage_part: '損傷箇所',
  notice_date: 'いつから',
  damage_degree: '損傷の程度',
  has_other_party: '相手がいる事故か',
  description: '詳細',
  use_insurance: '保険の利用',
  company_name: '保険会社',
  certificate_number: '証券番号',
  receipt_number: '事故受付番号',
  deductible: '免責金',
  repair_plan: '見積もり後の修理予定',
};
export const CONFIRM_LOANER_CAR = {
  use_loaner_car: '代車',
};
export const CONFIRM_CHECK_IN_DATE = {
  check_in_date: '入庫日',
};
