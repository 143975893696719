import { useCallback, useEffect, useState } from 'react';
import { ITEM_REPAIR } from '../../../const/bodyAndPaint';
import { RepairItemSelectInfoType } from '../../../type/bodyAndPaint';

interface RepairItemSelectHookProps {
  initialData: RepairItemSelectInfoType;
}
export const useRepairItemSelect = ({
  initialData,
}: RepairItemSelectHookProps) => {
  const { repair_item_info_list, repair_item_total_amount } = initialData;
  const [totalAmount, setTotalAmount] = useState(repair_item_total_amount);
  const [repairItemInfoList, setRepairItemInfoList] = useState(
    repair_item_info_list,
  );
  type ListUpdateType = { index: number; newSelect: number };
  const changeRepairItemSelect = useCallback(
    ({ index, newSelect }: ListUpdateType) => {
      setRepairItemInfoList((prevList) =>
        prevList.map((prev, idx) =>
          index === idx ? { ...prev, repair_select: newSelect } : prev,
        ),
      );
    },
    [setRepairItemInfoList],
  );
  useEffect(() => {
    const newAmount = repairItemInfoList.reduce((calc, repairItem) => {
      type KeyType = keyof typeof ITEM_REPAIR;
      const isInsurance = repairItem.applied_amount !== undefined;
      const selectKeyList = isInsurance
        ? (['USE_INSURANCE', 'NO_INSURANCE', 'NO_REPAIR'] as const)
        : (['NO_INSURANCE', 'NO_REPAIR'] as const);

      const select = repairItem.repair_select;
      const data = select !== undefined ? selectKeyList.at(select) : undefined;
      const AmountMap: Record<KeyType, number> = {
        USE_INSURANCE: repairItem.applied_amount ?? 0,
        NO_INSURANCE: repairItem.repair_amount,
        NO_REPAIR: 0,
      };
      const newValue = calc + (data ? AmountMap[data] : 0);
      return newValue;
    }, 0);
    ITEM_REPAIR;
    setTotalAmount(newAmount);
  }, [repairItemInfoList]);
  return [repairItemInfoList, totalAmount, changeRepairItemSelect] as const;
};
