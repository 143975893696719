import { ReactNode } from 'react';
import style from '../bpUtilityStyle.module.css';

export const EntryField = ({
  children,
  label,
  required = undefined,
}: {
  children: ReactNode;
  label: string;
  required?: boolean;
}) => {
  const EntryStatusBatch = ({ required = false }: { required: boolean }) => {
    const label = required ? '必須' : '任意';
    return (
      <div
        className={`${style.entryStatus} ${
          required ? style.required : style.optional
        }`}
      >
        <span>{label}</span>
      </div>
    );
  };

  return (
    <div className={style.entryField}>
      <div className={style.entryFieldHeader}>
        <div className={style.entryLabel}>
          <span>{label}</span>
        </div>
        {required !== undefined && <EntryStatusBatch required={required} />}
      </div>
      <div className={style.entryFieldBody}>{children}</div>
    </div>
  );
};
