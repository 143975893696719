import * as yup from 'yup';

// Ref: https://git.i3design.com/tokyo-nice-system/maikuru/replace-project/frontend/-/merge_requests/1603/diffs
const fixedRegex = /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/;
const mobileRegex = /^(050|070|080|090)-\d{4}-\d{4}$/;

export const customerSchema = yup.object().shape({
  companyName: yup.string().required('法人名は必須です'),
  companyNameKana: yup
    .string()
    .required('法人名フリガナは必須です')
    .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  // firstName: yup.string().required('ご担当者様のお名前（名）は必須です'),
  // familyName: yup.string().required('ご担当者様のお名前（姓）は必須です'),
  // // gender: yup.number().typeError('性別は必須です').required('性別は必須です'),
  // firstNameKana: yup
  //   .string()
  //   .required('フリガナ（名）は必須です')
  //   .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  // familyNameKana: yup
  //   .string()
  //   .required('フリガナ（姓）は必須です')
  //   .matches(/^([ァ-ン]|ー)+$/, '全角カタカナで入力してください'),
  // birthday: yup.string().required('生年月日は必須です'),
  tel1: yup
    .string()
    .required('有効な電話番号が指定されていません')
    .test(function (value) {
      const { tel2, tel3 } = this.parent;

      if (fixedRegex.test(`${value}-${tel2}-${tel3}`)) return true;
      if (mobileRegex.test(`${value}-${tel2}-${tel3}`)) return true;
      return false;
    }),
  tel2: yup
    .string()
    .required('有効な電話番号が指定されていません')
    .test(function (value) {
      const { tel1, tel3 } = this.parent;

      if (fixedRegex.test(`${tel1}-${value}-${tel3}`)) return true;
      if (mobileRegex.test(`${tel1}-${value}-${tel3}`)) return true;
      return false;
    }),
  tel3: yup
    .string()
    .required('有効な電話番号が指定されていません')
    .test(function (value) {
      const { tel2, tel1 } = this.parent;

      if (fixedRegex.test(`${tel1}-${tel2}-${value}`)) return true;
      if (mobileRegex.test(`${tel1}-${tel2}-${value}`)) return true;
      return false;
    }),
  tel4: yup
    .string()
    .test(function (value) {
      const { tel5, tel6 } = this.parent;

      if (!tel6 && !tel5 && !value) return true;
      if (fixedRegex.test(`${value}-${tel5}-${tel6}`)) return true;
      if (mobileRegex.test(`${value}-${tel5}-${tel6}`)) return true;
      return false;
    })
    .nullable(true)
    .transform((_, val) => (val.trim() !== '' ? val : null)),
  tel5: yup
    .string()
    .test(function (value) {
      const { tel4, tel6 } = this.parent;

      if (!tel4 && !tel6 && !value) return true;
      if (fixedRegex.test(`${tel4}-${value}-${tel6}`)) return true;
      if (mobileRegex.test(`${tel4}-${value}-${tel6}`)) return true;
      return false;
    })
    .nullable(true)
    .transform((_, val) => (val.trim() !== '' ? val : null)),
  tel6: yup
    .string()
    .test(function (value) {
      const { tel5, tel4 } = this.parent;

      if (!tel4 && !tel5 && !value) return true;
      if (fixedRegex.test(`${tel4}-${tel5}-${value}`)) return true;
      if (mobileRegex.test(`${tel4}-${tel5}-${value}`)) return true;
      return false;
    })
    .nullable(true)
    .transform((_, val) => (val.trim() !== '' ? val : null)),
  email: yup
    .string()
    .email('正しいメールアドレスを入力してください')
    .nullable(true)
    .transform((_, val) => (val.trim() !== '' ? val : null)),
  prefectures: yup.string().required('都道府県は必須です'),
});
