import { DayOfWeekItemInfo } from '@/types/bodyAndPaint';

interface OpeningHoursInfoHookProps {
  opening: string;
  closing: string;
  closed: DayOfWeekItemInfo;
}
export const useOpeningHoursInfo = ({
  opening,
  closing,
  closed,
}: OpeningHoursInfoHookProps) => {
  const zero = 0;
  const slice = 2;
  const openingTime = `${opening.slice(zero, slice)}:${opening.slice(slice)}`;
  const closingTime = `${closing.slice(zero, slice)}:${closing.slice(slice)}`;
  // TODO: Move to Const
  const CLOSE_DATE: Record<keyof DayOfWeekItemInfo, string> = {
    sun: '日曜日',
    mon: '月曜日',
    tue: '火曜日',
    wed: '水曜日',
    thu: '木曜日',
    fri: '金曜日',
    sat: '土曜日',
  };
  const openTime = `${openingTime} - ${closingTime}`;
  const closeDate = `（定休日：${Object.entries(closed)
    .map(([key, value]) => {
      const keyName = key as keyof DayOfWeekItemInfo;
      if (value) {
        return CLOSE_DATE[keyName];
      }
    })
    .join('、')}）`;
  return [openTime, closeDate] as const;
};
