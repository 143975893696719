import { f7 } from 'framework7-react';
import perse from 'html-react-parser';
import { FC } from 'react';
import { getWarrantiesMissDocument } from '../../WarrantyManagement/WarrantyManagementHelper';
import { BannerList } from '../BannerList/BannerList';
import { CarInfoCard } from '../CarInfoCard/CarInfoCard';
import { CardRecommend } from '../CardRecommend/CardRecommend';
import { PrepaidMenuCard } from '../PrepaidMenuCard/PrepaidMenuCard';
import { RequestMaintenanceButton } from '../RequestMaintenanceButton/RequestMaintenanceButton';
import style from './homeStyle.module.css';
import { AdTelemileage } from '@/components/projects/AdTelemileage/AdTelemileage';
import { BrainTraining } from '@/components/projects/BrainTraining/BrainTraining';
import { ButtonIconVertical } from '@/components/projects/ButtonIconVertical/ButtonIconVertical';
import { RankBanner } from '@/components/projects/RankBanner/RankBanner';
import { IconCard } from '@/components/uiParts/Card/IconCard/IconCard';
import { PageTemplateV2 } from '@/components/uiParts/Template/PageTemplate/PageTemplateV2';
import { isPc } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';
import { Affiliate } from '@/types/api/authApi';
import { Banner } from '@/types/api/bannerApi';
import { Car, Notification } from '@/types/api/customerApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { Category } from '@/types/api/serviceApi';
import { isDelivered } from '@/utils/car';
import {
  isAdContractorData,
  isLinkDriveOwnerData,
  isShowDriveRecordButton,
} from '@/utils/home';
import { storeDispatch } from '@/utils/store';

interface HomeProps {
  isBasicInfoLoaded: boolean;
  isBrainTrainingDisplay: boolean;
  isMileageDisplay: boolean;
  selectedCar?: Car;
  isOpenCarList: boolean;
  linkDriveOwner: LinkDriveOwner | undefined;
  news: Notification | undefined;
  categories: Category[] | undefined;
  banners: Banner[] | undefined;
  affiliatesInfo: Affiliate[];
  isShowCarWashService: boolean;
  isShowSwitchCar: boolean;
  isShowPrepaidInfo: boolean;
  isRegisterPaymentWarranty: boolean;
  isWarrantyExpired: boolean;
  isWarrantiesMissDocument: boolean;
  handleClickRegistCar: () => void;
  changeIsOpenCarList: (bool: boolean) => void;
  handleClickListImage: (
    car: Affiliate['cars'][0] | null,
    affiliateID: number,
  ) => Promise<void>;
  handleClickMaintenanceButton: () => void;
  handleClickNavigationNews: () => void;
  handleClickNews: (id: string) => void;
  handlePaymentRegistAlert: () => void;
  handlePaymentRegistAlertPrepaid: () => void;
  onRefetch?: (cb: () => void) => void;
}
export const Home: FC<HomeProps> = ({
  isBasicInfoLoaded,
  isBrainTrainingDisplay,
  isMileageDisplay,
  selectedCar,
  isOpenCarList,
  linkDriveOwner,
  news,
  categories,
  banners,
  affiliatesInfo,
  isShowCarWashService,
  isShowSwitchCar,
  isShowPrepaidInfo,
  isRegisterPaymentWarranty,
  isWarrantyExpired,
  isWarrantiesMissDocument,
  handleClickRegistCar,
  changeIsOpenCarList,
  handleClickListImage,
  handleClickMaintenanceButton,
  handleClickNavigationNews,
  handleClickNews,
  handlePaymentRegistAlert,
  handlePaymentRegistAlertPrepaid,
  onRefetch,
}) => {
  const isrRoyalAutoId =
    store.state.customerInfo.m_affiliate_id === affiliateId.ROYAL;
  const isShowSelfScan =
    !!linkDriveOwner && !!linkDriveOwner?.id && !!linkDriveOwner.owner_id;
  const isShowDriveRecord = isShowDriveRecordButton({
    deviceDelDateStr: linkDriveOwner?.device_del_date,
    serviceEndDateStr: linkDriveOwner?.service_end_date,
    delFlg: linkDriveOwner?.del_flg,
    driveLogSendFlg: linkDriveOwner?.drivelog_send_flg,
  });
  const isAdContractor = isAdContractorData({
    linkDriveStatus: selectedCar?.linkdrive_status,
  });

  const isLinkDriveOwner = isLinkDriveOwnerData({
    id: linkDriveOwner?.id,
    delFlg: linkDriveOwner?.del_flg,
  });

  const listExternalServiceURL = [
    {
      text: 'お車購入\nご相談',
      href: 'https://carstation.royal-auto.jp/',
      icon: 'search-black',
    },
    {
      text: '保険ご相談',
      href: 'https://royal-auto-service2.localinfo.jp/',
      icon: 'guarantee-insurance',
    },
    {
      text: 'アクセス',
      href: 'https://www.royal-auto.jp/shop',
      icon: 'home-map',
    },
  ];
  const handleRedirect = (href: string) => {
    window.open(href, '_blank');
  };
  const handleRedirectToMaintenanceReservation = () => {
    window.open(`https://onelink.to/n6x8cn`, '_blank');
  };

  return (
    <PageTemplateV2
      pageName="Home"
      title="ホーム"
      onRefresh={(done) => {
        if (onRefetch) {
          onRefetch(() => done());
        }
      }}
    >
      <div className={style['content']}>
        <CarInfoCard
          changeIsOpenCarList={changeIsOpenCarList}
          handleClickListImage={handleClickListImage}
          handleClickRegistCar={handleClickRegistCar}
          affiliatesInfo={affiliatesInfo}
          selectedCar={selectedCar}
          isOpenCarList={isOpenCarList}
          isBasicInfoLoaded={isBasicInfoLoaded}
          isShowSwitchCar={isShowSwitchCar}
        />
        <div className={style['notification-wrapper']}>
          {isWarrantiesMissDocument && (
            <div className="u-margin-bottom-xl">
              <IconCard
                isTransition
                iconType="confirm-notification"
                headingFontSize="14px"
                iconFontSize="32px"
                color="warning"
                heading="取引保証プラン有効化に必要な情報があります"
                onClick={() => {
                  storeDispatch(
                    'setCurrentWarranty',
                    'currentWarranty',
                    getWarrantiesMissDocument(store.state.warranties)[0] ||
                      store.state.warranties[1],
                  );
                  f7.views.main.router.navigate(paths.tradeGuaranteePlan);
                }}
              />
            </div>
          )}
          <div className={style['notification-title']}>
            <div className={style['notification-title-badge']}>
              <span className="u-font-bold u-font-size-xl">お知らせ</span>
            </div>
            <div className={style['view-all']}>
              <span onClick={handleClickNavigationNews}>全て見る</span>
              <i className="icon-right" />
            </div>
          </div>
          {isWarrantyExpired && (
            <div className="u-margin-top-sm">
              <IconCard
                path={paths.noticeWarrantyDetail}
                isTransition
                iconType="alert"
                headingFontSize="14px"
                iconFontSize="30px"
                color="danger"
                heading="保証が失効しております"
                supplement={perse(`現在、保証契約が失効しておりますので、保証サービスの役務を停止しております​`)}
              />
            </div>
          )}
          {isRegisterPaymentWarranty && (
            <div
              className={style['notice-box']}
              style={{ background: '#FFEFC6' }}
              onClick={handlePaymentRegistAlert}
            >
              <div className={style['notice-box-icon-title']}>
                <i
                  className="icon-confirm-notification"
                  style={{ color: 'orange' }}
                />
                <div className="notice-box__info-title">
                  保証の決済方法の登録をお願いします。
                </div>
              </div>
              <i className="icon-right" />
            </div>
          )}
          {isShowPrepaidInfo && (
            <div
              className={style['notice-box']}
              style={{ background: '#FFEFC6' }}
              onClick={handlePaymentRegistAlertPrepaid}
            >
              <div className={style['notice-box-icon-title']}>
                <i
                  className="icon-confirm-notification"
                  style={{ color: 'orange' }}
                />
                <div className="notice-box__info-title">
                  プリペイド積み立ての決済方法の登録をお願いします。
                </div>
              </div>
              <i className="icon-right" />
            </div>
          )}
          {news && (
            <div
              className={style['notice-box']}
              onClick={() => handleClickNews(String(news?.id))}
            >
              <div className={style['notice-box-icon-title']}>
                <i
                  className="icon-confirm-notification"
                  style={{ color: 'orange' }}
                />
                <div className="notice-box__info-title">{news?.title}</div>
              </div>
              <i className="icon-right" />
            </div>
          )}
        </div>
        <RankBanner redirectPage={paths.home} />
        <div className={style['menu-wrapper']}>
          <div className="heading-with-button u-font-size-xl u-margin-bottom-sm">
            <span className="u-font-bold">メニュー</span>
          </div>
          <div className={style['menu-buttons-wrapper']}>
            {isShowSelfScan && (
              <div className={style['button-wrapper']}>
                <ButtonIconVertical
                  label={`車両状態\nセルフスキャン`}
                  iconType="state"
                  path={paths.selfScanStart}
                />
              </div>
            )}
            <div className={style['button-wrapper']}>
              <ButtonIconVertical
                label="メンテナンス"
                iconType="calendar-maintenance"
                path={paths.maintenance}
              />
            </div>
            <div className={style['button-wrapper']}>
              <ButtonIconVertical
                label="保証管理"
                iconType="guarantee"
                path={paths.warranty('false')}
              />
            </div>
            <div className={style['button-wrapper']}>
              <ButtonIconVertical
                label="車両情報"
                iconType="info"
                path={paths.car(String(selectedCar?.m_car_id) || '')}
              />
            </div>
            {isrRoyalAutoId && (
              <div
                className={style['button-wrapper']}
                onClick={handleRedirectToMaintenanceReservation}
              >
                <ButtonIconVertical
                  label="メンテナンス予約"
                  iconType="tool-maintenance"
                  path=""
                />
              </div>
            )}
            {(isAdContractor || isLinkDriveOwner) &&
              isShowDriveRecord &&
              selectedCar &&
              isDelivered(selectedCar?.delivery_complete_flg) && (
                <div className={style['button-wrapper']}>
                  <ButtonIconVertical
                    label="運転記録"
                    iconType="car"
                    path={paths.driveRecord}
                  />
                </div>
              )}
            {isDelivered(selectedCar?.delivery_complete_flg) && (
              <div className={style['button-wrapper']}>
                <ButtonIconVertical
                  label={`コネクター\n設定`}
                  iconType="notification-settings"
                  path={paths.connectorSetting}
                />
              </div>
            )}
            {isrRoyalAutoId && (
              <>
                {listExternalServiceURL.map(({ text, href, icon }) => (
                  <div
                    key={text}
                    className={style['button-wrapper']}
                    onClick={() => handleRedirect(href)}
                  >
                    <ButtonIconVertical label={text} iconType={icon} path="" />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {categories && (
          <div className={style['maintenance-wrapper']}>
            <div className="heading-with-button u-font-size-xl u-margin-bottom-sm u-margin-top-sm">
              <span className="u-font-bold">サービスカテゴリー</span>
            </div>
            <PrepaidMenuCard categories={categories} />
          </div>
        )}
        {banners && (
          <div className={style['maintenance-wrapper']}>
            <div className="heading-with-button u-font-size-xl u-margin-bottom-sm u-margin-top-sm">
              <span className="u-font-bold">おすすめ商品</span>
            </div>
            <BannerList banners={banners} />
          </div>
        )}
        {isShowCarWashService && (
          <div className={style['maintenance-wrapper']}>
            <CardRecommend />
          </div>
        )}
        <div className={style['maintenance-wrapper']}>
          <div className="heading-with-button u-font-size-xl u-margin-bottom-sm u-margin-top-sm">
            <span className="u-font-bold">整備</span>
          </div>
          <RequestMaintenanceButton
            handleClickButton={handleClickMaintenanceButton}
          />
        </div>
        {!isPc && isBrainTrainingDisplay && <BrainTraining />}
        {isMileageDisplay && <AdTelemileage />}
      </div>
    </PageTemplateV2>
  );
};
