import { f7 } from 'framework7-react';
import { FC } from 'react';
import { BannerList } from '../BannerList/BannerList';
import styles from './CarWashStyle.module.css';
import IconCarFronShine from '@/assets/images/menu/icon_car-front-shine.svg';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { Banner } from '@/types/api/bannerApi';
import { Service } from '@/types/api/serviceApi';

interface CarWashProps {
  status?: string;
  services?: Service[];
  banners?: Banner[];
  handleClickItem: (target: string) => void;
}

export const CarWash: FC<CarWashProps> = ({
  status,
  // services,
  banners,
  handleClickItem,
}) => {
  return (
    <PageTemplate
      pageName="CarWash"
      title="洗車・コーティング"
      showNavBack
      handleClickBack={() => {
        f7.view.main.router.navigate(paths.maintenance, {
          transition: 'push-reverse',
        });
      }}
    >
      <div className={styles.serviceTitleWrap}>
        <h2>プリペイドが利用できるサービス</h2>
      </div>

      <div className={styles.container}>
        <div
          className={styles.card}
          onClick={() => handleClickItem(paths.carWashConditioner)}
        >
          <img src={IconCarFronShine} />
          <h4>出張カーエアコンクリーニング</h4>
        </div>
        <div
          className={styles.card}
          onClick={() => handleClickItem(paths.carWashHand)}
        >
          <img src={IconCarFronShine} />
          <h4>出張水なし手洗い洗車</h4>
        </div>
      </div>
      {banners && <BannerList banners={banners} />}
    </PageTemplate>
  );
};
