import {
  RepairItemConfirmInfoType,
  RepairRequestItemType,
} from '../../type/bodyAndPaint';

interface RepairRequestConfirmFieldProps {
  info: RepairItemConfirmInfoType;
}
export const RepairRequestConfirmField = ({
  info,
}: RepairRequestConfirmFieldProps) => {
  type ConfirmItem = { label: string; item: string };

  const { repair_request_info, repair_request_select_info_list } =
    info ?? ({} as RepairItemConfirmInfoType);

  const RepairRequestInfoLabel: Record<keyof RepairRequestItemType, string> = {
    car_name: '対象車両',
    category: '入庫区分',
    factory_name: '入庫工場',
    total_amount: '金額',
  };
  const repairRequestInfoList = Object.keys(RepairRequestInfoLabel).map(
    (key) => {
      const keyName = key as keyof RepairRequestItemType;
      return {
        label: RepairRequestInfoLabel[keyName],
        item: repair_request_info[keyName],
      } as ConfirmItem;
    },
  );
  const repairRequestSelectInfoList = repair_request_select_info_list.reduce(
    (list, infoItem) => {
      list.push({ label: '整備項目', item: infoItem.repair_item_name });
      list.push({ label: '保険利用の有無', item: infoItem.insurance_applied });
      return list;
    },
    [] as ConfirmItem[],
  );
  const repairRequestConfirmList = [
    ...repairRequestInfoList,
    ...repairRequestSelectInfoList,
  ];
  // 修理依頼 依頼申請注意カード
  const RepairItemSelectNote = () => {
    const noteLabel = 'ご注意事項';
    const noteText = [
      '修理依頼後のキャンセルならびにご依頼内容の変更は、お受けすることができません',
    ];
    return (
      <div className={'information-card repair-item-select'}>
        <div className={'information-header'}>
          <div className={'header-icon'}>
            <i className={'icon-caution'} />
          </div>
          <div className={'header-label'}>
            <span>{noteLabel}</span>
          </div>
        </div>
        <div className={'information-body'}>
          {noteText.map((text, idx) => (
            <span key={idx}>{text}</span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={'repair-service-request'}>
      <div className={'page-label repair-item-confirm'}>
        <span>{'修理依頼内容の確認'}</span>
      </div>
      <div className={'repair-request-confirm-list'}>
        {repairRequestConfirmList.map((confirm, idx) => (
          <div className={'repair-request-confirm-item'} key={idx}>
            <div className={'confirm-label'}>{confirm.label}</div>
            <div className={'confirm-item'}>{confirm.item}</div>
          </div>
        ))}
      </div>
      <RepairItemSelectNote />
    </div>
  );
};
