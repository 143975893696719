import { InsuranceApplyResultItemType } from '../../type/bodyAndPaint';

interface InsuranceApplyResultCardProps {
  info: InsuranceApplyResultItemType;
  onCheckImage?: () => void;
}
export const InsuranceApplyResultCard = ({
  info,
  onCheckImage,
}: InsuranceApplyResultCardProps) => {
  type KeyProperties = keyof InsuranceApplyResultItemType;
  const LabelItems: Record<KeyProperties, string> = {
    repair_estimate_amount: '修理見積もり金額',
    insurance_applied_amount: '保険申請協定金額',
    deductible_amount: '免責金',
    total_loss_determination: '全損判定',
    total_repair_amount: '合計料金',
  };
  const RecordStyle: Record<KeyProperties, string> = {
    repair_estimate_amount: 'item-data',
    insurance_applied_amount: 'item-data',
    deductible_amount: 'item-data',
    total_loss_determination: 'item-data',
    total_repair_amount: 'total-data',
  };
  const InfoItems: Record<KeyProperties, string> = {
    repair_estimate_amount:
      info.repair_estimate_amount.toLocaleString('ja-JP') + '円',
    insurance_applied_amount:
      info.insurance_applied_amount.toLocaleString('ja-JP') + '円',
    deductible_amount:
      info.deductible_amount !== undefined
        ? info.deductible_amount.toLocaleString('ja-JP') + '円'
        : '-円',
    total_loss_determination: info.total_loss_determination ? '有' : '無',
    total_repair_amount:
      Math.max(0, info.total_repair_amount).toLocaleString('ja-JP') + '円',
  };
  return (
    <div className={'insurance-apply-result-field'}>
      <div className={'insurance-apply-result-header'}>
        <span>{'保険申請結果'}</span>
      </div>
      <div className={'insurance-apply-result-body'}>
        <div className={'insurance-apply-result-card'}>
          {Object.keys(InfoItems).map((key, idx) => {
            const keyName = key as KeyProperties;
            return (
              <div
                className={`insurance-apply-result-item ${RecordStyle[keyName]}`}
                key={idx}
              >
                <div className={`apply-result-label`}>
                  <span>{LabelItems[keyName]}</span>
                </div>
                <div className={`apply-result-info`}>
                  <span>{InfoItems[keyName]}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
