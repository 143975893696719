import { Radio } from 'framework7-react';
import './f7ComponentStyle.css';

interface RadioGroupProps {
  groupName: string;
  list: string[];
  value: string;
  onChange: (newSelect: number) => void;
}
export const RadioGroup = ({
  groupName,
  list,
  value,
  onChange,
}: RadioGroupProps) => {
  const handleSelectRadio = (select: number) => onChange(select);

  const RadioButton = ({
    label,
    checked,
    group,
    onChange,
  }: {
    label: string;
    checked: boolean;
    group: string;
    onChange: () => void;
  }) => {
    const handleSelectChange = () => onChange();
    return (
      <div className={'radio-item'} onClick={handleSelectChange}>
        <Radio className={'radio'} name={group} checked={checked} />
        <div className={'label'}>
          <span>{label}</span>
        </div>
      </div>
    );
  };
  return (
    <div className={'radio-group'} key={groupName}>
      {list.map((label, idx) => (
        <RadioButton
          key={idx}
          label={label}
          checked={list.indexOf(value) === idx}
          group={groupName}
          onChange={() => handleSelectRadio(idx)}
        />
      ))}
    </div>
  );
};
