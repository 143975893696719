import { useDateText } from '../../hook/util/useDateTimeText';
import { AccordionField } from '../accordion';
import { EstimateSubjectItemInfoType } from '@/types/bodyAndPaint';

interface RepairServiceEstimateFieldProps {
  info: EstimateSubjectItemInfoType;
}
export const RepairServiceEstimateField = ({
  info,
}: RepairServiceEstimateFieldProps) => {
  const {
    created_at,
    estimate_number,
    car_name,
    number_plate,
    vin_number,
    check_in_date,
  } = info ?? {};

  type KeyItem = keyof EstimateSubjectItemInfoType;

  const createdAt = useDateText({
    dateTime: new Date(created_at),
    splitter: '/',
  });
  const checkInDate = useDateText({
    dateTime: new Date(check_in_date),
    splitter: '/',
  });
  const labelList: Record<KeyItem, string> = {
    created_at: '見積もり作成日',
    estimate_number: '見積もりNo.',
    car_name: '車名',
    number_plate: 'ナンバープレート',
    vin_number: '車台番号',
    check_in_date: '入庫予定日',
  };
  const infoList: Record<KeyItem, string> = {
    created_at: created_at ? createdAt : '-',
    estimate_number: estimate_number ? estimate_number : '-',
    car_name: car_name,
    number_plate: number_plate,
    vin_number: vin_number,
    check_in_date: checkInDate,
  };
  return (
    <AccordionField title={'見積もり情報'}>
      {Object.entries(labelList).map(([key, label], idx) => (
        <div className={'estimate-subject-info-item'} key={idx}>
          <div className={'estimate-subject-label'}>
            <span>{label}</span>
          </div>
          <div className={'estimate-subject-info'}>
            <span>{infoList[key as KeyItem]}</span>
          </div>
        </div>
      ))}
    </AccordionField>
  );
};
