import { ReactNode, useEffect, useState } from 'react';
import { useRepairProgressDataContext } from '../context/progressDetailContext';
import {
  RepairRequestProvider,
  useRepairRequestStateContext,
} from '../context/repairRequestContext';
import { useRepairRequestData } from '../hook/util/repairRequest/useRepairRequestData';
import {
  RepairRequestDataType,
  RepairRequestStateType,
} from '../type/bodyAndPaint';
import { EstimateDamagedImage } from './estimateDamagedImage';
import { EstimateResultInformation } from './estimateResultInformation';
import { RepairRequestConfirm } from './repairRequestConfirm';
import { RepairRequestItemSelect } from './repairRequestItemSelect';

export const RepairServiceRequest = ({ state }: { state: string }) => {
  const { data: bpData } = useRepairProgressDataContext();

  const stateMap: Record<RepairRequestStateType, boolean> = {
    ESTIMATE_RESULT: true,
    ESTIMATE_IMAGE: true,
    REPAIR_SELECT: true,
    REPAIR_CONFIRM: true,
  };
  const initState = (
    state in Object.keys(stateMap) ? state : 'ESTIMATE_RESULT'
  ) as RepairRequestStateType;

  const [repairRequestState] = useState<RepairRequestStateType>(initState);

  const [data, setData] = useState<RepairRequestDataType>(
    {} as RepairRequestDataType,
  );
  const { repairRequestData } = useRepairRequestData({
    m_customer_id: bpData?.ids.customerID ?? 0,
    t_pm_car_inspection_id: bpData?.ids.inspectionID ?? 0,
    t_sale_id: bpData?.ids.saleID ?? 0,
    status: bpData?.state_info.status ?? -1,
    factory_name: bpData?.state_info.factory_name ?? '-',
    car_info: {
      car_name: bpData?.reserve_info.car_info.car_name ?? '-',
      number_plate: bpData?.reserve_info.car_info.registry_no ?? '-',
      vin_number: bpData?.reserve_info.car_info.vin_number ?? '-',
      check_in_date:
        bpData?.reserve_info.check_in_date ?? new Date('undefined'),
    },
  });
  useEffect(() => {
    setData(repairRequestData);
  }, [repairRequestData]);

  const RepairRequestChildren = () => {
    const { state } = useRepairRequestStateContext();
    const StateMap: Record<RepairRequestStateType, ReactNode> = {
      ESTIMATE_RESULT: <EstimateResultInformation />,
      ESTIMATE_IMAGE: <EstimateDamagedImage />,
      REPAIR_SELECT: <RepairRequestItemSelect />,
      REPAIR_CONFIRM: <RepairRequestConfirm />,
    };
    return <>{state && StateMap[state]}</>;
  };
  return (
    <RepairRequestProvider initialState={repairRequestState} initialData={data}>
      <RepairRequestChildren />
    </RepairRequestProvider>
  );
};
