import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  RepairRequestDataType,
  RepairRequestStateType,
} from '../type/bodyAndPaint';

// Context Object
export const RepairRequestStateContext = createContext<{
  state?: RepairRequestStateType;
  setState: Dispatch<SetStateAction<RepairRequestStateType>>;
}>({
  setState: () => {},
});
export const RepairRequestDataContext = createContext<{
  data?: RepairRequestDataType;
  setData: Dispatch<SetStateAction<RepairRequestDataType>>;
}>({
  setData: () => {},
});

// Context Provider
interface RepairRequestProviderProps {
  initialData: RepairRequestDataType;
  initialState: RepairRequestStateType;
  children: ReactNode;
}
export const RepairRequestProvider = ({
  initialState,
  initialData,
  children,
}: RepairRequestProviderProps) => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState(initialData);

  const stateContext = useMemo(() => ({ state, setState }), [state]);
  const dataContext = useMemo(() => ({ data, setData }), [data]);

  useEffect(() => {
    setState(initialState);
    setData(initialData);
  }, [initialState, initialData]);

  return (
    <RepairRequestStateContext.Provider value={stateContext}>
      <RepairRequestDataContext.Provider value={dataContext}>
        {children}
      </RepairRequestDataContext.Provider>
    </RepairRequestStateContext.Provider>
  );
};

// Context Hook
export const useRepairRequestStateContext = () => {
  const { state, setState } = useContext(RepairRequestStateContext);
  return { state, setState };
};
export const useRepairRequestDataContext = () => {
  const { data, setData } = useContext(RepairRequestDataContext);
  return { data, setData };
};
