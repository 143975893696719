import { getUnixTime } from 'date-fns';
import { f7, useStore } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect, useState } from 'react';
import { getPrepaidPaymentInfo } from '../ChargePrepaid/ChargePrepaidHelper';
import {
  findRelevantNextWarranty,
  findWarrantyPaymentMethod,
  getExpiredWarranties,
  getValidWarranties,
  getWarrantiesMissDocument,
  listValidWarranties,
} from '../WarrantyManagement/WarrantyManagementHelper';
import { Home } from './Home/Home';
import { linkDriveOwnerApi } from '@/api/infoApi';
import { isApp } from '@/config/device';
import { AuthInfoState, initialState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { deliveryCompleteFlg } from '@/consts/car';
import { carWashServiceFlag } from '@/consts/carWash';
import {
  connectorJob,
  delFlg,
  jobUpdatingLabel,
  linkDriveSetupStatus,
  linkDriveStatus,
  setupProcessingStatus,
  updatePromiseResolveFlg,
} from '@/consts/linkDrive';
import { chargeFlg } from '@/consts/payment';
import { affiliateId as AFFILIATE_ID } from '@/consts/shop';
import { url } from '@/consts/url';
import { useFetchBanners } from '@/hooks/api/banner/useFetchBanners';
import { useCustomerInfo } from '@/hooks/api/customer/useCustomerInfo';
import { useLoginCount } from '@/hooks/api/customer/useLoginCount';
import { useFetchServiceCategories } from '@/hooks/api/service/useFetchServiceCategories';
import { useLinkDriveSetupProgress } from '@/hooks/useLinkDriveSetupProgress';
import { Affiliate } from '@/types/api/authApi';
import {
  BillInformation,
  Car,
  Prepaid,
  Warranty,
  PaymentMethod,
} from '@/types/api/customerApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import { GmoRedirectInfo } from '@/types/payment';
import { autoLoginHelper } from '@/utils/autoLoginHelper';
import { checkImpactInfo } from '@/utils/checkECall';
import { storeDispatch } from '@/utils/store';
// 下記importを消去しないように注意
import '@/utils/permissionHelper';
import '@/utils/deeplinkHelper';
import '@/utils/autoLoginHelper';

interface HomePageProps {
  f7router: Router.Router;
  status?: string;
  gmoRedirectInfo?: GmoRedirectInfo;
}

export const HomePage: FC<HomePageProps> = ({
  f7router,
  status,
  gmoRedirectInfo,
}) => {
  const authInfo = useStore(store, 'authInfo') as AuthInfoState;
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const isInfoLoaded = useStore(store, 'getIsInfoLoaded') as boolean;
  const affiliatesInfo = useStore(store, 'affiliatesInfo') as Affiliate[];
  const globalTheme = useStore(store, 'globalTheme') as number | null;
  const isImpactInfoChecked = useStore(
    store,
    'getIsImpactInfoChecked',
  ) as boolean;
  const paymentMethods = useStore(
    store,
    'getPaymentMethodsInfo',
  ) as PaymentMethod[];
  const billsInfo = useStore(store, 'getBillInfo') as BillInformation[];
  const { warranties, nextWarranties } = store.state;
  const ownedCars = useStore(store, 'carsInfo') as Required<Car>[];
  const prepaidInfo = useStore(
    store,
    'getFirstAdvancePaymentsInfo',
  ) as Required<Prepaid>;
  const linkDriveOwnerInfo = useStore(
    store,
    'linkDriveOwnerInfo',
  ) as LinkDriveOwner;
  const [isOpenCarList, setIsOpenCarList] = useState(false);
  const [isWarrantyExpired, setIsWarrantyExpired] = useState(false);
  const [isWarrantiesMissDocument, setIsWarrantiesMissDocument] =
    useState(false);
  const [unregistPaymentWarranty, setUnregistPaymentWarranty] =
    useState<Warranty>();
  const [isShowPrepaidInfo, setIsShowPrepaidInfo] = useState(false);
  const [isBrainTrainingDisplay, setIsBrainTrainingDisplay] = useState(false);
  const [isMileageDisplay, setIsMileageDisplay] = useState(false);
  const [isRegisterPaymentWarranty, setIsRegisterPaymentWarranty] =
    useState(false);
  const information = store.state.notificationsInfo;
  const { callCountLoginApi } = useLoginCount(authInfo.m_customer_id);
  const { recursiveJobStatus, updateModeReservation, updateECU } =
    useLinkDriveSetupProgress(store.state.linkDriveAbortController.signal);
  let affiliatesSwitch = affiliatesInfo;

  if (globalTheme) {
    const currentAffiliate = affiliatesInfo.find(
      (affiliate) => affiliate.id === authInfo.m_customer_id,
    );
    affiliatesSwitch = affiliatesInfo.filter(
      (affiliate) =>
        affiliate.id === authInfo.m_customer_id ||
        currentAffiliate?.affiliate_app_type === affiliate.affiliate_app_type,
    );
  }

  const isShowCarWashService =
    store.state.customerInfo.carwash_service_flg ===
    carWashServiceFlag.INSTALLED;

  const isShowSwitchCar = !(
    ownedCars.length < 2 && affiliatesSwitch.length < 2
  );

  const { getCustomerWithParams, refetch } = useCustomerInfo(
    {
      m_customer_id: authInfo.m_customer_id,
    },
    !status,
  );

  const timestamp = getUnixTime(new Date());
  const newsList = information.filter((item) => {
    return (
      getUnixTime(new Date(item.start_date)) <= timestamp &&
      getUnixTime(new Date(item.end_date)) >= timestamp
    );
  });

  const displayNews = newsList.length > 0 ? newsList.slice(0, 1)[0] : undefined;
  const affiliate_id = store.state.customerInfo.m_affiliate_id;
  // const affiliate_id = 331;
  const { categories } = useFetchServiceCategories({
    m_affiliate_id: affiliate_id,
  });
  const displayCategories =
    affiliate_id === AFFILIATE_ID.YAMADA ? categories : undefined;
  const { banners } = useFetchBanners({
    m_affiliate_id: affiliate_id,
    limit: 5,
  });

  const handleClickRegistCar = () => {
    storeDispatch('setRegisterUser', 'registerUser', {
      ...initialState.registerUser,
      currentStep: 3,
    });
    f7.view.main.router.navigate(paths.registerNewMember, {
      props: {
        sourcePage: paths.home,
        startStep: 3,
      },
    });
  };

  const handleClickListImage = async (
    car: Affiliate['cars'][0] | null,
    affiliateID: number,
  ) => {
    f7.preloader.show();
    let carsList = ownedCars;
    if (affiliateID !== authInfo.m_customer_id) {
      const customerInfo = await getCustomerWithParams(
        {
          m_customer_id: affiliateID,
        },
        (c) => {
          if (car) {
            return car.m_car_id == null
              ? car.t_order_id === c.t_order_id
              : car.m_car_id === c.m_car_id;
          }
          return true;
        },
      );
      if (customerInfo?.cars) {
        carsList = customerInfo.cars as Required<Car>[];
      }
    }
    const carInfo = car
      ? carsList.find((c) =>
          car.m_car_id == null
            ? car.t_order_id === c.t_order_id
            : car.m_car_id === c.m_car_id,
        )
      : initialState.carItemInfo;
    if (!carInfo) {
      setIsOpenCarList(false);
      f7.preloader.hide();
      return;
    }
    await storeDispatch('setCarItemInfo', 'carItemInfo', carInfo);
    await storeDispatch(
      'setWarranties',
      'warranties',
      carInfo?.warranties || [],
    );
    await storeDispatch(
      'setNextWarranties',
      'nextWarranties',
      carInfo?.next_warranty || [],
    );
    await storeDispatch(
      'setCurrentWarranty',
      'currentWarranty',
      carInfo?.warranties?.[0],
    );
    await storeDispatch(
      'setNextWarranty',
      'nextWarranty',
      findRelevantNextWarranty(
        carInfo?.warranties?.[0],
        carInfo?.next_warranty,
      ),
    );
    const { t_stock_car_id } = carInfo;
    setIsOpenCarList(false);
    f7.preloader.hide();
    const { data: linkDriveOwner } = await linkDriveOwnerApi({
      params: {
        customer_id: store.state.authInfo.m_customer_id,
        stock_car_id: t_stock_car_id,
        del_flg: 0,
      },
    });

    store.state.linkDriveAbortController.abort();
    await storeDispatch(
      'setOwnerId',
      'ownerId',
      linkDriveOwner?.data?.link_drive_owner.owner_id ||
        initialState.linkDriveOwnerInfo.owner_id,
    );
    await storeDispatch(
      'setSerialNumberInput',
      'serialNumberInput',
      initialState.serialNumberInput,
    );
    await storeDispatch(
      'setSerialNumberDb',
      'serialNumberDb',
      linkDriveOwner?.data?.link_drive_owner.serial_no ||
        initialState.linkDriveOwnerInfo.serial_no,
    );
    await storeDispatch(
      'setLinkDriveUpdating',
      'linkDriveUpdating',
      initialState.linkDriveUpdating,
    );
    await storeDispatch(
      'setLinkDriveAbortController',
      'linkDriveAbortController',
      new AbortController(),
    );
    await storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
    await storeDispatch(
      'setLinkDriveOwnerInfo',
      'linkDriveOwnerInfo',
      linkDriveOwner?.data?.link_drive_owner || initialState.linkDriveOwnerInfo,
    );
    storeDispatch(
      'setPrepaidChargePayment',
      'prepaidChargePayment',
      initialState.prepaidChargePayment,
    );
    if (
      linkDriveOwner?.error &&
      linkDriveOwner.error.message !== 'LinkDriveOwnerなし'
    ) {
      throw new Error(linkDriveOwner.error.message);
    }
    // AD向け車両のログインカウント
    await callCountLoginApi(carInfo);
  };

  const handleClickNavigationNews = () => {
    f7.view.main.router.navigate(paths.news);
  };

  const handleClickMaintenanceButton = () => {
    f7.view.main.router.navigate(paths.maintenanceGuide);
  };

  const handleClickNews = (id: string) => {
    f7.view.main.router.navigate(paths.noticeDetail(id), {
      props: { item: displayNews },
    });
  };

  const changeBannerIsDisplay = (car?: Required<Car>) => {
    const isLinkDriveStatus =
      car?.linkdrive_status === linkDriveStatus.FORMAL_REGISTRATION ||
      car?.linkdrive_status === linkDriveStatus.AGREEMENT;
    const isAdMileageDisplayFlg =
      car?.ad_mileage_display_flg === linkDriveStatus.TEMPORARY_REGISTRATION;
    setIsBrainTrainingDisplay(isLinkDriveStatus);
    setIsMileageDisplay(isLinkDriveStatus || isAdMileageDisplayFlg);
  };

  const handlePaymentRegistAlert = () => {
    if (!unregistPaymentWarranty) return;
    storeDispatch(
      'setCurrentWarranty',
      'currentWarranty',
      unregistPaymentWarranty,
    );
    store.dispatch('setPaymentTempData', {
      type: 'warranty',
      openDialogName: paths.registerPayment(
        'warranty',
        unregistPaymentWarranty.warranty_no,
      ),
      isTransition: false,
    });
    f7.view.main.router.navigate(
      paths.registerPayment('warranty', unregistPaymentWarranty.warranty_no),
      {
        props: {
          redirectPage: 'home',
        },
      },
    );
  };

  const handlePaymentRegistAlertPrepaid = () => {
    window.localStorage.setItem('m_prepaid_id', JSON.stringify(prepaidInfo.id));
    window.localStorage.setItem('prevPage', 'prepaid');
    f7.view.main.router.navigate(
      paths.registerPayment('prepaid', String(prepaidInfo.id)),
      {
        props: {
          redirectPage: 'home',
        },
      },
    );
  };

  const handleOnRefech = async (cb: () => void) => {
    try {
      await refetch();
      cb();
    } catch (e) {
      console.error('Error while refetching: ', e);
    }
  };

  useEffect(() => {
    if (
      isApp &&
      !store.state.linkDriveCheck &&
      linkDriveOwnerInfo.id &&
      linkDriveOwnerInfo.del_flg === delFlg.NOT_DELETE &&
      linkDriveOwnerInfo.setup_status !=
        linkDriveSetupStatus.MODE_RESERVATION_COMPLETE
    ) {
      window.location.href =
        url.NATIVE_BASE_URL + '/linkdrive/setup_incomplete/';
      storeDispatch('setLinkDriveCheck', 'linkDriveCheck', true);
    }
  }, [linkDriveOwnerInfo]);

  useEffect(() => {
    const mCustomerId = window.localStorage.getItem('m_customer_id');
    if (
      !mCustomerId ||
      !authInfo.isLogin ||
      !unregistPaymentWarranty?.warranty_no
    )
      return;
    f7.views.main.router.navigate(
      paths.registerPayment('warranty', unregistPaymentWarranty?.warranty_no),
      {
        props: {
          redirectPage: 'warranty',
        },
      },
    );
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('m_customer_id');
    window.localStorage.removeItem('globalTheme');
    window.localStorage.removeItem('isLoggedInToMaikuru');
    window.localStorage.removeItem('prepaid_registration_info');
  }, [authInfo.isLogin, unregistPaymentWarranty?.warranty_no]);

  useEffect(() => {
    changeBannerIsDisplay(selectedCar);
  }, [selectedCar]);

  useEffect(() => {
    let validWarranties = getValidWarranties(warranties);
    if (
      selectedCar?.delivery_complete_flg ==
        deliveryCompleteFlg.BEFORE_DELIVERY &&
      validWarranties.length === 0
    ) {
      validWarranties = warranties;
    }
    const unregisteredWarranties = validWarranties.filter(
      (warranty) => !findWarrantyPaymentMethod(warranty, paymentMethods),
    );
    setUnregistPaymentWarranty(unregisteredWarranties[0]);
    !!warranties.length &&
      setIsWarrantyExpired(getExpiredWarranties(warranties).length > 0);
    setIsWarrantiesMissDocument(
      getWarrantiesMissDocument(warranties).length > 0,
    );
  }, [paymentMethods, warranties]);

  useEffect(() => {
    const payment = getPrepaidPaymentInfo(prepaidInfo, paymentMethods);
    const isShowPrepaidPaymentInfo =
      !payment && prepaidInfo?.charge_flg === chargeFlg;
    setIsShowPrepaidInfo(isShowPrepaidPaymentInfo);
  }, [prepaidInfo, paymentMethods]);

  useEffect(() => {
    if (status && !authInfo.isLogin) {
      const customerId = window.localStorage.getItem('m_customer_id');
      const token = window.localStorage.getItem('token');
      const m_car_id = window.localStorage.getItem('m_car_id');
      const t_order_id = window.localStorage.getItem('t_order_id');
      const loginType = window.localStorage.getItem('loginType');
      const isLoggedInToMaikuru =
        window.localStorage.getItem('isLoggedInToMaikuru') === 'true';
      if (customerId && token) {
        autoLoginHelper({
          token,
          id: Number(customerId),
          f7router,
          type: loginType || '',
          isLoggedInToMaikuru,
          navigate: false,
        }).then(() => {
          getCustomerWithParams({ m_customer_id: Number(customerId) }, (c) =>
            c.m_car_id == null
              ? c.t_order_id === Number(t_order_id)
              : c.m_car_id === Number(m_car_id),
          );
        });
      }
      window.localStorage.removeItem('loginType');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('m_customer_id');
      window.localStorage.removeItem('globalTheme');
      window.localStorage.removeItem('isLoggedInToMaikuru');
      window.localStorage.removeItem('prepaid_registration_info');
    }
  }, [status, authInfo, f7router]);

  useEffect(() => {
    /**
     * @description Requesting Push notification permission
     * When native app receives the request,
     * it uses onPushEnabled global function to inject the state
     * onPushEnabled function is being imported at line 14 of this file.
     * Check the implementation.
     */

    if (isApp) {
      window.location.href = url.NATIVE_BASE_URL + '/permission/push';
    }
  }, []);

  useEffect(() => {
    if (isInfoLoaded && !isImpactInfoChecked) {
      checkImpactInfo(store.state.authInfo.m_customer_id);
    }
  }, [isInfoLoaded, isImpactInfoChecked]);

  useEffect(() => {
    let validWarranties = getListValidWarranties();
    const billWarranty = billsInfo.find(
      (bill) =>
        bill.warranty_no != null &&
        checkWarranties(bill.warranty_no, validWarranties) &&
        bill.next_bill_date != null,
    );
    setIsRegisterPaymentWarranty(!!(billWarranty && unregistPaymentWarranty));
  }, [billsInfo]);

  const checkWarranties = (warrantyNo: any, validWarranties: Warranty[]) => {
    if (!warrantyNo) return false;

    const warranty = validWarranties.find(
      (warranty) => warranty.warranty_no === warrantyNo,
    );

    return !!warranty;
  };

  const getListValidWarranties = () => {
    let validWarranties = listValidWarranties(warranties, nextWarranties);
    if (
      selectedCar?.delivery_complete_flg ==
        deliveryCompleteFlg.BEFORE_DELIVERY &&
      validWarranties.length === 0
    ) {
      validWarranties = warranties;
    }

    return validWarranties;
  };

  window.onLinkDriveJob = async (job: string, job_id: string) => {
    if (job && job_id) {
      storeDispatch(
        'setLinkDriveUpdating',
        'linkDriveUpdating',
        setupProcessingStatus.PROCESSING,
      );
      let rs = updatePromiseResolveFlg.ENDED;
      const startTime = new Date().getTime();
      rs = await recursiveJobStatus(job, job_id, startTime);
      if (
        rs === updatePromiseResolveFlg.SUCCESS &&
        job === connectorJob.FIRMWARE_UPDATE
      ) {
        rs = await updateModeReservation();
      }
      if (
        rs === updatePromiseResolveFlg.SUCCESS &&
        job !== connectorJob.ECU_LIST_UPDATE
      ) {
        const ecuStartTime = new Date().getTime();
        storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
        storeDispatch(
          'setLinkDriveSetupCurrent',
          'linkDriveSetupCurrent',
          jobUpdatingLabel[connectorJob.ECU_LIST_UPDATE],
        );
        rs = await updateECU(ecuStartTime);
      }
      if (isApp) {
        window.location.href =
          url.NATIVE_BASE_URL + `/linkdrive/setup_complete/`;
      }
      if (rs === updatePromiseResolveFlg.SUCCESS) {
        storeDispatch(
          'setLinkDriveUpdating',
          'linkDriveUpdating',
          setupProcessingStatus.SUCCESS,
        );
        return;
      }
      storeDispatch(
        'setLinkDriveUpdating',
        'linkDriveUpdating',
        setupProcessingStatus.INACTIVE,
      );
      storeDispatch('setLinkDriveSetupCurrent', 'linkDriveSetupCurrent', '');
      storeDispatch('setLinkDriveProgress', 'linkDriveProgress', 0);
    }
  };

  return (
    <Home
      isBasicInfoLoaded={isInfoLoaded}
      isBrainTrainingDisplay={isBrainTrainingDisplay}
      isMileageDisplay={isMileageDisplay}
      selectedCar={selectedCar}
      isOpenCarList={isOpenCarList}
      linkDriveOwner={linkDriveOwnerInfo}
      news={displayNews}
      categories={displayCategories}
      banners={banners}
      affiliatesInfo={affiliatesSwitch}
      isShowCarWashService={isShowCarWashService}
      isShowSwitchCar={isShowSwitchCar}
      isShowPrepaidInfo={isShowPrepaidInfo}
      isRegisterPaymentWarranty={isRegisterPaymentWarranty}
      isWarrantyExpired={isWarrantyExpired}
      isWarrantiesMissDocument={isWarrantiesMissDocument}
      handleClickRegistCar={handleClickRegistCar}
      changeIsOpenCarList={setIsOpenCarList}
      handleClickListImage={handleClickListImage}
      handleClickMaintenanceButton={handleClickMaintenanceButton}
      handleClickNavigationNews={handleClickNavigationNews}
      handleClickNews={handleClickNews}
      handlePaymentRegistAlert={handlePaymentRegistAlert}
      handlePaymentRegistAlertPrepaid={handlePaymentRegistAlertPrepaid}
      onRefetch={handleOnRefech}
    />
  );
};
