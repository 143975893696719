import { f7 } from 'framework7-react';
import { useCallback, useMemo } from 'react';
import {
  useCheckInReserveDataContext,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import { useHotFixTransition } from '../hook/useHotFixTransition';
import { useCarConditionInfo } from '../hook/util/repairCheckIn/useCarConditionInfo';
import { CheckInReserveDataType } from '../type/bodyAndPaint';
import { CheckInAnnounceCard } from '../util/repairCheckIn/checkInAnnounceCard';
import { EntryCarConditionField } from '../util/repairCheckIn/entryCarCondition';
import { NextButtonField } from '../util/repairCheckIn/nextButtonField';
import style from './repairCheckInReserveStyle.module.css';
import { paths } from '@/config/paths';

export const CheckInReserveCarCondition = () => {
  const { changePageInfo } = useHotFixTransition();
  const { setState } = useCheckInReserveStateContext();
  const { data } = useCheckInReserveDataContext();
  const { car_condition_info } = data ?? ({} as CheckInReserveDataType);
  const carConditionInfo = useMemo(
    () => car_condition_info,
    [car_condition_info],
  );

  const [stateCarCondition, setCarCondition, disable] = useCarConditionInfo({
    info: carConditionInfo,
  });

  const handleClickBack = useCallback(() => {
    f7.views.main.router.navigate(paths.maintenance);
  }, []);
  const handleClickNext = useCallback(() => {
    setState('USE_INSURANCE');
  }, [setState]);

  return (
    <>
      <div className={style.checkInReserveEntryField}>
        <CheckInAnnounceCard />
        <EntryCarConditionField
          info={stateCarCondition}
          setInfo={setCarCondition}
        />
      </div>
      <NextButtonField disable={disable} onClick={handleClickNext} />
    </>
  );
};
