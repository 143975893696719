import { Router } from 'framework7/types';
import {
  LoginPage,
  LoginTutorialPage,
  TermsOfUsePage,
} from '@/components/pages';
import { AccountForgottenPage } from '@/components/pages/AccountForgotten/AccountForgottenPage';
import { AdminAuthPage } from '@/components/pages/AdminAuth/AdminAuthPage';
import { ConfirmTermsPage } from '@/components/pages/ConfirmTerms/ConfirmTermsPage';
import { Contact } from '@/components/pages/Contact/Contact.tsx/Contact';
import { ContactCompletePage } from '@/components/pages/ContactComplete/ContactCompletePage';
import { ContactLoginPage } from '@/components/pages/ContactLogin/ContactLoginPage';
import { ContactPcPage } from '@/components/pages/ContactPc/ContactPhonePage';
import { ContactPhonePage } from '@/components/pages/ContactPhone/ContactPhonePage';
import { CreateAccountByEmailPage } from '@/components/pages/CreateAccountByEmail/CreateAccountByEmailPage';
import { DrivingScoreTermsDetailPage } from '@/components/pages/DrivingScoreTermsDetail/DrivingScoreTermsDetailPage';
import { EmailCompletePage } from '@/components/pages/EmailComplete/EmailCompletePage';
import { EmailDisablePage } from '@/components/pages/EmailDisable/EmailDisablePage';
import { EmailDuplicatedPage } from '@/components/pages/EmailDuplicated/EmailDuplicatedPage';
import { loginTabRoutes } from '@/components/pages/Login/loginTabRoutes';
import { PasswordPage } from '@/components/pages/Password/PasswordPage';
import { PhoneChangedPage } from '@/components/pages/PhoneChanged/PhoneChangedPage';
import { PhoneDuplicatedPage } from '@/components/pages/PhoneDuplicated/PhoneDuplicatedPage';
import { PinCodePage } from '@/components/pages/PinCode/PinCodePage';
import { PrivacyPage } from '@/components/pages/Privacy/PrivacyPage';
import { PrivacyPcPage } from '@/components/pages/PrivacyPc/PrivacyPcPage';
import { RegisterNewMember } from '@/components/pages/RegisterNewMember/RegisterNewMember';
import { RegisterNewStaff } from '@/components/pages/RegisterNewMember/RegisterNewStaff';
import { RegisterNewMemberConfirm } from '@/components/pages/RegisterNewMemberConfirm/RegisterNewMemberConfirm';
import { RegisterQRPage } from '@/components/pages/RegisterQR/RegisterQRPage';
import { ResendEmailPage } from '@/components/pages/ResendEmail/ResendEmailPage';
import { ResendSmsPage } from '@/components/pages/ResendSms/ResendSmsPage';
import { ResetPasswordPage } from '@/components/pages/ResetPassword/ResetPasswordPage';
import { ResetPasswordCompletePage } from '@/components/pages/ResetPasswordComplete/ResetPasswordCompletePage';
import { SetPasswordPage } from '@/components/pages/SetPassword/SetPasswordPage';
import { SmsDisablePage } from '@/components/pages/SmsDisable/SmsDisablePage';
import { SmsShouldBeAvailablePage } from '@/components/pages/SmsShouldBeAvailable/SmsShouldBeAvailablePage';
import { TermsPage } from '@/components/pages/Terms/TermsPage';
import { UnRegisteredPage } from '@/components/pages/UnRegistered/UnRegisteredPage';
import { paths } from '@/config/paths';

export const loginRoutes: Router.RouteParameters[] = [
  {
    path: paths.loginTutorial,
    component: LoginTutorialPage,
    name: 'チュートリアル',
  },
  {
    path: paths.root,
    name: 'ログイン',
    redirect: paths.loginTutorial,
  },
  {
    path: paths.registerQR,
    component: RegisterQRPage,
    name: 'ログイン',
  },
  {
    path: paths.registerNewMember,
    component: RegisterNewMember,
    name: 'ログイン',
  },
  {
    path: paths.registerNewStaff,
    component: RegisterNewStaff,
    name: 'ログイン',
  },
  {
    path: paths.registerNewMemberConfirm,
    component: RegisterNewMemberConfirm,
    name: 'ログイン',
  },
  {
    path: paths.login,
    component: LoginPage,
    tabs: loginTabRoutes,
    name: 'ログイン',
  },
  {
    path: paths.pinCode,
    component: PinCodePage,
    name: '認証コードの入力',
  },
  {
    path: paths.setPassword,
    component: SetPasswordPage,
    name: 'パスワード設定',
  },
  {
    path: paths.password,
    component: PasswordPage,
    name: 'パスワード入力',
  },
  {
    path: paths.emailComplete,
    component: EmailCompletePage,
    name: '認証メール送信',
  },
  {
    path: paths.drivingScoreTermsDetail(':from'),
    popup: {
      component: DrivingScoreTermsDetailPage,
    },
    name: '安全運転スコアサービスに関する確認事項',
  },
  {
    path: paths.unRegistered,
    popup: {
      component: UnRegisteredPage,
    },
    options: {
      animate: true,
    },
    name: 'アカウントを持っていない',
  },
  {
    path: paths.confirmTerms,
    component: ConfirmTermsPage,
    name: '初回ログイン完了',
  },
  {
    path: paths.terms,
    popup: {
      component: TermsPage,
    },
    options: {
      animate: true,
    },
    name: '利用規約',
  },
  {
    path: paths.termsOfUse,
    component: TermsOfUsePage,
    options: {
      animate: true,
    },
    name: '利用規約',
  },
  {
    path: paths.privacy,
    component: PrivacyPage,
    name: 'プライバシーポリシー',
  },
  {
    path: paths.privacyPc,
    component: PrivacyPcPage,
    name: 'プライバシーポリシー',
  },
  {
    path: paths.resetPassword,
    popup: {
      component: ResetPasswordPage,
    },
    options: {
      animate: true,
    },
    name: 'パスワードを忘れた方',
  },
  {
    path: paths.resetPasswordComplete,
    popup: {
      component: ResetPasswordCompletePage,
    },
    options: {
      animate: true,
    },
    name: 'パスワード再発行',
  },
  {
    path: paths.contactLogin,
    popup: {
      component: ContactLoginPage,
    },
    options: {
      animate: true,
    },
    name: '問い合わせ_問題の報告',
  },
  {
    path: paths.contactPhone,
    popup: {
      component: ContactPhonePage,
    },
    options: {
      animate: true,
    },
    name: '問い合わせ_電話番号を変更した',
  },
  {
    path: paths.contactLogin,
    popup: {
      component: Contact,
    },
    options: {
      animate: true,
    },
    name: '問い合わせ_問題の報告',
  },
  {
    path: paths.contactPc,
    component: ContactPcPage,
    name: '問い合わせ_問題の報告',
  },
  {
    path: paths.contactComplete,
    popup: {
      component: ContactCompletePage,
    },
    options: {
      animate: true,
    },
    name: '問い合わせ完了',
  },
  {
    path: paths.resendEmail,
    popup: {
      component: ResendEmailPage,
    },
    options: {
      animate: true,
    },
    name: 'メールが届かない',
  },
  {
    path: paths.resendSms,
    popup: {
      component: ResendSmsPage,
    },
    options: {
      animate: true,
    },
    name: 'SMS確認方法',
  },
  {
    path: paths.smsDisable,
    popup: {
      component: SmsDisablePage,
    },
    options: {
      animate: true,
    },
    name: 'SMSを受け取れない電話番号',
  },
  {
    path: paths.smsDisable2,
    popup: {
      component: SmsShouldBeAvailablePage,
    },
    options: {
      animate: true,
    },
    name: 'SMSを受け取れない電話番号-2',
  },
  {
    path: paths.emailDisable,
    popup: {
      component: EmailDisablePage,
    },
    options: {
      animate: true,
    },
    name: 'SMSを受け取れない電話番号-3',
  },
  {
    path: paths.emailDisable,
    popup: {
      component: EmailDisablePage,
    },
    name: 'メールが届かない',
  },
  {
    path: paths.phoneChanged,
    popup: {
      component: PhoneChangedPage,
    },
    options: {
      animate: true,
    },
    name: '電話番号を変更した',
  },
  {
    path: paths.contactNumber,
    popup: {
      component: ContactPhonePage,
    },
    options: {
      animate: true,
    },
    name: '電話番号を変更した_問い合わせ',
  },
  {
    path: paths.phoneDuplicated,
    popup: {
      component: PhoneDuplicatedPage,
    },
    options: {
      animate: true,
    },
    name: '複数の同じ電話番号が存在',
  },
  {
    path: paths.accountForgotten,
    popup: {
      component: AccountForgottenPage,
    },
    options: {
      animate: true,
    },
    name: 'アカウントがわからない',
  },
  {
    path: paths.createAccountByEmail,
    component: CreateAccountByEmailPage,
    name: 'メールアドレスでアカウントを作成',
  },
  {
    path: paths.adminAuth,
    component: AdminAuthPage,
    name: '管理者ログイン',
  },
  {
    path: paths.emailDuplicated,
    popup: {
      component: EmailDuplicatedPage,
    },
    options: {
      props: {
        affiliates: [],
      },
      animate: true,
    },
    name: 'ログイン_モーダル',
  },
];
