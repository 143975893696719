import { f7, Icon, Link, NavLeft } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC, useEffect } from 'react';
import style from './loginTutorialStyle.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { LoginPageTemplate } from '@/components/uiParts/Template/LoginPageTemplate/LoginPageTemplate';
import { UnderLineLink } from '@/components/uiParts/UnderLineLink/UnderLineLink';
import { isApp } from '@/config/device';
import { initialState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';
import { url } from '@/consts/url';
import { ParsedLoginTutorialQrScan } from '@/types/qr';
import { autoLoginHelper } from '@/utils/autoLoginHelper';
import { autoLoginNativeHelper } from '@/utils/autoLoginNativeHelper';
import { storeDispatch } from '@/utils/store';

interface LoginTutorialProps {
  f7router: Router.Router;
}

export const LoginTutorial: FC<LoginTutorialProps> = ({ f7router }) => {
  const handleClickLogin = () => {
    f7.views.main.router.navigate(paths.login, { reloadAll: true });
  };
  const handleScanClick = () => {
    if (isApp) {
      window.location.href = url.NATIVE_BASE_URL + '/qrcode_reader';
    } else {
      handleClickLogin();
    }
  };

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    const gmoRedirectInfo = window.localStorage.getItem('gmo_redirect_info');
    const mCustomerId = window.localStorage.getItem('m_customer_id');
    if (token && gmoRedirectInfo && mCustomerId) {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('gmo_redirect_info');
      window.localStorage.removeItem('m_customer_id');
      window.localStorage.removeItem('prepaid_registration_info');
      // GMOから戻ってきた際にオートログインする
      autoLoginHelper({
        token,
        id: Number(mCustomerId),
        f7router: f7.views.main.router,
        gmoRedirectInfo: JSON.parse(gmoRedirectInfo),
      });
    }
    // Nativeアプリからの場合は自動ログインさせるための連携URLに遷移させる
    if (isApp) {
      window.location.href = url.NATIVE_BASE_URL + '/get/login_success';
    }
  }, []);

  if (isApp) {
    // win.open(NativeSetting.BASEURL + '/on_app_start')
    location.href = url.NATIVE_BASE_URL + '/on_app_start';
  }

  window['onCodeReading'] = function (qrData: string) {
    try {
      const data: ParsedLoginTutorialQrScan = JSON.parse(qrData);
      if ('access_token' in data && 'websocket_token' in data) {
        f7.view.main.router.navigate(
          paths.contractConfirm(data.access_token, data.websocket_token),
        );
        return;
      }
      if ('affiliate_id' in data && 'user_register' in data) {
        storeDispatch('setRegisterUser', 'registerUser', {
          ...initialState.registerUser,
          affiliate_id: data.affiliate_id,
        });
        f7.view.main.router.navigate(
          `${paths.registerQR}?affiliate_id=${
            data.affiliate_id || affiliateId.DEFAULT
          }`,
        );
        return;
      }
    } catch (e) {
      f7.dialog.alert('Invalid QR code');
    }
  };

  // NativeアプリからのコールバックJS関数
  // アプリローカルに保存済みの認証情報を受け取る
  window['onCheckAutoLogin'] = (token: string, id: string, type?: string) => {
    autoLoginNativeHelper({ token, id, type });
  };

  // NativeアプリからのコールバックJS関数
  // Applinks（パスワード初期設定/パスワードリセットURL）の認証情報を受け取る
  window['onApplink'] = (dest: string, token: string) => {
    if (dest) {
      store.dispatch('setAuthInfo', {
        authInfo: {
          token: decodeURI(token),
          isLogin: false,
        },
      });
      f7.view.main.router.navigate('/set_password/', { props: { dest } });
    }
  };

  const navLeft = (
    <NavLeft>
      {isApp && (
        <Link animate onClick={handleScanClick} className="icon-scan-wrapper">
          <Icon className="icon-scan" />
          QRコード読み取りはこちら
        </Link>
      )}
    </NavLeft>
  );

  return (
    <LoginPageTemplate pageName="LoginTutorial" navLeft={navLeft}>
      <div className={style['buttons-container']}>
        <div className={style['button-wrapper']}>
          <Button handleClickAction={handleClickLogin} height="48px">
            ログイン
          </Button>
        </div>
        <UnderLineLink
          f7router={f7router}
          onClick={() => {
            storeDispatch('setRegisterUser', 'registerUser', {
              ...initialState.registerUser,
              affiliate_id: affiliateId.DEFAULT,
            });
            f7.view.main.router.navigate(
              `${paths.registerQR}?affiliate_id=${affiliateId.DEFAULT}`,
            );
          }}
          text="アカウントをお持ちでない方"
        />
      </div>
    </LoginPageTemplate>
  );
};
