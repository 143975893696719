import { useCallback, useEffect, useState } from 'react';
import { getMonthlyCheckInReserveApi } from '@/api/bodyAndPaint';

interface GetMonthlyCheckInDataHookProp {
  m_plant_id: number;
  year_month: string;
}
export const useGetMonthlyCheckInData = ({
  m_plant_id,
  year_month,
}: GetMonthlyCheckInDataHookProp) => {
  const [checkInDataList, setCheckInDataList] = useState<
    { [date_block: string]: Date[] }[]
  >([]);

  const fetchData = useCallback(async () => {
    try {
      if (m_plant_id > 0 && year_month !== '') {
        const response = await getMonthlyCheckInReserveApi({
          m_plant_id,
          year_month,
        });
        const { enable_datetime_list } = response;
        setCheckInDataList(enable_datetime_list);
      } else {
        setCheckInDataList([]);
        throw 'invalid parameter';
      }
    } catch (error) {
      console.error(error);
    }
  }, [m_plant_id, year_month]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { checkInDataList };
};
