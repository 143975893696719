import { f7, useStore } from 'framework7-react';
import { FC, useState } from 'react';
import { CarWash } from './CarWash/CarWash';
import { Dialog } from './CarWash/Dialog';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useFetchBanners } from '@/hooks/api/banner/useFetchBanners';
import { useFetchServices } from '@/hooks/api/service/useFetchServices';
import { useGmoRedirect } from '@/hooks/useGmoRedirect';
import { Prepaid } from '@/types/api/customerApi';
import { generatePrepaidName } from '@/utils/shop';

type CarWashPageProps = {
  status?: string;
};

export const CarWashPage: FC<CarWashPageProps> = ({ status }) => {
  const [showDialog, setShowDialog] = useState(false);
  useGmoRedirect(status);
  const prepaid = useStore(store, 'getFirstAdvancePaymentsInfo') as
    | Required<Prepaid>
    | undefined;

  const affiliate_id = store.state.customerInfo.m_affiliate_id;
  // const affiliate_id = 331;

  const { services } = useFetchServices({
    m_mt_service_category_id: 1,
    m_affiliate_id: affiliate_id,
  });

  const { banners } = useFetchBanners({
    m_mt_service_category_id: 1,
    m_affiliate_id: affiliate_id,
  });

  const handleClickItem = (target: string) => {
    if (!prepaid) {
      localStorage.setItem('redirectCarWashAfterPayment', target);
      setShowDialog(true);
      return;
    }
    f7.view.main.router.navigate(target);
  };

  const handleNavigatePrepaid = () => {
    setShowDialog(false);
    localStorage.setItem('paymentRedirectPage', 'car_wash');
    f7.view.main.router.navigate(paths.prepaidSummary);
  };

  const closeDialog = () => {
    setShowDialog(false);
    localStorage.removeItem('redirectCarWashAfterPayment');
  };

  return (
    <>
      <CarWash
        status={status}
        handleClickItem={handleClickItem}
        services={services}
        banners={banners}
      />
      {showDialog && (
        <Dialog
          prepaidName={generatePrepaidName(prepaid?.name)}
          handleCancel={() => closeDialog()}
          handleOk={handleNavigatePrepaid}
        />
      )}
    </>
  );
};
