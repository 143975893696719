import { useMemo } from 'react';
import style from '../../util/calendar/calendarComponent.module.css';

interface CalendarStyleHookProps {
  calendarDate: Date;
  selectDate: Date;
  availableSize: number;
}
export const useCalendarStyle = ({
  calendarDate,
  selectDate,
  availableSize,
}: CalendarStyleHookProps) => {
  const current = useMemo(() => new Date(), []);
  const selectStyle = useMemo(() => {
    if (availableSize > 0) {
      const select = new Date(selectDate);
      select.setHours(0, 0, 0, 0);
      if (calendarDate.getTime() < current.getTime()) {
        return `${style.dateItem} ${style.invalid}`;
      } else if (calendarDate.getTime() === select.getTime()) {
        return `${style.dateItem} ${style.select}`;
      } else {
        return `${style.dateItem}`;
      }
    } else {
      return `${style.dateItem} ${style.closed}`;
    }
  }, [current, calendarDate, selectDate, availableSize]);
  const reserveStyle = useMemo(() => {
    const period = 3;
    if (calendarDate.getTime() > current.getTime()) {
      if (availableSize <= 0) {
        return `${style.dateItemStatus} ${style.noAvailable}`;
      } else if (availableSize < period) {
        return `${style.dateItemStatus} ${style.fewAvailable}`;
      } else if (availableSize >= period) {
        return `${style.dateItemStatus} ${style.available}`;
      } else {
        return `${style.dateItemStatus} ${style.closed}`;
      }
    } else {
      return `${style.dateItemStatus} ${style.closed}`;
    }
  }, [current, calendarDate, availableSize]);
  return { selectStyle, reserveStyle };
};
