import { FilledButton } from '../button';

interface RepairItemAmountFieldProps {
  totalAmount: string;
  onClick: () => void;
}
export const RepairItemAmountField = ({
  totalAmount,
  onClick,
}: RepairItemAmountFieldProps) => {
  const handleClickRepairConfirm = () => onClick();
  return (
    <div className={'button-field repair-select'}>
      <div className={'amount-field'}>
        <div className={'amount-label'}>
          <span>{'総額'}</span>
        </div>
        <div className={'total-amount'}>
          <span className={'amount'}>{totalAmount}</span>
          <span className={'unit'}>{'円'}</span>
          <span className={'tax'}>{'(税込)'}</span>
        </div>
      </div>
      <FilledButton
        onClick={handleClickRepairConfirm}
        label={'この内容で修理を依頼する'}
      />
    </div>
  );
};
