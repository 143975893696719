import { f7 } from 'framework7-react';
import { FC, useState, createRef, RefObject, useRef } from 'react';
import style from './bannerListStyle.module.css';
import { paths } from '@/config/paths';
import { getBannerImageSrc } from '@/hooks/api/banner/useFetchBanners';
import { Banner } from '@/types/api/bannerApi';

interface BannerListProps {
  banners?: Banner[];
}
export const BannerList: FC<BannerListProps> = ({ banners }) => {
  // banners =
  //   (banners ?? []).length > 0
  //     ? banners
  //     : [
  //         { url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //       ];

  const [selectedBanner, setSelectedBanner] = useState(0);

  console.log(banners);

  const selectBannerRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  banners?.forEach((_, index) => {
    selectBannerRefs.current[index] = createRef<HTMLDivElement>();
  });

  const handleClickBanner = (path_type: number, path: string) => {
    if (path_type === 1) {
      f7.views.main.router.navigate(path);
    } else if (path_type === 2) {
      f7.views.main.router.navigate(paths.bannerPopupPage, {
        props: {
          path: path,
        },
      });
    }
  };

  const handleSelectBanner = (index: number) => {
    setSelectedBanner(index);
    selectBannerRefs?.current?.[index]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  return (
    <div className={style[`benner-section-wrapper`]}>
      <div className={style['banner-list-wrapper']}>
        {banners?.map(({ path_type, url, image_file_path }, index) => (
          <div
            className={style[`banner-wrapper`]}
            key={index}
            onClick={async () =>
              path_type && url && handleClickBanner(path_type, url)
            }
            ref={selectBannerRefs?.current[index]}
          >
            <div className={style['banner-image-wrapper']}>
              <img src={image_file_path} />
            </div>
          </div>
        ))}
      </div>
      <div className={style[`banner-select-button-wrapper`]}>
        {selectBannerRefs?.current?.map((_, index) => (
          <div
            key={index}
            className={`${style['banner-select-button']} ${
              index == selectedBanner ? style['selected'] : ''
            }`}
            onClick={() => handleSelectBanner(index)}
          />
        ))}
      </div>
    </div>
  );
};
