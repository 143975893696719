import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchServiceCategories } from '@/api/serviceApi';
import {
  FetchServiceCategoriesParams,
  FetchServiceCategoriesResponse,
} from '@/types/api/serviceApi';

export const useFetchServiceCategories = ({
  m_affiliate_id,
}: FetchServiceCategoriesParams) => {
  const [data, setData] = useState<FetchServiceCategoriesResponse['data']>();
  const [categories, setCategories] =
    useState<
      FetchServiceCategoriesResponse['data']['m_mt_service_categories']
    >();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();

    const fn = async () => {
      try {
        const res = await fetchServiceCategories({ m_affiliate_id });
        const data = res.data;

        const serviceCategories = data.m_mt_service_categories;

        setData(data);
        setCategories(serviceCategories);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };

    fn();
  }, [m_affiliate_id]);

  return {
    data,
    error,
    categories,
  };
};
