import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { CalendarDateEntryBody } from '../calendar/calendarDateEntryBody';
import { CalendarDateEntryHeader } from '../calendar/calendarDateEntryHeader';
import { CalendarDateEntryNote } from '../calendar/calendarDateEntryNote';
import { CalendarTimeEntryField } from '../calendar/calendarTimeEntryField';
import { EntryField } from '../common/entryField';
import style from './repairCheckInStyle.module.css';

interface EntryCalendarFieldProps {
  select: Date;
  setSelect: Dispatch<SetStateAction<Date>>;
  itemList: { [date_block: string]: Date[] }[];
  selectMonth: Date;
  onNext: () => void;
  onPrev: () => void;
}
export const EntryCalendarField = ({
  select,
  setSelect,
  itemList,
  selectMonth,
  onNext,
  onPrev,
}: EntryCalendarFieldProps) => {
  const handleClickNextMonth = useCallback(() => onNext(), [onNext]);
  const handleClickPrevMonth = useCallback(() => onPrev(), [onPrev]);
  const offset = 1;
  const timeListKey = useMemo(() => {
    const selectDate = new Date(select);
    return `${selectDate.getFullYear()}-${
      selectDate.getFullYear() + offset
    }-${selectDate.getFullYear()}`;
  }, [select]);

  const dateList = useMemo(
    () =>
      Array.isArray(itemList)
        ? itemList.reduce((list, item) => {
            const [[keyDate, timeList]] = Object.entries(item);
            const date = new Date(keyDate);
            if (date instanceof Date && !isNaN(date.getTime())) {
              list.push({ [keyDate]: timeList.length });
            }
            return list;
          }, [] as { [date_block: string]: number }[])
        : [],
    [itemList],
  );

  const timeList = useMemo(() => {
    const findItem =
      Array.isArray(itemList) && itemList.find((item) => timeListKey in item);
    return findItem ? findItem[timeListKey] : [];
  }, [itemList, timeListKey]);

  return (
    <div className={style.checkInCalendarField}>
      <EntryField label={'ご希望の入庫日を選択してください'} required>
        <div className={style.calendarDateField}>
          <CalendarDateEntryHeader
            selectMonth={selectMonth}
            onNext={handleClickNextMonth}
            onPrev={handleClickPrevMonth}
          />
          <CalendarDateEntryBody
            selectDate={select}
            setSelectDate={setSelect}
            monthDate={selectMonth}
            dateInfoList={dateList}
          />
          <CalendarDateEntryNote />
        </div>
      </EntryField>
      <EntryField label={'ご希望の時間を選択してください'} required>
        <CalendarTimeEntryField
          timeList={timeList}
          selectDate={select}
          setSelectDate={setSelect}
        />
      </EntryField>
    </div>
  );
};
