import { FilledButton } from '../button';
import style from './repairCheckInStyle.module.css';

interface NextButtonFieldProps {
  disable: boolean;
  onClick: () => void;
}
export const NextButtonField = ({ disable, onClick }: NextButtonFieldProps) => {
  const handleClickNext = () => onClick();
  return (
    <div className={style.nextButtonField}>
      <FilledButton
        onClick={handleClickNext}
        label={'次へ'}
        disabled={disable}
      />
    </div>
  );
};
