import { f7 } from 'framework7-react';
import { useState } from 'react';
import {
  useCheckInReserveDataContext,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import { useRepairProgressDataContext } from '../context/progressDetailContext';
import { usePostRepairCheckInReserve } from '../hook/api/usePostRepairCheckInReserve';
import { useCheckInReserveConfirmInfo } from '../hook/util/repairCheckIn/useCheckInReserveConfirmInfo';
import { useCheckInReserveRequest } from '../hook/util/repairCheckIn/useCheckInReserveRequest';
import { CheckInReserveDataType } from '../type/bodyAndPaint';
import { CheckBoxItem } from '../util/checkBox';
import { EntryField } from '../util/common/entryField';
import { DialogComponent } from '../util/dialogFrame';
import { CheckInConfirmInfoCard } from '../util/repairCheckIn/checkInConfirmCard';
import { CheckInReserveDialogCard } from '../util/repairCheckIn/checkInReserveDialogCard';
import { ReserveButtonField } from '../util/repairCheckIn/reserveButtonField';
import { paths } from '@/config/paths';

export const CheckInReserveConfirmReserve = () => {
  const { data: bpData } = useRepairProgressDataContext();
  const { setState } = useCheckInReserveStateContext();
  const { data } = useCheckInReserveDataContext();

  const {
    confirmCheckInReserveList,
    confirmCarConditionList,
    confirmLoanerCarList,
    confirmCheckInList,
  } = useCheckInReserveConfirmInfo({
    info: data ?? ({} as CheckInReserveDataType),
  });

  const { checkInReserveParams } = useCheckInReserveRequest({
    customerID: bpData?.ids?.customerID ?? 0,
    affiliateID: bpData?.ids?.affiliateID ?? 0,
    unitID: bpData?.ids?.unitID ?? 0,
    stockCarID: bpData?.ids?.stockCarID ?? 0,
    info: data ?? ({} as CheckInReserveDataType),
  });
  const { postRequest } = usePostRepairCheckInReserve({
    info: checkInReserveParams,
  });

  const [approveCheck, setApproveCheck] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickBack = () => {
    setState('CHECK_IN_DATE');
    // setState('USE_LOANER_CAR');
  };
  const handleClickEditCondition = () => {
    setState('CAR_CONDITION');
  };
  const handleClickEditCheckInDate = () => {
    setState('CHECK_IN_DATE');
  };
  const handleClickEditLoanerCar = () => {
    setState('USE_LOANER_CAR');
  };
  const handleClickReserve = () => {
    postRequest().then(() => setOpenDialog(true));
  };
  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    f7.views.main.router.navigate(paths.maintenance);
  };

  const ApproveReserveField = () => {
    const noteText = [
      '緊急入庫対応等の工場稼働状況により、ご予約日当日にご入庫日の変更をご依頼する可能性もございます。',
      'お車をお預かりする際に車内の車検証などを確認させていただく場合がございます。',
    ];
    return (
      <div className={'loaner-car-approve'}>
        <div className={'check-in-reserve-approve-message'}>
          <ol type={'1'}>
            {noteText.map((text, idx) => (
              <li key={idx}>
                <span key={idx}>{text}</span>
              </li>
            ))}
          </ol>{' '}
        </div>
        <div className={'check-in-reserve-approve-check'}>
          <CheckBoxItem
            name={'loaner-car-approve-check'}
            onChange={() => setApproveCheck(!approveCheck)}
            check={approveCheck}
            label={'内容を確認し、承諾する'}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <CheckInConfirmInfoCard info={confirmCheckInReserveList} />
      <CheckInConfirmInfoCard
        info={confirmCarConditionList}
        header={{ label: 'お車の状態', onEdit: handleClickEditCondition }}
      />
      <CheckInConfirmInfoCard
        info={confirmLoanerCarList}
        header={{ label: '代車の利用', onEdit: handleClickEditLoanerCar }}
      />
      <CheckInConfirmInfoCard
        info={confirmCheckInList}
        header={{ label: '入庫日', onEdit: handleClickEditCheckInDate }}
      />
      <EntryField label={'確認事項'} required={true}>
        <ApproveReserveField />
      </EntryField>
      <ReserveButtonField
        disable={!approveCheck}
        onClick={handleClickReserve}
      />
      <DialogComponent open={openDialog}>
        <CheckInReserveDialogCard onClick={handleClickCloseDialog} />
      </DialogComponent>
    </>
  );
};
