import { FactoryDataInfoType } from '@/types/bodyAndPaint';

interface FactoryInfoCard {
  info: FactoryDataInfoType;
}

export const FactoryInfoCard = ({ info }: FactoryInfoCard) => {
  const { factoryName, telephone, address } = info;
  const handleClickMapIcon = () => telephone && {};
  const handleClickTelephoneIcon = () => address && {};
  return (
    <div className={'factory-info-card'}>
      <div className={'factory-info-name-field'}>
        <div className={'factory-name-label'}>
          <span>{'入庫工場'}</span>
        </div>
        <div className={'factory-name-info'}>
          <span>{factoryName}</span>
        </div>
      </div>
      <div className={'factory-info-button-field'}>
        {address && (
          <div className={'factory-icon-button'} onClick={handleClickMapIcon}>
            <div className={'factory-icon map'}>
              <i className={'icon-factory-map'} />
            </div>
          </div>
        )}
        {telephone && (
          <div
            className={'factory-icon-button'}
            onClick={handleClickTelephoneIcon}
          >
            <div className={'factory-icon tel'}>
              <i className={'icon-factory-telephone'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
