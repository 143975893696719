import React, { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './PartialsStyle.module.css';
import {
  TEL1_MAX_LENGTH,
  TEL2_MAX_LENGTH,
  TEL3_MAX_LENGTH,
} from '@/hooks/api/carWash/useCarWashConditionerStep2';

const TEL1_INDEX = 0;
const TEL2_INDEX = 1;
const TEL3_INDEX = 2;

type PhoneInputProps = {
  type: 'phone_1' | 'phone_2';
  title: string;
  error: string;
  required?: boolean;
};

type Step2FormData = {
  tel1?: string;
  tel2?: string;
  tel3?: string;
  tel4?: string;
  tel5?: string;
  tel6?: string;
};

export const PhoneInput: FC<PhoneInputProps> = ({
  type,
  title,
  error,
  required = false,
}) => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<Step2FormData>();

  let tel1: string | undefined,
    tel2: string | undefined,
    tel3: string | undefined;
  if (type === 'phone_1') {
    tel1 = watch('tel1');
    tel2 = watch('tel2');
    tel3 = watch('tel3');
  } else {
    tel1 = watch('tel4');
    tel2 = watch('tel5');
    tel3 = watch('tel6');
  }

  useEffect(() => {
    if (type === 'phone_1' && tel1 && tel2 && tel3) {
      trigger('tel1');
      trigger('tel2');
      trigger('tel3');
    } else {
      trigger('tel4');
      trigger('tel5');
      trigger('tel6');
    }
  }, [tel1, tel2, tel3, trigger, type]);

  return (
    <div className={`${style['input-group']}`}>
      <div
        className={`${style['input-text']} ${
          !!error ? style['input-text-error'] : ''
        }`}
      >
        <label>
          {title}
          <span
            className={`${style['input-label-tips']} ${
              required && style['required']
            }`}
          >
            {required ? '必須' : '任意'}
          </span>
        </label>
        <div className={style['input-group-1']}>
          <input
            ref={inputRefs[TEL1_INDEX]}
            placeholder="000"
            type="number"
            maxLength={3}
            value={tel1}
            onChange={(e) => {
              if (e.target.value.length <= TEL1_MAX_LENGTH) {
                if (type === 'phone_1') {
                  setValue('tel1', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                } else {
                  setValue('tel4', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }
              }
              if (e.target.value.length === TEL1_MAX_LENGTH) {
                inputRefs[TEL1_INDEX].current?.blur();
                inputRefs[TEL2_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL2_INDEX]}
            placeholder="1234"
            type="number"
            maxLength={4}
            value={tel2}
            onChange={(e) => {
              if (e.target.value.length <= TEL2_MAX_LENGTH) {
                if (type === 'phone_1') {
                  setValue('tel2', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                } else {
                  setValue('tel5', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }
              }
              if (e.target.value.length === TEL2_MAX_LENGTH) {
                inputRefs[TEL2_INDEX].current?.blur();
                inputRefs[TEL3_INDEX].current?.focus();
              }
            }}
          />
          -
          <input
            ref={inputRefs[TEL3_INDEX]}
            placeholder="5678"
            type="number"
            maxLength={4}
            value={tel3}
            onChange={(e) => {
              if (e.target.value.length <= TEL3_MAX_LENGTH) {
                if (type === 'phone_1') {
                  setValue('tel3', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                } else {
                  setValue('tel6', e.target.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }
              }

              if (e.target.value.length === TEL3_MAX_LENGTH) {
                inputRefs[TEL3_INDEX].current?.blur();
              }
            }}
          />
        </div>
        {!!error && (
          <p className={style['input-error-message']}>
            有効な電話番号が指定されていません
          </p>
        )}
      </div>
    </div>
  );
};
