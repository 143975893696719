import { WEEK_LABEL } from '@/consts/bodyAndPaint';

export const useCalendarDateList = ({ monthInfo }: { monthInfo: Date }) => {
  const first = 1;
  const referenceDate = new Date(monthInfo);
  referenceDate.setDate(first);
  const startDayOfWeek = new Date(referenceDate).getDay();
  const calendarStart = new Date(
    referenceDate.getFullYear(),
    referenceDate.getMonth(),
    referenceDate.getDate() - startDayOfWeek,
  );

  const weekLabel = Object.keys(WEEK_LABEL);
  const length = 42;
  const CalendarItems = Array.from({ length }, (_, idx) => {
    const date = new Date(calendarStart);
    date.setDate(calendarStart.getDate() + idx);
    return date;
  })
    .reduce((week, date, idx) => {
      if (idx % weekLabel.length === 0) {
        week.push([]);
      }
      const offset = 1;
      week[week.length - offset].push(date);
      return week;
    }, [] as Date[][])
    .filter((week) =>
      week.some((date) => date.getMonth() === referenceDate.getMonth()),
    )
    .map((week) =>
      week.map((date) => {
        return new Date(date.setHours(0, 0, 0, 0));
      }),
    );
  return CalendarItems;
};
