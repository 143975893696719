import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useState } from 'react';
import { generateCustomerInviteCode } from '@/api/registerQRApi';
import { store } from '@/config/store';

export const useGenerateInviteCode = () => {
  const [error, setError] = useState<AxiosError>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const generateInviteCode = async () => {
    try {
      f7.preloader.show();
      setIsLoading(true);

      const res = await generateCustomerInviteCode({
        m_customer_id: store.state.authInfo.m_customer_id,
      });

      if (res?.data?.success) {
        setCode(res?.data?.invite_code);
      } else {
        setErrorMessage(res?.data.error?.message || '');
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
      f7.preloader.hide();
    }
  };

  return {
    generateInviteCode,
    error,
    errorMessage,
    code,
    isLoading,
  };
};
