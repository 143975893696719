import { RepairItemType } from '../../type/bodyAndPaint';
import { EntryField } from '../common/entryField';
import { RepairItemSelectCard } from './repairItemSelectCard';

interface RepairItemSelectFieldProps {
  list: RepairItemType[];
  onChange: ({
    index,
    newSelect,
  }: {
    index: number;
    newSelect: number;
  }) => void;
}
export const RepairItemSelectField = ({
  list,
  onChange,
}: RepairItemSelectFieldProps) => {
  return (
    <EntryField label={'整備する項目を選択してください'} required={false}>
      <div className={'repair-item-select-list'}>
        {list.map((infoItem, index) => (
          <RepairItemSelectCard
            info={infoItem}
            onChange={(newSelect: number) => onChange({ index, newSelect })}
            key={index}
          />
        ))}
      </div>
    </EntryField>
  );
};
