import { DamageMapInfoType } from '@/types/bodyAndPaint';

interface DamagePartTextHookProps {
  damagePart: DamageMapInfoType;
}
export const useDamagePartText = ({ damagePart }: DamagePartTextHookProps) => {
  // TODO: Move to Const
  const DAMAGE_PART: Record<keyof DamageMapInfoType, string> = {
    front_side_left: '前方部分 左側の破損',
    front_side_center: '前方部分 中央の破損',
    front_side_right: '前方部分 右側の破損',
    front_door_left: '前ドア 左側の破損',
    front_door_right: '前ドア 右側の破損',
    rear_door_left: '後ドア 左側の破損',
    rear_door_right: '後ドア 右側の破損',
    rear_side_left: '後方部分 左側の破損',
    rear_side_center: '後方部分 中央の破損',
    rear_side_right: '後方部分 右側の破損',
  };
  const damagePartText = Object.entries(damagePart)
    .reduce((list, [key, value]) => {
      const keyName = key as keyof DamageMapInfoType;
      if (value) {
        list.push(DAMAGE_PART[keyName]);
      }
      return list;
    }, [] as string[])
    .join(' ');
  return damagePartText;
};
