import { f7, useStore } from 'framework7-react';
import { FC } from 'react';
import { RegisterNewMemberStep2 } from './RegisterNewMemberStep2/RegisterNewMemberStep2';
import { RegisterNewMemberStep3 } from './RegisterNewMemberStep3/RegisterNewMemberStep3';
import { RegisterNewMemberStep4 } from './RegisterNewMemberStep4/RegisterNewMemberStep4';
import style from './RegisterNewMemberStyle.module.css';
import { RegisterNewStaffStep1 } from './RegisterNewStaffStep1/RegisterNewStaffStep1';
import { RegisterQRPageTemplate } from '@/components/uiParts/Template/RegisterQRPageTemplate/RegisterQRPageTemplate';
import { RegisterUserDataType } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { affiliateId } from '@/consts/shop';
import { storeDispatch } from '@/utils/store';

export const RegisterNewStaff: FC = () => {
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;
  const { currentStep, step1 } = registerUserData;

  const handleBack = () => {
    if (currentStep > 1) {
      storeDispatch('setRegisterUser', 'registerUser', {
        ...registerUserData,
        currentStep: currentStep - 1,
      });
    } else {
      f7.view.main.router.navigate(
        `${paths.registerQR}?affiliate_id=${
          registerUserData.affiliate_id || affiliateId.DEFAULT
        }`,
        {
          transition: 'push-reverse',
        },
      );
    }
  };

  return (
    <RegisterQRPageTemplate
      title="新規会員登録"
      pageName="RegisterNewMember"
      showNavBack={true}
      handleClickBack={handleBack}
    >
      <div
        className={`${style['page']} ${
          currentStep === 1 ? style['white-page'] : ''
        }`}
      >
        {[1, 2].includes(currentStep) && (
          <div className={style['content']}>
            {currentStep === 1 && <RegisterNewStaffStep1 />}

            {currentStep === 2 && <RegisterNewMemberStep2 />}
          </div>
        )}

        {currentStep === 3 && (
          <RegisterNewMemberStep4
            hanldeCancel={() => {
              f7.view.main.router.navigate(paths.loginTutorial);
            }}
          />
        )}
      </div>
    </RegisterQRPageTemplate>
  );
};
