import { AccordionContent, List, ListItem } from 'framework7-react';
import { ReactNode } from 'react';
import './f7ComponentStyle.css';

export const AccordionField = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className={'accordion-field'}>
      <List accordionList noHairlines>
        <ListItem accordionItem title={title}>
          <AccordionContent>
            <List noHairlinesBetween>{children}</List>
          </AccordionContent>
        </ListItem>
      </List>
    </div>
  );
};
