import { useState, useMemo, useEffect } from 'react';
import { InsuranceInfoType } from '../../../type/bodyAndPaint';

interface InsuranceInfoHookProps {
  info?: InsuranceInfoType;
}
export const useInsuranceInfo = ({ info }: InsuranceInfoHookProps) => {
  const [insurance, setInsurance] = useState(info ?? ({} as InsuranceInfoType));
  const [disable, setDisable] = useState(false);
  const {
    use_insurance,
    company_name,
    certificate_number,
    has_deductible,
    repair_plan,
  } = insurance ?? ({} as InsuranceInfoType);

  const insuranceCheck = useMemo(
    () => use_insurance !== undefined && use_insurance >= 0,
    [use_insurance],
  );
  const repairPlan = useMemo(
    () => repair_plan !== undefined && repair_plan >= 0,
    [repair_plan],
  );
  const hasDeductible = useMemo(
    () => has_deductible !== undefined && has_deductible >= 0,
    [has_deductible],
  );
  const isInsurance = useMemo(
    () => use_insurance !== undefined && use_insurance === 0,
    [use_insurance],
  );

  useEffect(() => {
    if (!isInsurance) {
      setInsurance({
        use_insurance: use_insurance,
        company_id: 0,
        company_name: '',
        certificate_number: '',
        receipt_number: '',
        has_deductible: -1,
        deductible_amount: undefined,
        repair_plan: -1,
      } as InsuranceInfoType);
    }
  }, [isInsurance, use_insurance]);
  useEffect(() => {
    const check = isInsurance
      ? !!(
          insuranceCheck &&
          hasDeductible &&
          repairPlan &&
          company_name !== '' &&
          company_name !== undefined &&
          certificate_number !== '' &&
          certificate_number !== undefined
        )
      : true;
    setDisable(!check);
  }, [
    insurance,
    certificate_number,
    company_name,
    hasDeductible,
    insuranceCheck,
    isInsurance,
    repairPlan,
  ]);

  return [insurance, setInsurance, disable] as const;
};
