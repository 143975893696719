import {
  useRepairProgressDataContext,
  useRepairProgressStateContext,
} from '../context/progressDetailContext';
import { RepairProgressDataType } from '../type/bodyAndPaint';
import { RepairPartImageCard } from '../util/repairRequest/repairPartImageCard';

export const ProgressRepairImageCheck = () => {
  const { setState } = useRepairProgressStateContext();
  const { data } = useRepairProgressDataContext();
  const { repair_progress_info } = data ?? ({} as RepairProgressDataType);

  const handleClickBack = () => {
    setState('REPAIR_PROGRESS');
  };

  return (
    <>
      <div className={'repair-service-request'}>
        {(repair_progress_info ?? []).map((imageInfo, idx) => (
          <RepairPartImageCard info={imageInfo} key={idx} />
        ))}
      </div>
    </>
  );
};
