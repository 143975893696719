import { baseApi } from '@/config/axios';

import {
  FetchServicesParams,
  FetchServicesResponse,
  FetchServiceCategoriesParams,
  FetchServiceCategoriesResponse,
} from '@/types/api/serviceApi';

export const fetchServices = (
  params: FetchServicesParams,
): Promise<FetchServicesResponse> =>
  baseApi.get(`v1/customer/service/info`, { params });

export const fetchServiceCategories = (
  params: FetchServiceCategoriesParams,
): Promise<FetchServiceCategoriesResponse> =>
  baseApi.get(`v1/customer/category/info`, { params });
