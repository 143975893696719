import { useEffect, useMemo, useState } from 'react';
import { CarConditionInfoType } from '../../../type/bodyAndPaint';

interface CarConditionInfoHookProps {
  info: CarConditionInfoType;
}
export const useCarConditionInfo = ({ info }: CarConditionInfoHookProps) => {
  const [carCondition, setCarCondition] = useState(info);
  const [disable, setDisable] = useState(false);
  const {
    car_driven,
    damage_map,
    damage_degree,
    other_party,
    notice_date,
    description,
  } = carCondition ?? ({} as CarConditionInfoType);

  const carDrivenCheck = useMemo(
    () => car_driven !== undefined && car_driven >= 0,
    [car_driven],
  );
  const damageDegreeCheck = useMemo(
    () => damage_degree !== undefined && damage_degree >= 0,
    [damage_degree],
  );
  const otherPartyCheck = useMemo(
    () => other_party !== undefined && other_party >= 0,
    [other_party],
  );
  const damageMapCheck = useMemo(
    () => damage_map && Object.values(damage_map).some((damage) => damage),
    [damage_map],
  );
  useEffect(() => {
    const check = !!(
      carDrivenCheck &&
      damageDegreeCheck &&
      otherPartyCheck &&
      damageMapCheck &&
      notice_date instanceof Date &&
      !isNaN(notice_date.getTime()) &&
      description !== undefined &&
      description !== ''
    );
    setDisable(!check);
  }, [
    carCondition,
    carDrivenCheck,
    damageDegreeCheck,
    damageMapCheck,
    description,
    notice_date,
    otherPartyCheck,
  ]);
  return [carCondition, setCarCondition, disable] as const;
};
