import { f7, useStore } from 'framework7-react';
import { FC } from 'react';
import style from './RegisterNewMemberConfirmStyle.module.css';
import { postInspectionCertifications } from '@/api/registerQRApi';
import { Button } from '@/components/uiParts/Button';
import { RegisterQRPageTemplate } from '@/components/uiParts/Template/RegisterQRPageTemplate/RegisterQRPageTemplate';
import { isApp } from '@/config/device';
import { RegisterUserDataType } from '@/config/initialState';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useToast } from '@/hooks/useToast';
import { convertSlashToHyphen, hyphenDate } from '@/utils/date';
import { storeDispatch } from '@/utils/store';

export const RegisterNewMemberConfirm: FC = () => {
  const registerUserData = useStore(
    store,
    'getRegisterUser',
  ) as RegisterUserDataType;
  const { isCorporation, scan } = registerUserData;
  const { openToast } = useToast();

  const vehicleNo = [
    scan.transport_bu_name,
    scan.class_no,
    scan.hiragana,
    scan.hiragana && scan.registry_no && '・',
    scan.registry_no,
  ]
    .filter(Boolean)
    .join(' ');

  window.onInspectionCertificateScan = (data) => {
    try {
      const parsed: {
        transport_bu_name: string;
        class_no: string;
        hiragana: string;
        registry_no: string;
        registry_no_erased?: number;
        businesswork_flg?: number;
        inspection_certificate_file: string | File | Blob; // Base64 image if necessary
        vin: string;
        motor_model?: string;
        katasiki_num?: number;
        ruibetsu_num?: number;
        inspection_limit: string; // YYYY/MM/DD
        model_code: string;
        first_registration: string; // YYYY-MM-DD
      } = JSON.parse(data);
      storeDispatch('setRegisterUser', 'registerUser', {
        ...registerUserData,
        scan: {
          ...parsed,
          next_inspection_date: parsed.inspection_limit,
          registration_date: parsed.first_registration,
        },
      });
      f7.view.main.router.navigate(paths.registerNewMemberConfirm);
    } catch {}
  };

  window.onInspectionCertificateCapture = (imageBase64) => {
    storeDispatch('setRegisterUser', 'registerUser', {
      ...registerUserData,
      scan: {
        ...registerUserData.scan,
        inspection_certificate_file: imageBase64,
      },
    });
  };

  const openInspectionCertificateScan = (isReScan: boolean) => {
    if (isApp) {
      if (isReScan) {
        window.location.href = '/inspection_certificate_reader';
      } else {
        window.location.href = '/inspection_certificate_capture';
      }
    }
  };

  const handleSubmit = async () => {
    f7.preloader.show();
    try {
      const certificateParams = new FormData();
      certificateParams.append('m_customer_id', registerUserData.m_customer_id?.toString() || '');
      certificateParams.append(
        'm_affiliate_id',
        registerUserData.affiliate_id?.toString(),
      );
      certificateParams.append(
        'transport_bu_name',
        scan.transport_bu_name || '',
      );
      certificateParams.append('class_no', scan.class_no || '');
      certificateParams.append('hiragana', scan.hiragana || '');
      certificateParams.append('registry_no', scan.registry_no || '');
      certificateParams.append('registry_no_erased', '0');
      certificateParams.append('businesswork_flg', '0');
      // certificateParams.append('inspection_certificate_file', scan.inspection_certificate_file || '');
      certificateParams.append('vin', scan.vin || '');
      certificateParams.append('motor_model', scan.motor_model || '');
      certificateParams.append(
        'katasiki_num',
        scan.katasiki_num?.toString() || '',
      );
      certificateParams.append(
        'ruibetsu_num',
        scan.ruibetsu_num?.toString() || '',
      );
      certificateParams.append(
        'next_inspection_date',
        convertSlashToHyphen(scan.next_inspection_date),
      );
      certificateParams.append('model_code', scan.model_code || '');
      certificateParams.append(
        'registration_date',
        scan.registration_date || '',
      );

      const certResponse = await postInspectionCertifications(
        certificateParams,
      );

      if (!certResponse?.data?.success) {
        throw new Error(certResponse?.data?.error?.message);
      }

      openToast('車検証登録が完了しました。', 'toast-success');

      f7.view.main.router.navigate(paths.loginTutorial);
    } catch (e: any) {
      f7.dialog.alert(e?.message || 'エラーが発生しました。', 'エラー');
    }
    f7.preloader.hide();
  };

  return (
    <RegisterQRPageTemplate
      pageName="RegisterConfirm"
      title="入力内容の確認"
      showNavBack
      confirmPage
      handleClickBack={() => f7.view.main.router.back()}
    >
      <div className={style['page']}>
        <div className={`${style['block']}`}>
          <div className={style['block-header']}>
            <div className={style['flex-center']}>
              <div className={style['title']}>車両情報</div>
              <div
                className={style['edit-btn']}
                onClick={() => openInspectionCertificateScan(true)}
              >
                編集
              </div>
            </div>
            {!scan.inspection_certificate_file && (
              <p className={style['section-description']}>
                ※ 車両情報は後からでも登録できます
              </p>
            )}

            <div
              className={`${style['content-line']} ${style['border-bottom-normal']}`}
            >
              <p className={style['text-label']}>車検証の写真</p>
              <div className={style['inspection-image']}>
                {scan.inspection_certificate_file && (
                  <img
                    src={scan.inspection_certificate_file}
                    style={{
                      borderRadius: 8,
                    }}
                  />
                )}
                <Button
                  className={style['reshoot-btn']}
                  onClick={() => openInspectionCertificateScan(!vehicleNo)}
                >
                  {scan.inspection_certificate_file
                    ? '再撮影'
                    : '車検証を撮影する'}
                </Button>
              </div>
            </div>
          </div>

          <div className={`${style['block-header']} ${style['vehicle-cert']}`}>
            <div className={style['title']}>車検証情報</div>
            {vehicleNo && (
              <div className={style['vehicle-cert-info']}>
                <p> {`${scan.transport_bu_name || ''} ${scan.class_no}`}</p>
                <p className={style['vehicle-cert-info__name']}>
                  {scan.hiragana}・
                  <span className={style['bold']}>{scan.registry_no}</span>
                </p>
              </div>
            )}
          </div>
          <div className={style['divide']}>
            <div className={style['content-line-car-info']}>
              <p className={style['text-label-medium']}>車両ナンバー：</p>
              <p className={style['text']}>{vehicleNo || '-'}</p>
            </div>
            <div className={style['content-line-car-info']}>
              <p className={style['text-label-medium']}>登録年月日：</p>
              <p className={style['text']}>
                {scan.registration_date
                  ? hyphenDate(scan.registration_date)
                  : '-'}
              </p>
            </div>
            <div className={style['content-line-car-info']}>
              <p className={style['text-label-medium']}>車検満了日：</p>
              <p className={style['text']}>
                {scan.next_inspection_date
                  ? hyphenDate(scan.next_inspection_date)
                  : '-'}
              </p>
            </div>
            <div className={style['content-line-car-info']}>
              <p className={style['text-label-medium']}>車台番号：</p>
              <p className={style['text']}>{scan.vin || '-'}</p>
            </div>
            <div className={style['content-line-car-info']}>
              <p className={style['text-label-medium']}>型式：</p>
              <p className={style['text']}>{scan.model_code || '-'}</p>
            </div>
          </div>
        </div>
        <Button onClick={handleSubmit}>車検証登録を完了</Button>
      </div>
    </RegisterQRPageTemplate>
  );
};
