import { f7 } from 'framework7-react';
import { useMemo } from 'react';
import { ITEM_REPAIR, REPAIR_PLAN } from '../const/bodyAndPaint';
import {
  useRepairRequestDataContext,
  useRepairRequestStateContext,
} from '../context/repairRequestContext';
import { useRepairItemSelect } from '../hook/util/repairRequest/useRepairItemSelect';
import {
  RepairRequestDataType,
  RepairRequestSelectItemType,
} from '../type/bodyAndPaint';
import { RepairItemAmountField } from '../util/repairRequest/repairItemAmountField';
import { RepairItemHeaderField } from '../util/repairRequest/repairItemHeaderField';
import { RepairItemSelectField } from '../util/repairRequest/repairItemSelectField';
import { paths } from '@/config/paths';

export const RepairRequestItemSelect = () => {
  const { setState } = useRepairRequestStateContext();
  const { data, setData } = useRepairRequestDataContext();
  const { estimate_result_info, repair_item_select_info } =
    data ?? ({} as RepairRequestDataType);
  const insurance = useMemo(
    () => estimate_result_info.insurance_apply_result,
    [estimate_result_info],
  );
  const repairItemSelectInfo = useMemo(
    () => repair_item_select_info,
    [repair_item_select_info],
  );

  const [repairItemList, totalAmount, changeRepairItemSelect] =
    useRepairItemSelect({
      initialData: repairItemSelectInfo,
    });

  const handleClickBack = () => {
    f7.views.main.router.navigate(paths.maintenance);
  };
  const handleClickCheckImage = () => {
    setState('ESTIMATE_IMAGE');
  };
  const handleClickRepairConfirm = () => {
    setData((prev) => ({
      ...prev,
      repair_item_confirm_info: {
        ...prev.repair_item_confirm_info,
        repair_request_select_info_list: repairItemList.map((item) => {
          const selectKey = Object.keys(ITEM_REPAIR).map(
            (item) => item as keyof typeof ITEM_REPAIR,
          );
          const repairItem: RepairRequestSelectItemType = {
            repair_item_id: item.id,
            repair_select: item.repair_select ?? 0,
            repair_item_name: item.repair_item_name,
            insurance_applied:
              item.repair_select !== undefined
                ? ITEM_REPAIR[selectKey[item.repair_select]].label
                : '',
          };
          return repairItem;
        }),
      },
    }));
    setState('REPAIR_CONFIRM');
  };

  return (
    <>
      <RepairItemHeaderField
        onCheckImage={handleClickCheckImage}
        insurance={insurance}
      />
      <RepairItemSelectField
        list={repairItemList}
        onChange={changeRepairItemSelect}
      />
      <RepairItemAmountField
        totalAmount={totalAmount.toLocaleString('ja-JP')}
        onClick={handleClickRepairConfirm}
      />
    </>
  );
};
