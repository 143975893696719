import { AxiosError } from 'axios';
import { f7 } from 'framework7-react';
import { useEffect, useState } from 'react';
import { fetchServices } from '@/api/serviceApi';
import {
  FetchServicesParams,
  FetchServicesResponse,
} from '@/types/api/serviceApi';

export const useFetchServices = ({
  m_mt_service_category_id,
  m_affiliate_id,
}: FetchServicesParams) => {
  const [data, setData] = useState<FetchServicesResponse['data']>();
  const [services, setServices] =
    useState<FetchServicesResponse['data']['m_mt_services']>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    f7.preloader.show();

    const fn = async () => {
      try {
        const res = await fetchServices({
          m_mt_service_category_id,
          m_affiliate_id,
        });
        const data = res.data;

        const services = data.m_mt_services;

        setData(data);
        setServices(services);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        f7.preloader.hide();
      }
    };

    fn();
  }, [m_affiliate_id, m_mt_service_category_id]);

  return {
    data,
    error,
    services,
  };
};
