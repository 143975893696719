import { useCallback, useEffect, useState } from 'react';
import { getDamagePartImageApi } from '@/api/bodyAndPaint';
import { DamagePartImageResponse } from '@/types/api/bodyAndPaint';

interface GetDamagePartImageDataHookProps {
  t_pm_car_inspection_id: number;
}
export const useGetDamagePartImageData = ({
  t_pm_car_inspection_id,
}: GetDamagePartImageDataHookProps) => {
  const [damagePartList, setDamagePartList] = useState<DamagePartImageResponse>(
    {} as DamagePartImageResponse,
  );

  const fetchDate = useCallback(async () => {
    try {
      if (t_pm_car_inspection_id > 0) {
        const fetch = await getDamagePartImageApi({
          t_pm_car_inspection_id,
        });
        setDamagePartList(fetch);
      } else {
        setDamagePartList({} as DamagePartImageResponse);
        throw 'invalid parameter';
      }
    } catch (error) {
      console.error(error);
    }
  }, [t_pm_car_inspection_id]);
  useEffect(() => {
    fetchDate();
  }, [fetchDate]);

  return { damagePartList: damagePartList.injury_place };
};
