import { ReactNode } from 'react';

interface DialogComponentProps {
  open: boolean;
  children: ReactNode;
}
export const DialogComponent = ({ open, children }: DialogComponentProps) => {
  const DialogField = () => {
    return (
      <div className={'dialog-field'}>
        <div className={'dialog-background'} />
        <div className={'dialog-content'}>{children}</div>
      </div>
    );
  };
  return open ? <DialogField /> : <></>;
};
