import { EstimateImageInfoType } from '../../type/bodyAndPaint';

interface RepairPartImageCardProps {
  info: EstimateImageInfoType;
}
export const RepairPartImageCard = ({ info }: RepairPartImageCardProps) => {
  const { damage_part, damage_desc, repair_item_list, image_url_list } = info;
  return (
    <div className={'repair-part-image-card'}>
      <div className={'repair-part-info'}>
        <div className={'repair-part'}>
          <div className={'info-label'}>
            <span>{'損傷箇所'}</span>
            <span>{':'}</span>
          </div>
          <div className={'info-item'}>
            <span>{damage_part}</span>
          </div>
        </div>
        <div className={'repair-desc'}>
          <div className={'info-label'}>
            <span>{'詳細'}</span>
            <span>{':'}</span>
          </div>
          <div className={'info-item'}>
            <span>{damage_desc}</span>
          </div>
        </div>
      </div>
      <div className={'repair-part-image-label'}>
        {repair_item_list.map((repairItem, idx) => (
          <div className={'image-label'} key={idx}>
            <span>{repairItem}</span>
          </div>
        ))}
      </div>
      <div className={'repair-part-image-items'}>
        {image_url_list.map((repairImage, idx) => (
          <div className={'image-card'} key={idx}>
            <img src={repairImage} />
          </div>
        ))}
      </div>
    </div>
  );
};
