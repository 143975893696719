import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import style from './PartialsStyle.module.css';
import { SelectCalendar } from './SelectCalendar';

interface Props {
  name: string;
  label: string;
  error: string;
  required?: boolean;
  defaultValue: string | null;
}

export const DateTimeSelect: FC<Props> = ({
  name,
  label,
  error,
  required = false,
  defaultValue = null,
}) => {
  const { setValue, trigger } = useFormContext();

  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (date) {
      setValue(name, format(date, 'yyyy-MM-dd'));
      trigger(name);
    }
  }, [date, name, setValue, trigger]);

  return (
    <div
      className={`${style['input-group']} ${
        !!error ? style['input-text-error'] : ''
      }`}
    >
      <div className={style['input-text']}>
        <label>{label}</label>
        <span
          className={`${style['input-label-tips']} ${
            required && style['required']
          }`}
        >
          {required ? '必須' : '任意'}
        </span>
        <SelectCalendar
          name={name}
          value={date}
          maxDate={new Date()}
          onChange={setDate}
        />
      </div>
      {!!error && <p className={style['input-error-message']}>{error}</p>}
    </div>
  );
};
