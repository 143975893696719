import { FilledButton } from '../button';

interface RepairProgressButtonProp {
  changeCheckInDate: () => void;
  cancelCheckInDate: () => void;
}
export const RepairProgressButton = ({
  changeCheckInDate,
  cancelCheckInDate,
}: RepairProgressButtonProp) => {
  return (
    <div className={'button-field repair-progress'}>
      <FilledButton
        onClick={changeCheckInDate}
        label={'入庫予定日を変更する'}
      />
      <FilledButton
        onClick={cancelCheckInDate}
        label={'予約を取り消す'}
        buttonStyle={'warning'}
      />
    </div>
  );
};
