import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import {
  findRelevantNextWarranty,
  isMultipleWarrantiesCheck,
  isWarrantiesDuplicatedCheck,
} from '../WarrantyManagement/WarrantyManagementHelper';
import { WarrantyDetail } from './WarrantyDetail/WarrantyDetail';
import {
  checkIfAllowToChangePlan,
  makeDisplayWarrantyDetail,
  makeDisplayWarrantyOptionDetail,
} from './WarrantyDetailHelper';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { isCancellationInProcess } from '@/utils/warranty';

interface WarrantyDetailPageProps {
  f7router: Router.Router;
}

export const WarrantyDetailPage: FC<WarrantyDetailPageProps> = ({
  f7router,
}) => {
  const {
    customerInfo,
    currentWarranty,
    nextWarranty,
    warranties,
    nextWarranties,
  } = store.state;
  const warranty = currentWarranty?.append_warranties?.[0];
  const warrantyNo = currentWarranty?.warranty_no ?? '';
  const isInCancellation = isCancellationInProcess(
    warranty?.apply_status,
    warranty?.warranty_status,
  );
  const isWarrantiesDuplicated = isWarrantiesDuplicatedCheck(warranties);
  const isWarrantyUnderContract = !findRelevantNextWarranty(
    currentWarranty,
    nextWarranties,
  );
  const isMultipleWarranties = isMultipleWarrantiesCheck(warranties);
  const isUpdatable = !!currentWarranty?.auto_continuous_flg;
  const displayWarrantyDetail = makeDisplayWarrantyDetail(
    customerInfo.affiliate_name,
    warranty,
  );
  const displayOptions = makeDisplayWarrantyOptionDetail(
    currentWarranty?.append_warranties
  );
  const isStarted = displayWarrantyDetail.isWarrantyStarted;
  const isAllowToChangePlan = checkIfAllowToChangePlan(
    isWarrantiesDuplicated,
    !isWarrantyUnderContract,
    warranty?.warranty_end_date,
  );
  const handleClickChangePlan = () => {
    f7.view.main.router.navigate(paths.updateWarranty(warrantyNo));
  };

  const handleClickCancel = () => {
    if (displayWarrantyDetail.cancelEnableTerm) {
      f7.view.main.router.navigate(paths.cancelWarranty);
    } else {
      f7.view.main.router.navigate(paths.warrantyCancelable(warrantyNo));
    }
  };

  return (
    <WarrantyDetail
      f7router={f7router}
      warrantyNo={warrantyNo}
      displayWarrantyDetail={displayWarrantyDetail}
      displayOptions={displayOptions}
      isAllowToChangePlan={isAllowToChangePlan}
      isWarrantyUnderContract={isWarrantyUnderContract}
      isInCancellation={isInCancellation}
      isUpdatable={isUpdatable}
      handleClickCancel={handleClickCancel}
      handleClickChangePlan={handleClickChangePlan}
    />
  );
};
