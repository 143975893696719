import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckInReserveDataType } from '../../../type/bodyAndPaint';
import { RepairCheckInReserveParams } from '@/types/api/bodyAndPaint';

interface CheckInReserveRequest {
  customerID: number;
  affiliateID: number;
  unitID: number;
  stockCarID: number;
  info: CheckInReserveDataType;
}
export const useCheckInReserveRequest = ({
  customerID,
  affiliateID,
  unitID,
  stockCarID,
  info,
}: CheckInReserveRequest) => {
  const {
    reserve_info,
    car_condition_info,
    insurance_info,
    loaner_car_info,
    check_in_date,
    plantID,
  } = info ?? ({} as CheckInReserveDataType);

  const reserveInfo = useMemo(() => {
    return {
      car_name: reserve_info?.car_name ?? '-',
      category: reserve_info?.category ?? '-',
      factory_name: reserve_info?.factory_name ?? '-',
      factory_map_address: reserve_info?.factory_map_address ?? '-',
      factory_phone_number: reserve_info?.factory_phone_number ?? '-',
    };
  }, [reserve_info]);

  const carConditionInfo = useMemo(() => {
    return {
      can_driven: car_condition_info?.car_driven ?? 0,
      damage_parts: {
        front_side_left:
          car_condition_info?.damage_map?.front_side_left ?? false,
        front_side_center:
          car_condition_info?.damage_map?.front_side_center ?? false,
        front_side_right:
          car_condition_info?.damage_map?.front_side_right ?? false,
        front_door_left:
          car_condition_info?.damage_map?.front_door_left ?? false,
        front_door_right:
          car_condition_info?.damage_map?.front_door_right ?? false,
        rear_door_left: car_condition_info?.damage_map?.rear_door_left ?? false,
        rear_door_right:
          car_condition_info?.damage_map?.rear_door_right ?? false,
        rear_side_left: car_condition_info?.damage_map?.rear_side_left ?? false,
        rear_side_center:
          car_condition_info?.damage_map?.rear_side_center ?? false,
        rear_side_right:
          car_condition_info?.damage_map?.rear_side_right ?? false,
      },
      damage_scale: car_condition_info?.damage_degree ?? 0,
      accident_interpersonal: !!car_condition_info?.other_party,
      damage_location_date: car_condition_info?.notice_date ?? 0,
      damage_detail: car_condition_info?.description ?? 0,
    };
  }, [car_condition_info]);

  const insuranceInfo = useMemo(() => {
    return insurance_info
      ? {
          insurance_use_flg: insurance_info?.use_insurance ?? '-',
          m_insurance_company_id: insurance_info?.company_id ?? '-',
          policy_number: insurance_info?.certificate_number ?? '-',
          has_deductible: !!insurance_info?.has_deductible,
          deductible_fee: insurance_info?.deductible_amount ?? 0,
          repair_planning: insurance_info?.repair_plan ?? '-',
          accident_reception_number: insurance_info?.receipt_number ?? '-',
        }
      : {
          insurance_use_flg: 0,
          m_insurance_company_id: 0,
          policy_number: '',
          has_deductible: false,
          deductible_fee: 0,
          repair_planning: 0,
          accident_reception_number: '',
        };
  }, [insurance_info]);

  const loanerCarInfo = useMemo(() => {
    return {
      loaner_car_flg: loaner_car_info?.use_loaner_car ?? 0,
      loaner_car_memo: loaner_car_info?.loaner_car_request ?? '-',
    };
  }, [loaner_car_info]);
  const [checkInReserveParams, setCheckInReserveParams] =
    useState<RepairCheckInReserveParams>({} as RepairCheckInReserveParams);
  const initialize = useCallback(() => {
    setCheckInReserveParams({
      m_customer_id: customerID,
      m_affiliate_id: affiliateID,
      m_unit_id: unitID,
      m_plant_id: plantID,
      t_stock_car_id: stockCarID,
      data: {
        reserve_info: reserveInfo,
        car_condition: carConditionInfo,
        insurance_info: insuranceInfo,
        check_in_date: check_in_date,
        loaner_car_info: loanerCarInfo,
      },
    });
  }, [
    customerID,
    affiliateID,
    unitID,
    plantID,
    stockCarID,
    reserveInfo,
    carConditionInfo,
    insuranceInfo,
    check_in_date,
    loanerCarInfo,
  ]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  return { checkInReserveParams };
};
