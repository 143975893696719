import { useState, useCallback, useMemo } from 'react';

interface RadioSelectHookProps {
  initialSelect: number;
  selectList: string[];
}
export const useRadioSelect = ({
  initialSelect,
  selectList,
}: RadioSelectHookProps) => {
  const [select, setSelect] = useState(initialSelect);
  const handleChangeSelect = useCallback((newSelect: number) => {
    setSelect(newSelect);
  }, []);
  const value = useMemo(() => selectList[select], [select, selectList]);
  return [select, value, handleChangeSelect] as const;
};
