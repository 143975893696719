import { InsuranceApplyResultItemType } from '../../type/bodyAndPaint';
import { OutlinedButton } from '../button';
import { InsuranceApplyResultCard } from './insuranceApplyResultCard';

interface RepairItemHeaderFieldProps {
  onCheckImage: () => void;
  insurance?: InsuranceApplyResultItemType;
}
export const RepairItemHeaderField = ({
  onCheckImage,
  insurance,
}: RepairItemHeaderFieldProps) => {
  const handleClickCheckImage = () => onCheckImage();
  const RepairItemSelectLabel = () => {
    return (
      <div className={'page-label repair-item-select'}>
        <div className={'page-label repair-item-select'}>
          <span>{'修理依頼内容の選択'}</span>
        </div>
        <div className={'image-check-button'}>
          <OutlinedButton
            onClick={handleClickCheckImage}
            label={'修理箇所の画像を確認'}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={'repair-item-select-header-field'}>
      <RepairItemSelectLabel />
      {insurance && <InsuranceApplyResultCard info={insurance} />}
    </div>
  );
};
