import { useCallback, useEffect, useState } from 'react';

// TODO： Framework7 - React 間の設計が完了次第削除
export const useHotFixTransition = () => {
  const storageKey = 'bp_page_info';
  type PageInfo = {
    pageTitle: string;
    backTo: string;
  };

  localStorage.removeItem(storageKey);
  const initial =
    localStorage.getItem(storageKey) || '{"pageInfo":"","backTo":""}';
  const [pageInfo, setPageInfo] = useState<PageInfo>(JSON.parse(initial));

  const changePageInfo = (page: PageInfo) => {
    localStorage.setItem(storageKey, JSON.stringify(page));
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: storageKey,
        newValue: JSON.stringify(page),
      }),
    );
  };
  const handleChange = useCallback((event: StorageEvent) => {
    if (event.key === storageKey) {
      console.log('callback:', event);
      event.newValue && setPageInfo(JSON.parse(event.newValue));
    }
  }, []);
  useEffect(() => {
    window.addEventListener('storage', handleChange);
    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [handleChange]);

  return { pageInfo, changePageInfo };
};
