import { useState, useMemo, useEffect } from 'react';
import { LoanerCarInfoType } from '../../../type/bodyAndPaint';

interface LoanerCarInfoHookProps {
  info: LoanerCarInfoType;
}
export const useLoanerCarInfo = ({ info }: LoanerCarInfoHookProps) => {
  const [loanerCar, setLoanerCar] = useState(info ?? ({} as LoanerCarInfoType));
  const [disable, setDisable] = useState(false);
  const { use_loaner_car, loaner_car_request, approve } =
    loanerCar ?? ({} as LoanerCarInfoType);
  const loanerCarCheck = useMemo(
    () => use_loaner_car !== undefined && use_loaner_car >= 0,
    [use_loaner_car],
  );
  const isLoanerCar = use_loaner_car === 0;
  useEffect(() => {
    if (!isLoanerCar) {
      setLoanerCar({
        use_loaner_car: use_loaner_car,
        loaner_car_request: '',
        approve: false,
      } as LoanerCarInfoType);
    }
  }, [isLoanerCar, use_loaner_car]);
  useEffect(() => {
    const check = isLoanerCar
      ? !!(
          approve &&
          loanerCarCheck &&
          loaner_car_request !== undefined &&
          loaner_car_request !== ''
        )
      : !!(approve && loanerCarCheck);
    setDisable(!check);
  }, [isLoanerCar, loaner_car_request, approve, loanerCarCheck]);
  return [loanerCar, setLoanerCar, disable] as const;
};
