import { FilledButton } from '../button';

interface RepairRequestDialogCardProps {
  onClose: () => void;
}
export const RepairRequestDialogCard = ({
  onClose,
}: RepairRequestDialogCardProps) => {
  const dialogLabel = '修理依頼を受け付けました';
  const dialogText = ['ご予約ありがとうございます。', '着工を開始します。'];
  const handleClickCloseDialog = () => onClose();
  return (
    <div className={'dialog-card repair-request'}>
      <div className={'message-field'}>
        <div className={'message-header'}>
          <span>{dialogLabel}</span>
        </div>
        <div className={'message-body'}>
          {dialogText.map((item, idx) => (
            <span key={idx}>{item}</span>
          ))}
        </div>
      </div>
      <FilledButton
        onClick={handleClickCloseDialog}
        label={'依頼状況を確認する'}
      />
    </div>
  );
};
