import { useMemo } from 'react';
import { RepairRequestDataType } from '../../type/bodyAndPaint';
import { EstimateDetailItemCard } from './estimateDetailItemCard';
import { InsuranceApplyResultCard } from './insuranceApplyResultCard';
import { NoInsuranceCard } from './noInsuranceCard';
import { RepairServiceEstimateField } from './repairServiceEstimateField';

interface EstimateResultInfoFieldProps {
  onCheckImage: () => void;
  onAddInsurance: () => void;
  info: RepairRequestDataType;
}
export const EstimateResultInfoField = ({
  info,
  onCheckImage,
  onAddInsurance,
}: EstimateResultInfoFieldProps) => {
  const { estimate_result_info } = info ?? ({} as RepairRequestDataType);
  const { estimateSubjects, insuranceInfo, estimateItemList } = useMemo(() => {
    return {
      estimateSubjects: estimate_result_info?.estimate_subject_item_info,
      insuranceInfo: estimate_result_info?.insurance_apply_result,
      estimateItemList: estimate_result_info?.estimate_detail_item_list,
    };
  }, [estimate_result_info]);

  const handleClickCheckImage = () => onCheckImage();
  const handleClickAddInsurance = () => onAddInsurance();
  return (
    <div className={'estimate-info-result-field'}>
      <EstimateDetailItemCard
        info={estimateItemList}
        onClick={handleClickCheckImage}
      />
      {insuranceInfo ? (
        <InsuranceApplyResultCard info={insuranceInfo} />
      ) : (
        <NoInsuranceCard onClick={handleClickAddInsurance} />
      )}
      <RepairServiceEstimateField info={estimateSubjects} />
    </div>
  );
};
