import { f7, useStore } from 'framework7-react';
import { FC } from 'react';
import style from './ReservationHistoryPageStyle.module.css';
import { ReservationItem } from '@/components/projects/ReservationItem/ReservationItem';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { useFetchReservationHistories } from '@/hooks/api/reservation/useFetchReservationHistories';
import { Car } from '@/types/api/customerApi';
import { Reservation } from '@/types/api/reservationApi';

export const ReservationHistoryPage: FC = () => {
  const selectedCar = useStore(store, 'carItemInfo') as Required<Car>;
  const { m_customer_id } = store.state.authInfo;
  const { bp_flg } = store.state.customerInfo;

  const { data } = useFetchReservationHistories({
    m_customer_id,
    t_stock_car_id: selectedCar.t_stock_car_id,
  });

  return (
    <PageTemplate
      pageName="WashHistory"
      title="過去の予約一覧"
      showNavBack
      handleClickBack={() =>
        f7.view.main.router.navigate(paths.maintenance, {
          transition: 'push-reverse',
        })
      }
    >
      <div className={style['reservation-history-title']}>
        <p className={style['reservation-history-title__text']}>
          過去の予約一覧
        </p>
        <p className={style['reservation-history-title__desc']}>
          支払い済みの領収書は各予約詳細から確認することができます。
        </p>
      </div>
      <div className={style['card']}>
        {!!bp_flg &&
          data?.map((reservation: Reservation, i: any) => (
            <ReservationItem reservation={reservation} key={i} />
          ))}
      </div>
    </PageTemplate>
  );
};
