import { useMemo } from 'react';
import {
  CAR_DRIVEN,
  CONFIRM_CAR_CONDITION,
  CONFIRM_CHECK_IN_DATE,
  CONFIRM_CHECK_IN_RESERVE,
  CONFIRM_LOANER_CAR,
  DAMAGE_DEGREE,
  DAMAGE_PART,
  HAS_DEDUCTIBLE,
  INSURANCE_USE,
  LOANER_CAR_USE,
  OTHER_PARTY,
  REPAIR_PLAN,
} from '../../../const/bodyAndPaint';
import {
  CheckInReserveDataType,
  DamageMapItemType,
} from '../../../type/bodyAndPaint';
import { useDateText, useReserveDateText } from '../useDateTimeText';

interface CheckInReserveConfirmInfoHookProps {
  info: CheckInReserveDataType;
}
export const useCheckInReserveConfirmInfo = ({
  info,
}: CheckInReserveConfirmInfoHookProps) => {
  const {
    reserve_info,
    car_condition_info,
    insurance_info,
    loaner_car_info,
    check_in_date,
  } = info ?? ({} as CheckInReserveDataType);
  const confirmCheckInReserveItem: Record<
    keyof typeof CONFIRM_CHECK_IN_RESERVE,
    string
  > = {
    car_name: reserve_info?.car_name ?? '-',
    category: reserve_info?.category ?? '-',
    factory: reserve_info?.factory_name ?? '-',
  };
  const confirmCheckInReserveList: Record<string, string>[] = Object.entries(
    confirmCheckInReserveItem,
  ).map(([key, value]) => {
    const keyName = key as keyof typeof CONFIRM_CHECK_IN_RESERVE;
    return { [CONFIRM_CHECK_IN_RESERVE[keyName]]: value };
  });

  const carDrivenList: string[] = useMemo(
    () => Object.values(CAR_DRIVEN).map((item) => item.label),
    [],
  );
  const damageDegreeList: string[] = useMemo(
    () => Object.values(DAMAGE_DEGREE).map((item) => item.label),
    [],
  );
  const otherPartyList: string[] = useMemo(
    () => Object.values(OTHER_PARTY).map((item) => item.label),
    [],
  );
  const insuranceUseList: string[] = useMemo(
    () => Object.values(INSURANCE_USE).map((item) => item.label),
    [],
  );
  const hasDeductibleList: string[] = useMemo(
    () => Object.values(HAS_DEDUCTIBLE).map((item) => item.label),
    [],
  );
  const repairPlanList: string[] = useMemo(
    () => Object.values(REPAIR_PLAN).map((item) => item.label),
    [],
  );
  const loanerCarList: string[] = useMemo(
    () => Object.values(LOANER_CAR_USE).map((item) => item.label),
    [],
  );

  const damagePart = Object.keys(DAMAGE_PART).reduce((list, key) => {
    const keyName = key as keyof DamageMapItemType;
    if (car_condition_info?.damage_map[keyName]) {
      list.push(DAMAGE_PART[keyName]);
    }
    return list;
  }, [] as string[]);

  const noticeDate = useDateText({ dateTime: car_condition_info?.notice_date });
  const checkInDate = useReserveDateText({ dateTime: check_in_date });

  const confirmCarConditionItem: Record<
    keyof typeof CONFIRM_CAR_CONDITION,
    string | string[]
  > = {
    car_drive: carDrivenList.at(car_condition_info?.car_driven) ?? '-',
    damage_part: damagePart ?? ['-'],
    notice_date: noticeDate ? `${noticeDate}から` : '-',
    damage_degree:
      damageDegreeList.at(car_condition_info?.damage_degree) ?? '-',
    has_other_party: otherPartyList.at(car_condition_info?.other_party) ?? '-',
    description: car_condition_info?.description ?? '-',
    use_insurance:
      insuranceUseList.at(insurance_info?.use_insurance ?? -1) ?? '-',
    company_name: insurance_info?.company_name ?? '-',
    certificate_number: insurance_info?.certificate_number ?? '-',
    receipt_number: insurance_info?.receipt_number ?? '-',
    deductible:
      hasDeductibleList.at(insurance_info?.has_deductible ?? -1) ?? '-',
    repair_plan: repairPlanList.at(insurance_info?.repair_plan ?? -1) ?? '-',
  };
  const confirmCarConditionList: Record<string, string | string[]>[] =
    Object.entries(confirmCarConditionItem).map(([key, value]) => {
      const keyName = key as keyof typeof CONFIRM_CAR_CONDITION;
      return { [CONFIRM_CAR_CONDITION[keyName]]: value };
    });

  const confirmLoanerCarItem: Record<keyof typeof CONFIRM_LOANER_CAR, string> =
    {
      use_loaner_car: loanerCarList.at(loaner_car_info?.use_loaner_car) ?? '-',
    };
  const confirmLoanerCarList: Record<string, string>[] = Object.entries(
    confirmLoanerCarItem,
  ).map(([key, value]) => {
    const keyName = key as keyof typeof CONFIRM_LOANER_CAR;
    return { [CONFIRM_LOANER_CAR[keyName]]: value };
  });

  const confirmCheckInItem: Record<keyof typeof CONFIRM_CHECK_IN_DATE, string> =
    {
      check_in_date: checkInDate ? `${checkInDate}〜` : '-',
    };
  const confirmCheckInList: Record<string, string>[] = Object.entries(
    confirmCheckInItem,
  ).map(([key, value]) => {
    const keyName = key as keyof typeof CONFIRM_CHECK_IN_DATE;
    return { [CONFIRM_CHECK_IN_DATE[keyName]]: value };
  });
  return {
    confirmCheckInReserveList,
    confirmCarConditionList,
    confirmLoanerCarList,
    confirmCheckInList,
  } as const;
};
