import { OutlinedButton, FilledButton } from '../button';

interface RequestConfirmButtonFieldProps {
  onCancel: () => void;
  onRequest: () => void;
  isEstimating: boolean;
}
export const RequestConfirmButtonField = ({
  onCancel,
  onRequest,
  isEstimating,
}: RequestConfirmButtonFieldProps) => {
  const handleClickCorrectRequest = () => onCancel();
  const handleClickRepairRequest = () => onRequest();
  return (
    <div className={'button-field estimate-result'}>
      <OutlinedButton
        onClick={handleClickCorrectRequest}
        label={'キャンセル'}
      />
      <FilledButton
        onClick={handleClickRepairRequest}
        label={'修理を依頼する'}
        disabled={isEstimating}
      />
    </div>
  );
};
