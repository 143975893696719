import { FC } from 'react';
import style from './RegisterMethod.module.css';

interface RegisterMethodProps {
  img: string;
  title: string;
  handleClick?: () => void;
}

export const RegisterMethod: FC<RegisterMethodProps> = ({
  img,
  title,
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className={style['container']}>
      <i className={'icon-right ' + style['icon-left']} />
      <div className={style['register-method']}>
        <p className={style['title']}>{title}</p>
        <img src={img} />
      </div>
      <i className={'icon-right ' + style['icon-right']} />
    </div>
  );
};
