import { Link, NavLeft, NavTitle, Navbar, Page } from 'framework7-react';
import { FC, ReactNode } from 'react';
import style from './registerQRPageTemplateStyle.module.css';
import { store } from '@/config/store';
import { switchShopTheme } from '@/utils/shop';
import './registerQRPageTemplateStyle.scss';

interface RegisterQRPageTemplateProps {
  children: ReactNode;
  pageName: string;
  title: string;
  showNavBack?: boolean;
  noScroll?: boolean;
  inlinePageStyle?: React.CSSProperties;
  handleClickBack?: () => void;
  confirmPage?: boolean;
}
export const RegisterQRPageTemplate: FC<RegisterQRPageTemplateProps> = ({
  children,
  pageName,
  title,
  showNavBack = false,
  noScroll = false,
  inlinePageStyle,
  handleClickBack,
  confirmPage = false,
}) => {
  const themeId = store.state.themeId;
  const shopThemeObj = switchShopTheme(themeId);
  const pageStyle = { backgroundColor: shopThemeObj.mainColor };

  const getClassPage = () => {
    return `${style['page']}`;
  };

  return (
    <Page
      name={pageName}
      className={getClassPage()}
      style={{ ...pageStyle, ...inlinePageStyle }}
    >
      <Navbar
        className={
          confirmPage
            ? style['register-confirm-navbar'] + ' register-confirm-navbar'
            : style['register-qr-navbar'] + ' register-qr-navbar-no-logo'
        }
      >
        {showNavBack &&
          (!handleClickBack ? (
            <NavLeft>
              <Link back animate className={`${style['no-ripple']} no-ripple`}>
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ) : (
            <NavLeft>
              <Link
                onClick={handleClickBack}
                className={`${style['no-ripple']} no-ripple`}
              >
                <div className={style['icon-wrapper']}>
                  <i className={`${style['nav-icon']} icon-left no-ripple`} />
                </div>
              </Link>
            </NavLeft>
          ))}
        {title && <NavTitle style={{ color: '#323232' }}>{title}</NavTitle>}
      </Navbar>
      <div className={style['container']}>
        <div
          className={
            'register-qr-container ' +
            `${style['content']} ${noScroll ? style['no-scroll'] : ''}`
          }
        >
          {children}
        </div>
      </div>
    </Page>
  );
};
