import {
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
} from 'framework7-react';
import { FC, ReactNode } from 'react';
import style from './pageTemplateStyle.module.css';
import { MenuFooter } from '@/components/uiParts/MenuFooter/MenuFooter';
import { isApp, isIPhone } from '@/config/device';
import { store } from '@/config/store';
import { switchShopTheme } from '@/utils/shop';

interface PageTemplateComponentProps {
  children: ReactNode;
  pageName: string;
  title: string;
  onBack?: () => void;
}
export const PageTemplateComponent: FC<PageTemplateComponentProps> = ({
  children,
  pageName,
  title,
  onBack,
}) => {
  // IPhone safe-area padding!
  const iPhoneTopPadding = 50;
  const themeId = store.state.themeId;
  const shopThemeObj = switchShopTheme(themeId);
  const pageStyle = { backgroundColor: shopThemeObj.mainColor };

  const containerBackground = {
    '--background-color': shopThemeObj.mainBgColor,
  } as React.CSSProperties;

  const getClassPage = () => {
    if (isApp) {
      if (isIPhone) {
        return `${style['page']} ${style['ios-page']}`;
      }
    }
    return `${style['page']}`;
  };

  const navbarsElement = document.getElementsByClassName('navbars')[0];
  if (navbarsElement) {
    navbarsElement.setAttribute(
      'style',
      `height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top)); background-image: linear-gradient(-180deg, ${shopThemeObj.homeBorderColorFirst} 4px, ${shopThemeObj.homeBorderColorSecond} 4px); background-size: 100% 8px; background-repeat: no-repeat; background-position: 0 100%;`,
    );
  }
  const handleCustomClickBack = () => onBack && onBack();

  // IPhone only top Padding for safe-area has been added. Even on the prior model of Iphone X, it still adds the safe area padding regardless
  return (
    <Page name={pageName} className={getClassPage()} style={{ ...pageStyle }}>
      <Navbar noShadow noHairline>
        <NavLeft>
          <Link
            onClick={handleCustomClickBack}
            animate={!handleCustomClickBack}
            ignoreCache={!handleCustomClickBack}
            className={`${style['no-ripple']} no-ripple`}
          >
            <div
              className={style['icon-wrapper']}
              style={{ backgroundColor: shopThemeObj.mainColor }}
            >
              <i className={`${style['nav-icon']} icon-left no-ripple`} />
            </div>
          </Link>
        </NavLeft>
        <NavTitle>{title}</NavTitle>
        <NavRight>
          <Link
            animate
            href="/help_top/"
            className={`${style['no-ripple']} no-ripple`}
          >
            <div
              style={{ backgroundColor: shopThemeObj.mainColor }}
              className={style['icon-wrapper']}
            >
              <i className={`${style['nav-icon']} icon-help-fill`} />
            </div>
          </Link>
        </NavRight>
      </Navbar>
      <div style={containerBackground} className={style['container']}>
        <div className={`${style['content']}`} id="page-template-content">
          {children}
        </div>
      </div>
      <MenuFooter />
    </Page>
  );
};
