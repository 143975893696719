import { f7 } from 'framework7-react';
import { useMemo, useState } from 'react';
import { BODY_PAINT_STATE } from './const/bodyAndPaint';
import {
  RepairProgressProvider,
  useRepairProgressDataContext,
} from './context/progressDetailContext';
import { useHotFixTransition } from './hook/useHotFixTransition';
import { useRepairProgressData } from './hook/util/repairProgress/useRepairProgress';
import { RepairCheckInReserve } from './repairCheckIn/repairCheckInReserve';
import { RepairProgressDetail } from './repairProgress/repairProgressDetail';
import { RepairServiceRequest } from './repairService/repairServiceRequest';
import {
  RepairProgressDataType,
  RepairProgressStateType,
} from './type/bodyAndPaint';
import { PageTemplateComponent } from './util/common/pageTemplate/pageTemplate';
import { paths } from '@/config/paths';
import { store } from '@/config/store';

export const BPRepairPage = () => {
  const { authInfo, reservation, customerInfo } = store.state;
  const customerID = useMemo(() => authInfo.m_customer_id, [authInfo]);
  const reservationID = useMemo(
    () => reservation.reservation_id,
    [reservation],
  );
  const stockCarID = useMemo(() => reservation.t_stock_car_id, [reservation]);
  const affiliateID = useMemo(
    () => customerInfo.m_affiliate_id,
    [customerInfo],
  );
  const unitID = useMemo(() => customerInfo.m_unit_id, [customerInfo]);

  const { repairProgressData } = useRepairProgressData({
    affiliateID,
    customerID,
    reservationID,
    stockCarID,
    unitID,
  });

  const BodyAndPaint = ({ state }: { state: string }) => {
    const { data } = useRepairProgressDataContext();
    const statusList = Object.keys(BODY_PAINT_STATE);

    const status = data?.state_info?.status ?? -1;
    console.log(data);
    console.log([status]);
    console.log(statusList[status]);
    switch (statusList[status]) {
      case 'ACCIDENT_ACCEPT':
      case 'CHECK_IN_ACCEPT':
      case 'INSURANCE_APPLY':
      case 'DETERMINED_APPLIES':
      case 'REPAIR_CLAIM':
      case 'NO_REPAIR':
      case 'DISASSEMBLY':
      case 'REPAIR_HAS_DONE':
      case 'INSPECTION_HAS_DONE':
      case 'DELIVERED_HAS_DONE':
      case 'REPAIR_CANCELLED':
        return <RepairProgressDetail state={state} data={repairProgressData} />;
      case 'ESTIMATE_APPLY':
      case 'APPLIES_RESULT':
        return <RepairServiceRequest state={state} />;
      default:
        return <RepairCheckInReserve state={state} />;
    }
  };

  const {
    pageInfo: { pageTitle, backTo },
  } = useHotFixTransition();
  const [pageState, setPageState] = useState(backTo);
  const handleClickBack = () => {
    f7.views.main.router.navigate(paths.maintenance);
    // pageState !== ''
    //   ? setPageState(pageState)
    //   : f7.views.main.router.navigate(paths.maintenance);
  };

  return (
    <PageTemplateComponent
      pageName={'checkInReserve'}
      title={'傷・凹み修理'}
      onBack={handleClickBack}
    >
      <RepairProgressProvider
        initialData={repairProgressData ?? ({} as RepairProgressDataType)}
        initialState={pageState as RepairProgressStateType}
      >
        <BodyAndPaint state={pageState} />
      </RepairProgressProvider>
    </PageTemplateComponent>
  );
};
