import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetMonthlyCheckInData } from '../api/useGetMonthlyCheckInData';

// API Hooks
interface CalendarDataHookProps {
  initialDate?: Date;
  plantID: number;
}
export const useGetCalendarData = ({
  initialDate,
  plantID,
}: CalendarDataHookProps) => {
  const isDateCheck =
    initialDate instanceof Date && !isNaN(initialDate.getTime());
  const [selectDate, setSelectDate] = useState(
    isDateCheck ? initialDate : ({} as Date),
  );
  const [selectMonthly, setSelectMonthly] = useState(
    isDateCheck ? initialDate : new Date(),
  );
  const [disable, setDisable] = useState(false);

  const offset = 1;
  const selectMonth = useMemo(
    () => `${selectMonthly.getFullYear()}-${selectMonthly.getMonth() + offset}`,
    [selectMonthly],
  );

  const handlePrevMonth = useCallback(() => {
    const newDate = new Date(selectMonthly);
    newDate.setMonth(selectMonthly.getMonth() - offset);
    setSelectMonthly(newDate);
  }, [selectMonthly]);

  const handleNextMonth = useCallback(() => {
    const newDate = new Date(selectMonthly);
    newDate.setMonth(selectMonthly.getMonth() + offset);
    setSelectMonthly(newDate);
  }, [selectMonthly]);

  const { checkInDataList: dateItemList } = useGetMonthlyCheckInData({
    m_plant_id: plantID,
    year_month: selectMonth,
  });

  useEffect(() => {
    const check = !!(
      selectDate instanceof Date && !isNaN(selectDate.getTime())
    );
    setDisable(!check);
  }, [selectDate]);

  return {
    selectDate,
    setSelectDate,
    dateItemList,
    selectMonthly,
    handlePrevMonth,
    handleNextMonth,
    disable,
  };
};
