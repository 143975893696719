import { useCallback, useMemo } from 'react';
import {
  useCheckInReserveDataContext,
  useCheckInReserveStateContext,
} from '../context/checkInReserveContext';
import { useRepairProgressDataContext } from '../context/progressDetailContext';
import { useGetCalendarData } from '../hook/util/useGetCalendarData';
import { EntryCalendarField } from '../util/repairCheckIn/entryCalendar';
import { NextButtonField } from '../util/repairCheckIn/nextButtonField';
import style from './repairCheckInReserveStyle.module.css';
import { CheckInReserveDataType } from '@/types/bodyAndPaint';

export const CheckInReserveCheckInDate = () => {
  const { data: bpData } = useRepairProgressDataContext();
  const { setState } = useCheckInReserveStateContext();
  const { data } = useCheckInReserveDataContext();
  const { check_in_date } = data ?? ({} as CheckInReserveDataType);
  const checkInDate = useMemo(() => check_in_date, [check_in_date]);

  // TODO:工場一覧取得

  const {
    selectDate: selectCheckInDate,
    setSelectDate: setSelectCheckInDate,
    dateItemList,
    selectMonthly: selectMonth,
    handlePrevMonth,
    handleNextMonth,
    disable,
  } = useGetCalendarData({
    initialDate: checkInDate,
    plantID: 0,
  });

  const handleClickBack = useCallback(() => {
    setState('USE_INSURANCE');
  }, [setState]);
  const handleClickNext = useCallback(() => {
    setState('RESERVE_CONFIRM');
  }, [setState]);

  return (
    <>
      <div className={style.checkInReserveEntryField}>
        <EntryCalendarField
          select={selectCheckInDate}
          setSelect={setSelectCheckInDate}
          itemList={dateItemList}
          selectMonth={selectMonth}
          onNext={handleNextMonth}
          onPrev={handlePrevMonth}
        />
      </div>
      <NextButtonField disable={disable} onClick={handleClickNext} />
    </>
  );
};
