import { useCallback, useEffect, useState } from 'react';
import { getRepairEstimateResultApi } from '@/api/bodyAndPaint';
import { RepairEstimateResultResponse } from '@/types/api/bodyAndPaint';

interface GetEstimateResultDataHookProps {
  m_customer_id: number;
  t_pm_car_inspection_id: number;
  t_sale_id: number;
}
export const useGetEstimateResultData = ({
  m_customer_id,
  t_pm_car_inspection_id,
  t_sale_id,
}: GetEstimateResultDataHookProps) => {
  const [estimateResult, setEstimateResult] =
    useState<RepairEstimateResultResponse>({} as RepairEstimateResultResponse);

  const fetchDate = useCallback(async () => {
    try {
      if (m_customer_id > 0 && t_pm_car_inspection_id > 0 && t_sale_id > 0) {
        const fetch = await getRepairEstimateResultApi({
          m_customer_id,
          t_pm_car_inspection_id,
          t_sale_id,
        });

        setEstimateResult(fetch.data.data as RepairEstimateResultResponse);
      } else {
        setEstimateResult({} as RepairEstimateResultResponse);
        throw 'invalid parameter';
      }
    } catch (error) {
      console.error(error);
    }
  }, [m_customer_id, t_pm_car_inspection_id, t_sale_id]);
  useEffect(() => {
    fetchDate();
  }, [fetchDate]);

  return {
    estimateInfo: estimateResult.info,
    estimateItemList: estimateResult.estimate,
  };
};
