import { Page } from 'framework7-react';
import { FC } from 'react';
import style from './ServiceStyle.module.css';
import { ReactComponent as PayIcon } from '@/assets/images/menu/icon-pay-tip-color.svg';
import { Button } from '@/components/uiParts/Button';

type DialogProps = {
  prepaidName: string;
  handleOk: () => void;
  handleCancel: () => void;
};

export const Dialog: FC<DialogProps> = ({
  prepaidName,
  handleOk,
  handleCancel,
}) => {
  return (
    <Page name="ConfirmDialog">
      <div>
        <div className={`container ${style['dialog-container']}`}>
          <div>
            <PayIcon width={48} height={48} />
            <h2>{prepaidName}のご登録はされましたか？</h2>
          </div>
          <p>
            サービスのお支払いは、ご登録された{prepaidName}
            の残高から自動でお支払いが行われます。
          </p>
          <Button text="登録する" handleClickAction={handleOk} />
          <Button
            style={{ color: '#232323', backgroundColor: '#fff' }}
            text="閉じる"
            handleClickAction={handleCancel}
          />
        </div>
      </div>
    </Page>
  );
};
