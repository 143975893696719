import {
  useDateText,
  useReserveDateText,
} from '../../hook/util/useDateTimeText';
import { RepairProgressReserveInfoType } from '../../type/bodyAndPaint';
import { AccordionField } from '../accordion';

interface RepairProgressReserveProps {
  info: RepairProgressReserveInfoType;
}
export const RepairProgressReserveField = ({
  info,
}: RepairProgressReserveProps) => {
  const {
    car_info,
    category,
    factory_name,
    check_in_date,
    damage_part,
    notice_date,
    description,
  } = info ?? ({} as RepairProgressReserveInfoType);

  const checkInDate = useReserveDateText({ dateTime: new Date(check_in_date) });
  const noticeDate = useDateText({ dateTime: new Date(notice_date) });
  const infoList: Record<string, string> = {
    対象車両: car_info?.car_name ?? '-',
    入庫区分: category,
    入庫工場: factory_name,
    予約日時: `${checkInDate}〜`,
    損傷箇所: damage_part,
    いつから: `${noticeDate}から`,
    詳細: description,
  };
  return (
    <AccordionField title={'予約情報'}>
      {Object.entries(infoList).map(([label, item], idx) => (
        <div className={'repair-progress-info'} key={idx}>
          <div className={'repair-progress-info-label'}>
            <span>{label}</span>
          </div>
          <div className="repair-progress-info-item">
            <span>{item}</span>
          </div>
        </div>
      ))}
    </AccordionField>
  );
};
