import { FilledButton } from '../button';

interface ReserveButtonFieldProps {
  disable: boolean;
  onClick: () => void;
}
export const ReserveButtonField = ({
  disable,
  onClick,
}: ReserveButtonFieldProps) => {
  const handleClickNext = () => onClick();
  return (
    <div className={'next-button-field'}>
      <FilledButton
        onClick={handleClickNext}
        label={'入庫予約をする'}
        disabled={disable}
      />
    </div>
  );
};
