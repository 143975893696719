import { Input } from 'framework7-react';
import { BaseSyntheticEvent, useCallback, useState } from 'react';
import './f7ComponentStyle.css';

interface TextFieldProps {
  value: string;
  onChange: (e: BaseSyntheticEvent) => void;
  required?: boolean;
  placeholder?: string;
  readonly?: boolean;
}
export const TextField = ({
  value,
  onChange,
  required,
  placeholder,
  readonly,
}: TextFieldProps) => {
  const [text, setText] = useState(value);
  const handleChangeInput = useCallback(
    (e: BaseSyntheticEvent) => {
      setText(e.target.value);
      onChange(e);
    },
    [onChange],
  );
  const isRequired = !!(required && value !== '' && text === '')
    ? 'input-error'
    : '';
  return (
    <Input
      readonly={readonly}
      defaultValue={text}
      className={isRequired}
      type={'text'}
      placeholder={placeholder}
      onInput={handleChangeInput}
    />
  );
};
export const TextAreaField = ({
  value,
  onChange,
  required,
  placeholder,
}: TextFieldProps) => {
  const [text, setText] = useState(value);
  const handleChangeInput = useCallback(
    (e: BaseSyntheticEvent) => {
      setText(e.target.value);
      onChange(e);
    },
    [onChange],
  );
  const isRequired = !!(required && value !== '' && text === '')
    ? 'input-error'
    : '';
  return (
    <Input
      defaultValue={text}
      className={isRequired}
      type={'textarea'}
      placeholder={placeholder}
      onInput={handleChangeInput}
    />
  );
};
