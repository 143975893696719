import { useCallback, useMemo } from 'react';
import style from './calendarComponent.module.css';

interface CalendarDateEntryHeaderProps {
  selectMonth: Date;
  onPrev: () => void;
  onNext: () => void;
}
export const CalendarDateEntryHeader = ({
  selectMonth,
  onPrev,
  onNext,
}: CalendarDateEntryHeaderProps) => {
  const monthDate = useMemo(() => new Date(selectMonth), [selectMonth]);
  const monthOffset = 1;
  const year = monthDate.getFullYear();
  const month = monthDate.getMonth() + monthOffset;
  const handleClickPrevMonth = useCallback(() => onPrev(), [onPrev]);
  const handleClickNextMonth = useCallback(() => onNext(), [onNext]);
  return (
    <div className={style.calendarDateHeader}>
      <div className={style.monthLabel}>
        <span>{`${year}年${month}月`}</span>
      </div>
      <div className={style.monthSelector}>
        <div className={style.leftMonthIcon} onClick={handleClickPrevMonth}>
          <i className={'icon-image'} />
        </div>
        <div className={style.rightMonthIcon} onClick={handleClickNextMonth}>
          <i className={'icon-image'} />
        </div>
      </div>
    </div>
  );
};
