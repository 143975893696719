import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  RepairProgressDataType,
  RepairProgressStateType,
} from '../type/bodyAndPaint';

// Context Object
export const RepairProgressStateContext = createContext<{
  state?: RepairProgressStateType;
  setState: Dispatch<SetStateAction<RepairProgressStateType>>;
}>({
  setState: () => {},
});
export const RepairProgressDataContext = createContext<{
  data?: RepairProgressDataType;
  setData: Dispatch<SetStateAction<RepairProgressDataType>>;
}>({
  setData: () => {},
});

// Context Provider
interface RepairProgressProviderProps {
  initialState: RepairProgressStateType;
  initialData: RepairProgressDataType;
  children: ReactNode;
}
export const RepairProgressProvider = ({
  initialState,
  initialData,
  children,
}: RepairProgressProviderProps) => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState(initialData);

  const stateContext = useMemo(() => ({ state, setState }), [state]);
  const dataContext = useMemo(() => ({ data, setData }), [data]);

  useEffect(() => {
    setState(initialState);
    setData(initialData);
  }, [initialState, initialData]);
  return (
    <RepairProgressStateContext.Provider value={stateContext}>
      <RepairProgressDataContext.Provider value={dataContext}>
        {children}
      </RepairProgressDataContext.Provider>
    </RepairProgressStateContext.Provider>
  );
};

// Context Hook
export const useRepairProgressStateContext = () => {
  const { state, setState } = useContext(RepairProgressStateContext);
  return { state, setState };
};
export const useRepairProgressDataContext = () => {
  const { data, setData } = useContext(RepairProgressDataContext);
  return { data, setData };
};
