import { FilledButton } from '../button';

interface ChangeCheckInButtonFieldProps {
  disable: boolean;
  onClick: () => void;
}
export const ChangeCheckInButtonField = ({
  disable,
  onClick,
}: ChangeCheckInButtonFieldProps) => {
  const handleClickConfirm = () => onClick();
  return (
    <div className={'next-button-field'}>
      <FilledButton
        onClick={handleClickConfirm}
        label={'上記で再予約する'}
        disabled={disable}
      />
    </div>
  );
};
