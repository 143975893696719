import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  CheckInReserveDataType,
  CheckInReserveStateType,
} from '../type/bodyAndPaint';

// Context Object
export const CheckInReserveStateContext = createContext<{
  state?: CheckInReserveStateType;
  setState: Dispatch<SetStateAction<CheckInReserveStateType>>;
}>({
  setState: () => {},
});
export const CheckInReserveDataContext = createContext<{
  data?: CheckInReserveDataType;
  setData: Dispatch<SetStateAction<CheckInReserveDataType>>;
}>({
  setData: () => {},
});

// Context Provider
interface CheckInReserveProviderProps {
  initialState: CheckInReserveStateType;
  initialData: CheckInReserveDataType;
  children: ReactNode;
}
export const CheckInReserveProvider = ({
  initialState,
  initialData,
  children,
}: CheckInReserveProviderProps) => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState(initialData);

  const stateContext = useMemo(() => ({ state, setState }), [state]);
  const dataContext = useMemo(() => ({ data, setData }), [data]);

  return (
    <CheckInReserveStateContext.Provider value={stateContext}>
      <CheckInReserveDataContext.Provider value={dataContext}>
        {children}
      </CheckInReserveDataContext.Provider>
    </CheckInReserveStateContext.Provider>
  );
};

// Context Hook
export const useCheckInReserveStateContext = () => {
  const { state, setState } = useContext(CheckInReserveStateContext);
  return { state, setState };
};
export const useCheckInReserveDataContext = () => {
  const { data, setData } = useContext(CheckInReserveDataContext);
  return { data, setData };
};
