import style from './calendarComponent.module.css';

export const CalendarDateEntryNote = () => {
  return (
    <div className={style.calendarDateNote}>
      <div className={`${style.calendarStatus} ${style.available}`}>
        <i className={'icon-available'} />
        <span>{'…空きあり'}</span>
      </div>
      <div className={`${style.calendarStatus} ${style.fewAvailable}`}>
        <i className={'ico-few-available'} />
        <span>{'…空きわずか'}</span>
      </div>
      <div className={`${style.calendarStatus} ${style.noAvailable}`}>
        <i className={'icon-no-available'} />
        <span>{'…空きなし'}</span>
      </div>
      <div className={`${style.calendarStatus} ${style.closed}`}>
        <i className={'icon-closed'} />
        <span>{'…定休日'}</span>
      </div>
    </div>
  );
};
