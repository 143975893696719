import { f7 } from 'framework7-react';
import { FC, useState } from 'react';
import style from './bannerListStyle.module.css';
import { getBannerImageSrc } from '@/hooks/api/banner/useFetchBanners';
import { Banner } from '@/types/api/bannerApi';

interface BannerListProps {
  banners?: Banner[];
}
export const BannerList: FC<BannerListProps> = ({ banners }) => {
  // banners =
  //   (banners ?? []).length > 0
  //     ? banners
  //     : [
  //         { path_type: 2, url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { path_type: 2, url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { path_type: 2, url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { path_type: 1, url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //         { path_type: 1, url: '', image_file_path: '/src/assets/images/banner-sample.png' },
  //       ];
  const handleClickBanner = (path_type: number, path: string) => {
    if (path_type === 1) {
      f7.views.main.router.navigate(path);
    } else if (path_type === 2) {
      window.open(path, '_blank');
    }
  };

  const [selectedBanner, setSelectedBanner] = useState(0);

  return (
    <div className={style[`benner-section-wrapper`]}>
      <div className={style['banner-list-wrapper']}>
        {banners?.map(({ path_type, url, image_file_path }, index) => (
          <div
            className={style[`banner-wrapper`]}
            key={index}
            onClick={async () =>
              path_type && url && handleClickBanner(path_type, url)
            }
          >
            <div className={style['banner-image-wrapper']}>
              <img src={image_file_path} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
