import { f7 } from 'framework7-react';
import { FC } from 'react';
import { BannerList } from '../BannerList/BannerList';
import styles from './ServiceStyle.module.css';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { Banner } from '@/types/api/bannerApi';
import { Service as PripaidService } from '@/types/api/serviceApi';

interface ServiceProps {
  id: number;
  services?: PripaidService[];
  banners?: Banner[];
  handleClickItem: (path_type: number, url: string) => void;
}

export const Service: FC<ServiceProps> = ({
  id,
  services,
  banners,
  handleClickItem,
}) => {
  const serviceNames: { [key: number]: string } = {
    1: '洗車・コーティング',
    2: 'カーシェア',
    3: 'ガソリンスタンド',
    4: '車検',
    5: '駐車場',
    6: 'カー用品',
    7: 'レンタカー',
  };
  return (
    <PageTemplate
      pageName="Service"
      title={serviceNames[id]}
      showNavBack
      handleClickBack={() => {
        f7.view.main.router.navigate(paths.home);
      }}
    >
      {services && services?.length > 0 && (
        <div className={styles.serviceTitleWrap}>
          <h2>プリペイドが利用できるサービス</h2>
        </div>
      )}
      <div className={styles.container}>
        {services &&
          services?.map(({ id, service_name, url, link_type }) => (
            <div
              className={styles.card}
              onClick={async () =>
                link_type && url && handleClickItem(link_type, url)
              }
              key={id}
            >
              <h4>{service_name}</h4>
            </div>
          ))}
      </div>
      {banners && <BannerList banners={banners} />}
    </PageTemplate>
  );
};
