import { useMemo } from 'react';
import {
  useRepairRequestDataContext,
  useRepairRequestStateContext,
} from '../context/repairRequestContext';
import { RepairPartImageCard } from '../util/repairRequest/repairPartImageCard';

export const EstimateDamagedImage = () => {
  const { setState } = useRepairRequestStateContext();
  const { data } = useRepairRequestDataContext();
  const imageInfoList = useMemo(() => data?.estimate_image_list ?? [], [data]);

  const handleClickBack = () => {
    setState('ESTIMATE_RESULT');
  };

  return (
    <>
      <div className={'repair-service-request'}>
        {imageInfoList.map((imageInfo, idx) => (
          <RepairPartImageCard info={imageInfo} key={idx} />
        ))}
      </div>
    </>
  );
};
