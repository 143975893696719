import { useCallback } from 'react';
import { postRepairRequestApi } from '@/api/bodyAndPaint';
import { BodyAndPaintRepairRequestParams } from '@/types/api/bodyAndPaint';

interface PostRepairRequestHookProps {
  info: BodyAndPaintRepairRequestParams;
}
export const usePostRepairRequest = ({ info }: PostRepairRequestHookProps) => {
  const postRequest = useCallback(async () => {
    try {
      if (info) {
        const fetch = await postRepairRequestApi(info);
        return fetch;
      } else {
        throw 'invalid params';
      }
    } catch (error) {
      console.error(error);
    }
  }, [info]);

  return { postRequest };
};
