import { f7 } from 'framework7-react';
import { FC, useEffect } from 'react';
import { RegisterMethod } from './RegisterMethod/RegisterMethod';
import style from './RegisterQRPageStyle.module.css';
import CustomerIcon from '@/assets/images/register-qr/customer.png';
import LogoImg from '@/assets/images/register-qr/logo.svg';
import StaffIcon from '@/assets/images/register-qr/staff.png';
import { RegisterQRPageTemplate } from '@/components/uiParts/Template/RegisterQRPageTemplate/RegisterQRPageTemplate';
import { initialState } from '@/config/initialState';
import { paths } from '@/config/paths';
import { storeDispatch } from '@/utils/store';

export const RegisterQRPage: FC = () => {
  const redirectInviteCode = () => {
    f7.view.main.router.navigate(paths.registerNewMember);
  };

  const redirectRegisterNewStaff = () => {
    f7.view.main.router.navigate(paths.registerNewStaff);
  };

  useEffect(() => {
    const { affiliate_id } = f7.view.main.router.currentRoute.query;

    // Reset data in store
    storeDispatch('setRegisterUser', 'registerUser', {
      ...initialState.registerUser,
      isCorporation: false,
      currentStep: 1,
      step1: {
        companyName: '',
        companyNameKana: '',
        firstName: '',
        firstNameKana: '',
        familyName: '',
        familyNameKana: '',
        email: '',
        birthday: '',
        tel1: '',
        tel2: '',
        tel3: '',
        tel4: '',
        tel5: '',
        tel6: '',
        mobile_type1: 0,
        mobile_type2: null,
      },
      step2: {
        zipCode1: '',
        zipCode2: '',
        municipalities: '',
        prefectures: '',
        street: '',
        apartment: '',
      },
      affiliate_id: Number(affiliate_id) || 1,
    });
  }, []);

  return (
    <RegisterQRPageTemplate title="" pageName="RegisterQR" showNavBack={false}>
      <div>
        <div className={style['register-qr-div']}>
          <img src={LogoImg} />
          新規会員
        </div>
        <div className={style['register-qr-method']}>
          <RegisterMethod
            handleClick={redirectInviteCode}
            img={CustomerIcon}
            title="個人のお客様"
          />
          <RegisterMethod
            handleClick={redirectRegisterNewStaff}
            img={StaffIcon}
            title="法人のお客様"
          />
        </div>
      </div>
    </RegisterQRPageTemplate>
  );
};
