import { useReserveDateText } from '../../hook/util/useDateTimeText';
import { FilledButton } from '../button';

interface CancelReserveDialogCard {
  onClose: () => void;
  onConfirm: () => void;
}
export const CancelReserveDialogCard = ({
  onClose,
  onConfirm,
}: CancelReserveDialogCard) => {
  const dateTimeInfo = new Date();
  const dialogLabel = '予約を取り消す';
  const dialogText = ['以下の予約が破棄されます。', '本当に取り消しますか？'];
  const reserveDate = useReserveDateText({ dateTime: dateTimeInfo });
  const reserveInfo = [
    `予約日時：${reserveDate}〜`,
    `ご相談内容：傷・凹み修理の入庫予約`,
  ];
  const handleClickCloseButton = () => onClose();
  const handleClickCancelButton = () => onConfirm();
  const CancelReserveButtonField = () => {
    return (
      <div className={'button-field cancel-reserve'}>
        <FilledButton
          onClick={handleClickCloseButton}
          label={'キャンセル'}
          buttonStyle={'cancelate'}
        />
        <FilledButton
          onClick={handleClickCancelButton}
          label={'予約を取り消す'}
          buttonStyle={'warning'}
        />
      </div>
    );
  };
  return (
    <div className={'dialog-card cancel-reserve'}>
      <div className={'message-field'}>
        <div className={'message-header'}>
          <div className={'message-icon'}>
            <i className={'icon-caution'} />
          </div>
          <div className={'message-text'}>
            <span>{dialogLabel}</span>
          </div>
        </div>
        <div className={'message-body'}>
          {dialogText.map((item, idx) => (
            <span key={idx}>{item}</span>
          ))}
        </div>
        <div className={'message-info'}>
          {reserveInfo.map((item, idx) => (
            <span key={idx}>{item}</span>
          ))}
        </div>
      </div>
      <CancelReserveButtonField />
    </div>
  );
};
