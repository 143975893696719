import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  CAR_DRIVEN,
  DAMAGE_DEGREE,
  OTHER_PARTY,
} from '../../const/bodyAndPaint';
import { useRadioSelect } from '../../hook/util/common/useRadioSelect';
import { useTextInput } from '../../hook/util/common/useTextInput';
import { useDamageCheck } from '../../hook/util/repairCheckIn/useDamageCheck';
import {
  CarConditionInfoType,
  DamageMapItemType,
} from '../../type/bodyAndPaint';
import { DamageCheckMap } from '../common/damageCheckMap';
import { EntryField } from '../common/entryField';
import { RadioGroup } from '../radioGroup';
import { TextAreaField, TextField } from '../textFIeld';
import style from './repairCheckInStyle.module.css';

interface EntryCarConditionField {
  info: CarConditionInfoType;
  setInfo: Dispatch<SetStateAction<CarConditionInfoType>>;
}
export const EntryCarConditionField = ({
  info,
  setInfo,
}: EntryCarConditionField) => {
  const {
    car_driven,
    damage_map,
    damage_degree,
    other_party,
    notice_date,
    description,
  } = info ?? ({} as CarConditionInfoType);

  const carDrivenList: string[] = useMemo(
    () => Object.values(CAR_DRIVEN).map((item) => item.label),
    [],
  );
  const damageDegreeList: string[] = useMemo(
    () => Object.values(DAMAGE_DEGREE).map((item) => item.label),
    [],
  );
  const otherPartyList: string[] = useMemo(
    () => Object.values(OTHER_PARTY).map((item) => item.label),
    [],
  );

  const [carDrivenSelect, carDrivenValue, changeCarDrivenSelect] =
    useRadioSelect({ initialSelect: car_driven, selectList: carDrivenList });
  const [damageDegreeSelect, damageDegreeValue, changeDamageDegree] =
    useRadioSelect({
      initialSelect: damage_degree,
      selectList: damageDegreeList,
    });
  const [otherPartySelect, otherPartyValue, changeOtherParty] = useRadioSelect({
    initialSelect: other_party,
    selectList: otherPartyList,
  });

  const [damageMap, setDamageMap] = useDamageCheck({
    initialCheck: damage_map ?? ({} as DamageMapItemType),
  });

  const noticeDate = useMemo(
    () =>
      notice_date instanceof Date && !isNaN(notice_date.getTime())
        ? notice_date.toLocaleDateString()
        : '',
    [notice_date],
  );
  const [noticeDateValue, changeNoticeDate] = useTextInput({
    initialValue: noticeDate,
    required: true,
  });
  const [descriptionValue, changeDescription] = useTextInput({
    initialValue: description,
    required: true,
  });
  useEffect(() => {
    setInfo((prev) => ({
      ...prev,
      car_driven: carDrivenSelect,
      damage_map: damageMap,
      damage_degree: damageDegreeSelect,
      other_party: otherPartySelect,
      notice_date: new Date(noticeDateValue),
      description: descriptionValue,
    }));
  }, [
    setInfo,
    carDrivenSelect,
    damageMap,
    damageDegreeSelect,
    otherPartySelect,
    noticeDateValue,
    descriptionValue,
  ]);
  return (
    <div className={style.entryCarConditionField}>
      <EntryField label={'自走できますか？'} required={true}>
        <RadioGroup
          groupName={'car_driven'}
          list={carDrivenList}
          value={carDrivenValue}
          onChange={changeCarDrivenSelect}
        />
      </EntryField>
      <EntryField label={'整備する項目を選択してください'} required={true}>
        <DamageCheckMap map={damageMap} setMap={setDamageMap} />
      </EntryField>
      <EntryField label={'損傷の程度はどのくらいですか？'} required={true}>
        <RadioGroup
          groupName={'damage_degree'}
          list={damageDegreeList}
          value={damageDegreeValue}
          onChange={changeDamageDegree}
        />
      </EntryField>
      <EntryField label={'相手方がいる事故ですか？'} required={true}>
        <RadioGroup
          groupName={'damage_degree'}
          list={otherPartyList}
          value={otherPartyValue}
          onChange={changeOtherParty}
        />
      </EntryField>
      <EntryField label={'お気づきになられたのはいつですか？'} required={true}>
        <TextField
          value={noticeDateValue}
          onChange={changeNoticeDate}
          required={true}
          placeholder={'年/月/日'}
        />
      </EntryField>
      <EntryField label={'損傷の詳細をご記入ください'} required={true}>
        <TextAreaField
          value={descriptionValue}
          onChange={changeDescription}
          required={true}
          placeholder={
            '例：前側バンパーの右の方を擦ってしまった。駐車場にて左前ドアを凹まされた。'
          }
        />
      </EntryField>
    </div>
  );
};
